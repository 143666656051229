import { ScheduleRegistrationDetail } from './../../model/entity/schedule-registration-detail';
import { ScheduleRegistrationService } from '../../service/schedule-registration.service';
import { DataService } from 'app/service/data.service';
import { Constant, PreviewToolEnum, FIELD_COMPONENT, MODULE_NAME, SortTypeEnum, SettingType } from 'app/config/constants';
import { PanzoomObject } from '@panzoom/panzoom';
import { Template } from 'app/model/entity/template';
import { DialogPlaylistRecurrenceComponent } from 'app/dialog/dialog-playlist-recurrence/dialog-playlist-recurrence.component';
import { ChangeDetectorRef, Component, ElementRef, HostListener, OnInit, Renderer2, ViewChild, Output, EventEmitter } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { DialogMessageComponent } from 'app/dialog/dialog-message/dialog-message.component';
import * as fileSaver from 'file-saver';
import { DialogTemplateSettingScheduleComponent } from 'app/dialog/schedule/dialog-template-setting-schedule/dialog-template-setting-schedule.component';
import { Area } from 'app/model/entity/area';
import { Common } from 'app/model/entity/common';
import { ContentDay } from 'app/model/entity/content-day';
import { Media } from 'app/model/entity/media';
import { TextArea } from 'app/model/entity/text-area';
import { CommonService } from 'app/service/common.service';
import { DialogService } from 'app/service/dialog.service';
import { IndexWordService } from 'app/service/index-word.service';
import { DrawScheduleService } from 'app/service/draw-schedule.service';
import { MenuActionService } from 'app/service/menu-action.service';
import { TemplateService } from 'app/service/template.service';
import _ from 'lodash';
import { Subscription } from 'rxjs';
import { ScheduleContentDayService } from 'app/service/schedule-content-day.service';
import { ScreenNameEnum, RepeatModeEnum, ScreenFunctionId } from '../../config/constants';
import { ToastrService } from 'ngx-toastr';
import { PublishSetting } from 'app/model/entity/publish-setting';
import { PublishTimetableService } from 'app/service/publish-timetable.service';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import Panzoom from '@panzoom/panzoom';
import { DialogExportCalendarComponent } from 'app/dialog/dialog-export-calendar/dialog-export-calendar.component';
import { RegexTime } from '../timetable-editor/timetable-editor.component';
import { DialogConfirmComponent } from 'app/dialog/dialog-confirm/dialog-confirm.component';
import { Helper } from 'app/common/helper';
import { IHash, OptionFilter, SortFilterObject } from 'app/model/entity/sort-filter-object';
import { SortFilterService } from 'app/service/sort-filter.service';
import { DialogCustomSortComponent } from 'app/dialog/dialog-custom-sort/dialog-custom-sort.component';
import { DialogRouteLabelManagerComponent } from 'app/dialog/dialog-route-label-manager/dialog-route-label-manager.component';
import { DialogChangeLabelComponent } from 'app/dialog/dialog-change-label/dialog-change-label.component';
import { SettingSignageChannelService } from 'app/service/setting-signage-channel.service';
import { DialogSettingSignageDisplayComponent } from 'app/dialog/dialog-setting-signage-display/dialog-setting-signage-display.component';
import { SettingSignageChannel } from 'app/model/entity/simple/setting-signage-channel';
import { SimpleMediaService } from 'app/service/simple/simple-media.service';
import { IndexWord } from 'app/model/entity/index-word';
import { CommonTableService } from 'app/service/common-table.service';
import { CommonTable } from 'app/model/entity/commonTable';
import moment from 'moment';
import { resolve } from 'dns';
import { rejects } from 'assert';
import { ExecutingService } from 'app/service/executing.service';
import { Store } from '@ngrx/store';
import { AppState } from 'app/store/app.state';

@Component({
  selector: 'schedule-registration',
  templateUrl: './schedule-registration.component.html',
  styleUrls: ['./schedule-registration.component.scss']
})
export class ScheduleRegistrationComponent implements OnInit {
  /**
  /**
   * current index schedule
   */
  public currentIndexSchedule: number;
  /**
   * true if > finish month
   */
  isNextMonth: boolean;
  /**
   * true if < start month
   */
  isPreviousMonth: boolean = true;
  /**
   * current date
   */
  currentDate: Date;
  /**
   * content days month
   */
  contentDaysMonth: Array<ContentDay>;
  /**
   * year selected
   */
  selectedYear: number;
  /**
   * month selected
   */
  selectedMonth: any;
  /**
   * PATH_ANGLE_DOUBLE_RIGHT
   */
  public PATH_ANGLE_DOUBLE_RIGHT = Constant.PATH_ANGLE_DOUBLE_RIGHT;
  /**
   * tab active selected
   */
  public tabSelected: Tab_Enum = Tab_Enum.TIMETABLE;
  /**
   * Tab_Enum
   */
  public Tab_Enum = Tab_Enum;
  /**
   * true if expand preview
   */
  public isEnlargePreview: boolean;
  /**
   * true if isPlay
   */
  public isPlay: boolean = false;
  /**
   * timetable daily selected
   */
  public timetableDailySelected: any;
  /**
   * true if active tool pan
   */
  public isPan: boolean;
  /**
   * panzoom display
   */
  private panzoomDisplay: PanzoomObject;
  /**
   * PreviewToolEnum
   */
  public PreviewToolEnum = PreviewToolEnum;
  /**
   * timetables daily
   */
  public timetables: ScheduleRegistrationDetail[] = new Array<ScheduleRegistrationDetail>();

  readonly MAX_NUMBER_COLORS = 5;

  /**
   * scheduleSelected
   */
  public scheduleSelected: any;

  /**
   * templateIds;
   */
  public templateIds: any;
  /**
   * template selected
   */
  public templateSelected: Template;

  /**
   * media setting
   */
  mediaSetting: Media;

  /**
   * areas of display template
   */
  areasTimetable: TextArea[];

  /**
   * areas drew of template
   */
  areasTimetableDrew: TextArea[];

  /**
   * areas index word of template
   */
  areasIndexWord: Area[];
  /**
   * areas index word drew of template
   */
  areasIndexWordDrew: Area[];
  /**
   * panzoomDisplay1
   */
  private panzoomDisplay1: PanzoomObject;

  areaSwitchingTiming: number;

  /**
   * abort controller request
   */
  abortControllerDisplay: AbortController = new AbortController();

  /**
   * reference position columns
   */
  referencePositionColumnsByTemplate: number[] = new Array();
  readonly IS_EDITING_TIMETABLE = 'isEditingTimetable';
  readonly TIMETABLE_INDEX = 0;
  readonly INDEX_WORD_INDEX = 1;
  readonly INDEX_TIME_ITEM = 0;
  readonly IS_PREVIEW_ON_SCR = 'isPreviewScheduleRegistration';
  /**
   * start index schedule
   */
  readonly START_INDEX_SCHEDULE = 0;

  timeoutsDisplay1: any[] = [];

  templateIdSelected: number;

  commonTable: CommonTable;
  /**
   * list month
   */
  listMonth: Array<{ value: string; key: number }> = Array(
    { value: this.translateService.instant('schedule-registration.month-1'), key: 0 },
    { value: this.translateService.instant('schedule-registration.month-2'), key: 1 },
    { value: this.translateService.instant('schedule-registration.month-3'), key: 2 },
    { value: this.translateService.instant('schedule-registration.month-4'), key: 3 },
    { value: this.translateService.instant('schedule-registration.month-5'), key: 4 },
    { value: this.translateService.instant('schedule-registration.month-6'), key: 5 },
    { value: this.translateService.instant('schedule-registration.month-7'), key: 6 },
    { value: this.translateService.instant('schedule-registration.month-8'), key: 7 },
    { value: this.translateService.instant('schedule-registration.month-9'), key: 8 },
    { value: this.translateService.instant('schedule-registration.month-10'), key: 9 },
    { value: this.translateService.instant('schedule-registration.month-11'), key: 10 },
    { value: this.translateService.instant('schedule-registration.month-12'), key: 11 }
  );

  /**
   * header column original
   */
  headerColumnsOriginal: any = [
    { headerName: this.translateService.instant('schedule-registration.label'), property: 'nameLabel', isSortBy: '', isFilterBy: '' },
    { headerName: this.translateService.instant('schedule-registration.no'), property: 'no', isSortBy: '', isFilterBy: '' },
    { headerName: this.translateService.instant('schedule-registration.suffix'), property: 'suffix', isSortBy: '', isFilterBy: '' },
    { headerName: this.translateService.instant('schedule-registration.name'), property: 'name', isSortBy: '', isFilterBy: '' }
  ];

  /**
   * templates
   */
  public templates: Array<Template>;
  /**
   * schedule daily selected
   */
  public timetableSelected: ScheduleRegistrationDetail;
  /**
   * current index schedule daily
   */
  public currentIndexScheduleDetail: number;
  /**
   * true if active tool pan
   */
  public isZoom: boolean;
  /**
   * constant
   */
  Constant = Constant;
  /**
   * view child element divContainCanvasRegistration
   */
  @ViewChild('divContainCanvasRegistration', { static: false })
  public divContainCanvasRegistration: ElementRef;
  /**
   * calendars
   */
  calendarsInTwoYear: Array<ContentDay>;

  /**
   * calendar in month
   */
  calendarFromDB: Array<ContentDay> = [];

  /**
   * Is unlimited data from dialog data recurrence
   */
  private isUnlimitedDataFromDialogDataRecurrence: boolean;

  /**
   * Is unlimited
   */
  private isUnlimited: boolean = false;
  /**
   * color being used
   */
  colorBeingUsed: Map<Number, string> = new Map<Number, string>();
  /**
   * save data success
   */
  @Output() saveDataSuccess: EventEmitter<boolean> = new EventEmitter<boolean>();
  /**
   * colors original
   */
  colorsOriginal = ['#FFB8B8', '#FFEB8D', '#D5FF96', '#B8E9FF', '#D5BAFF'];
  /**
   * colors unused
   */
  unUsedColors: string[];
  /**
   * name edit
   */
  nameEdit: string;
  /**
   * no edit
   */
  noEdit: string;
  /**
   * suffix edit
   */
  suffixEdit: string;
  /**
   * view child element divPreviewDisplayRegistration
   */
  @ViewChild('divPreviewDisplayRegistration', { static: false })
  public divPreviewDisplayRegistration: ElementRef;
  /**
   * common object
   */
  commonObject: Common;

  /**
   * timetable for display
   */
  timetablesDisplay: Array<ScheduleRegistrationDetail> = new Array<ScheduleRegistrationDetail>();

  /**
   * ElementRef
   */
  @ViewChild('no') noElementRef: ElementRef;
  @ViewChild('suffix') suffixElementRef: ElementRef;
  @ViewChild('name') nameElementRef: ElementRef;
  /**
   * time date line
   */
  timeDateLine: string;

  /**
   * sort filter variable
   */
  isSortFilter: boolean;
  isCheckAllOptionFilter: boolean;
  columnSortFiltering: string;
  isShowPopUpSortFilter: boolean;
  lastColumnFilter: string;
  listFilterDisplay: Array<OptionFilter>;
  listFilterDisplayOrigin: Array<OptionFilter>;
  isFilter: boolean;
  isClear: boolean;
  listCurrentFilter: IHash = {};
  listSorted: any = [];

  /**
   * Sort filter object
   */
  private sortFilterObject: SortFilterObject;

  /**
   * header column show on display
   */
  headerColumns: any = [];
  readonly LAST_FILTER = 'lastFilter';
  readonly IS_FILTER = 'isFilter';
  /**
   * array subscription
   */
  subscriptions: Array<Subscription> = new Array<Subscription>();

  constructor(
    private menuActionService: MenuActionService,
    private dataService: DataService,
    private renderer: Renderer2,
    private commonService: CommonService,
    private translateService: TranslateService,
    private dialogService: DialogService,
    private scheduleRegistrationService: ScheduleRegistrationService,
    private changeDetectorRef: ChangeDetectorRef,
    private templateService: TemplateService,
    private drawScheduleService: DrawScheduleService,
    private indexWordService: IndexWordService,
    private scheduleContentDayService: ScheduleContentDayService,
    private toast: ToastrService,
    private publishTimetableService: PublishTimetableService,
    private sortFilterService: SortFilterService,
    private settingSignageChannelService: SettingSignageChannelService,
    private simpleMediaService: SimpleMediaService,
    private commonTableService: CommonTableService,
    private executingService: ExecutingService,
    public readonly store: Store<AppState>
  ) {
    // subscribe for change template
    this.subscriptions.push(
      this.menuActionService.actionChangeTemplate.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.ScheduleMergeComponent]) {
          this.showDialogTemplateSetting();
        }
      })
    );

    this.subscriptions.push(
      this.menuActionService.actionImportScheduleImportRegistration.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.ScheduleRegistrationComponent]) {
          this.importScheduleRegistration();
        }
      })
    );

    this.subscriptions.push(
      this.menuActionService.actionExportScheduleImportRegistration.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.ScheduleRegistrationComponent]) {
          this.exportScheduleRegistration();
        }
      })
    );
    // export data calendar
    this.subscriptions.push(
      this.menuActionService.actionExportCalendarScheduleRegistration.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.ScheduleRegistrationComponent]) {
          this.exportCalendarScheduleRegistration();
        }
      })
    );
    // add new timetable
    this.subscriptions.push(
      this.menuActionService.actionAdd.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.ScheduleRegistrationComponent]) {
          this.addTimetableScheduleRegistration();
        }
      })
    );

    // edit new time table
    this.subscriptions.push(
      this.menuActionService.actionEdit.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.ScheduleRegistrationComponent]) {
          this.editTimeTable();
        }
      })
    );

    // duplicate time table
    this.subscriptions.push(
      this.menuActionService.actionDuplicate.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.ScheduleRegistrationComponent]) {
          this.duplicateTimeTable();
        }
      })
    );

    // delete time table
    this.subscriptions.push(
      this.menuActionService.actionDelete.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.ScheduleRegistrationComponent]) {
          this.deleteTimeTable();
        }
      })
    );
    // show dialog manager label
    this.subscriptions.push(
      this.menuActionService.actionManageLabel.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.ScheduleRegistrationComponent]) {
          this.showDialogManagerLabel();
        }
      })
    );

    // sort filter timetables
    this.subscriptions.push(
      this.menuActionService.actionSortAndFilter.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.ScheduleRegistrationComponent]) {
          this.sortFilter();
        }
      })
    );
    // subscribe for change label action
    this.subscriptions.push(
      this.menuActionService.actionChangeLabel.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.ScheduleRegistrationComponent]) {
          this.changeLabelTimeTable();
        }
      })
    );

    // setting channel area preview
    this.subscriptions.push(
      this.menuActionService.actionChannelAreaPreview.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.ScheduleRegistrationComponent]) {
          this.signageChannelAreaPreview();
        }
      })
    );

    this.subscriptions.push(
      this.translateService.onLangChange.subscribe((langChangeEvent: LangChangeEvent) => {
        this.listMonth = Array(
          { value: this.translateService.instant('timetable-editor.month-1'), key: 0 },
          { value: this.translateService.instant('timetable-editor.month-2'), key: 1 },
          { value: this.translateService.instant('timetable-editor.month-3'), key: 2 },
          { value: this.translateService.instant('timetable-editor.month-4'), key: 3 },
          { value: this.translateService.instant('timetable-editor.month-5'), key: 4 },
          { value: this.translateService.instant('timetable-editor.month-6'), key: 5 },
          { value: this.translateService.instant('timetable-editor.month-7'), key: 6 },
          { value: this.translateService.instant('timetable-editor.month-8'), key: 7 },
          { value: this.translateService.instant('timetable-editor.month-9'), key: 8 },
          { value: this.translateService.instant('timetable-editor.month-10'), key: 9 },
          { value: this.translateService.instant('timetable-editor.month-11'), key: 10 },
          { value: this.translateService.instant('timetable-editor.month-12'), key: 11 }
        );
        this.multiLanguageHeader();
        this.multiLanguageTooltip();
      })
    );
    this.subscriptions.push(this.store.select(state => state).subscribe());
    this.commonObject = commonService.getCommonObject();
    this.currentDate = Helper.getCurrentByTimezoneSetting(this.commonObject);
    this.sortFilterObject = sortFilterService.getSortFilterObject();
    this.sortFilterObject.isSortFilter = false;
    this.subscriptions.push(
      this.menuActionService.actionUpdateDataScheduleRegistration.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.ScheduleRegistrationComponent]) {
          this.updateDataRegistration();
        }
      })
    );
  }

  async ngOnInit() {
    await this.getAreaSwitching();
    this.dataService.sendData([Constant.USER_ROOT, this.commonObject.user.userId == Constant.USER_ROOT]);
    this.executingService.executing();
    await Helper.loadFontsToPreview(this.store, this.commonObject, this.translateService, this.dialogService);
    this.executingService.executed();
    this.getAllSortFilterConditions();
    this.selectedYear = this.currentDate.getFullYear();
    this.selectedMonth = this.currentDate.getMonth();
    this.calendarsInTwoYear = Helper.getCalendarsFromCurrentDate();
    this.getInformationChangeDateLine();
    this.getAllSettingTemplateIds();
    this.getAllCalendar();
    this.settingSignageChannelService.getSettingSignageChannelByType(SettingType.SCHEDULE_REGISTRATION).subscribe(settingSignageChannel => {
      if (!settingSignageChannel || (!settingSignageChannel.folderId && !settingSignageChannel.mediaId)) {
        return;
      }
      this.simpleMediaService.getMediaById(settingSignageChannel.mediaId).subscribe(
        async mediaData => {
          if (!mediaData) {
            this.mediaSetting = undefined;
            return;
          }
          this.mediaSetting = Helper.convertSimpleMediaToMedia(Helper.convertDataSimpleMedia(mediaData, false));
          this.drawScheduleService.setupPreview(this.timetableSelected, this.mediaSetting ?? undefined);
          // draw area is set(signage channel)
          this.drawScheduleService.drawAreasSignageChannel(this.templateSelected, this.renderer);
        },
        error => Helper.handleError(error, this.translateService, this.dialogService)
      );
    });
  }

  ngOnDestroy() {
    if (this.isPlay) {
      this.changeStatePreview();
    }
    this.drawScheduleService.clearAllThreadDrawTemplate(this.templateSelected);
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  /**
   * choose tab
   * @param tabEnum
   */
  public chooseTab(tabEnum: Tab_Enum): void {
    this.tabSelected = tabEnum;
    this.isPlay = false;
    this.dataService.sendData([Constant.IS_TAB_TIMETABLE, tabEnum == Tab_Enum.TIMETABLE]);
    this.dataService.sendData([Constant.IS_TAB_CALENDAR, tabEnum == Tab_Enum.CALENDAR]);
    this.dataService.sendData([this.IS_PREVIEW_ON_SCR, this.isPlay]);
    switch (tabEnum) {
      case Tab_Enum.TIMETABLE:
        this.drawDisplay(this.templateSelected);
        break;
      case Tab_Enum.CALENDAR:
        this.drawScheduleService.pausePreview();
        this.drawScheduleService.clearAllThreadDrawTemplate(this.templateSelected);
        break;
      default:
        break;
    }
  }

  /**
   * select timetable
   * @param timetable
   * @returns
   */
  public selectTimetable(schedule: any, event): void {
    if (schedule?.id == this.timetableSelected?.id) {
      return;
    }
    this.dataService.sendData([Constant.IS_RECORDS_TIMETABLE_MAX, this.timetables.length >= Constant.MAX_RECORDS_TIMETABLE]);
    if (this.isPlay) {
      this.changeStatePreview();
    } else {
      this.drawScheduleService.pausePreview();
    }
    if (this.timetableSelected?.isEdit) {
      return;
    }
    if (event?.target?.id === 'checkBoxTimetableSchedule' || schedule?.id == this.timetableSelected?.id) {
      return;
    }
    this.timetableSelected = schedule;
    this.currentIndexSchedule = 0;
    this.convertDataSchedule();
    // if (!this.timetablesDisplaySecond.length) {
    const areasDisplay1 = Helper.getAllAreaTemplate(this.templateSelected);
    this.reDrawPreview(areasDisplay1);
    // }
    this.selectSchedule(this.currentIndexSchedule, true);
    this.multiLanguageTooltip();
  }

  timetablesSecond: any[];
  timetablesDisplaySecond: any[]; //data output with sort filter
  headerColumnsOriginalSecond: any[];
  dataStoredSecond: any;
  convertDataSchedule() {
    this.headerColumnsOriginalSecond = [];
    this.timetablesSecond = [];
    const headerColumnsOriginalSecond = [];
    const timetablesSecond = [];
    // this.headerColumnsOriginalSecond.push({ headerName: '', property: 'isChecked', type: 'checkbox' });

    this.timetableSelected?.headers?.forEach((e, index) => {
      headerColumnsOriginalSecond.push({
        headerName: e,
        property: index + '',
        isSortBy: '',
        isFilterBy: '',
        isHaveSortOrder: true,
        isChecked: false
      });
    });
    this.timetableSelected?.schedules?.forEach((e, index) => {
      timetablesSecond.push({ id: index, indexFilter: index, ...Object.assign({}, e.columnData), invalid: e.inValidRow });
    });
    this.timetablesDisplaySecond = timetablesSecond;
    this.headerColumnsOriginalSecond = headerColumnsOriginalSecond;
    this.timetablesSecond = timetablesSecond;

    if (headerColumnsOriginalSecond.length == 1 || timetablesSecond.length == 0) {
      this.dataStoredSecond = null;
    }
    // else {
    // this.executingService.executing();
    // setTimeout(() => {
    // }, 100);
    // }
  }
  /**
   * change checked
   * @param id
   * @param event
   */
  public changeChecked(scheduleRegistrationId, event): void {
    event.stopPropagation();
    let index = this.timetables.findIndex(schedule => schedule.id === scheduleRegistrationId);
    this.timetables[index].isChecked = !this.timetables[index].isChecked;
    let indexDisplay = this.timetablesDisplay.findIndex(e => e.id === this.timetables[index].id);
    this.timetablesDisplay[indexDisplay].isChecked = this.timetables[index].isChecked;
    if (this.timetables[index].id == this.timetableSelected?.id) {
      this.timetableSelected.isChecked = this.timetables[index].isChecked;
    }
  }

  /**
   * cancel save timetable
   */
  public cancelSaveTimetable(): void {
    this.timetableSelected.isEdit = false;
    let indexTimetable = this.timetables.findIndex(timetable => timetable.id == this.timetableSelected.id);
    if (indexTimetable != -1) {
      this.timetables[indexTimetable].isEdit = false;
    }
    let index = this.timetablesDisplay.findIndex(timetableDisplay => timetableDisplay.id == this.timetableSelected.id);
    if (index != -1) {
      this.timetablesDisplay[index].isEdit = false;
    }
    this.dataService.sendData([this.IS_EDITING_TIMETABLE, this.timetableSelected.isEdit]);
    if (!this.timetableSelected.id) {
      this.timetables.pop();
      this.timetablesDisplay.pop();
      if (this.timetablesDisplay?.length) {
        this.selectTimetable(this.timetablesDisplay[Constant.FIRST_ELEMENT_INDEX], null);
      } else {
        this.timetableSelected = undefined;
      }
    } else {
      this.selectTimetable(this.timetableSelected, null);
    }
  }
  /**
   * selectScheduleFromSortFilter
   * @param index
   */
  public selectScheduleFromSortFilter(dataRow: any): void {
    if (dataRow) {
      this.selectSchedule(dataRow.indexFilter);
    }
  }
  /**
   * select schedule
   * @param index
   */
  public selectSchedule(index: number, isReset?: boolean): void {
    if (index < 0 || index >= this.timetablesDisplaySecond?.length) {
      return;
    }
    this.referencePositionColumnsByTemplate = Helper.getReferencePositionColumnsByTemplate(this.templateSelected);
    this.referencePositionColumnsByTemplate = this.fillDataForReferencePositionColumn();
    this.scheduleSelected = this.timetablesDisplaySecond[index];
    const areasDisplay1 = Helper.getAllAreaTemplate(this.templateSelected);
    // setup AbortController
    this.abortControllerDisplay.abort();
    this.abortControllerDisplay = new AbortController();
    this.clearBeforeDrawFinishSchedule();
    if (index == this.timetablesDisplaySecond?.length) {
      // finish timetable
      this.drawScheduleService.handleUnSubscriptionForLayer(this.templateSelected);
      this.currentIndexSchedule = this.timetablesDisplaySecond?.length + 1;
      // clear before drawing
      this.clearBeforeDrawFinishSchedule();
      // draw area is timing on
      this.drawScheduleService.drawPreviewFixAreaTimingOn(
        areasDisplay1?.filter(area => area.isFix && area.isTimingOn),
        this.renderer,
        true,
        this.templateSelected
      );
      return;
    }
    if (index === this.START_INDEX_SCHEDULE) {
      this.currentIndexSchedule = this.START_INDEX_SCHEDULE;
      // pause if reset when playing
      if (isReset && this.isPlay) {
        this.reDrawPreview(areasDisplay1);
        return;
      }
    } else {
      if (this.currentIndexSchedule === this.timetablesDisplaySecond?.length + 1) {
        // clear Fix Area Timing On
        this.clearFixAreaTimingOn(areasDisplay1);
      }
      this.currentIndexSchedule = index;
    }
    if (isReset) {
      this.reDrawPreview(areasDisplay1);
      return;
    }
    // draw time table
    this.drawScheduleService.setDataTimetables(this.currentIndexSchedule, this.referencePositionColumnsByTemplate, this.timeDateLine);
    this.drawScheduleService.setDataPreviewTimetableEditor(this.timetablesDisplaySecond);
    this.drawScheduleService.clearAreas(this.areasTimetableDrew);
    this.drawScheduleService.clearAreas(this.areasIndexWordDrew);
    this.drawScheduleService.clearAreas(this.areasTimetable);
    this.drawScheduleService.clearAreas(this.areasIndexWord);
    const areasTimetableDraw = _.cloneDeep(this.areasTimetable);
    this.drawAreasTimetableForDisplay(this.templateSelected, areasTimetableDraw);
    this.areasTimetableDrew = areasTimetableDraw;
    this.drawScheduleService.clearMediaIndexWords();
    // draw index word
    if (this.areasIndexWord?.length) {
      // get index word from schedule
      const areasIndexWord = _.cloneDeep(this.areasIndexWord);
      this.indexWordService.getIndexWordsByNameAndGroupId(this.getParamForGetIndexWord(areasIndexWord)).subscribe(indexWords => {
        Helper.setDataIndexWordForAreasRegistration(areasIndexWord, indexWords, this.timetablesDisplaySecond, this.currentIndexSchedule);
        this.drawScheduleService.setDataPreviewTimetableEditor(this.timetablesDisplaySecond, null, null, areasIndexWord);
        // draw index word
        this.drawAreasIndexWordForDisplay(this.templateSelected, areasIndexWord, indexWords);
        this.areasIndexWordDrew = areasIndexWord;
      });
    }
  }

  /**
   * Re draw preview
   * @param areasDisplay1
   * @param areasDisplay2
   */
  private reDrawPreview(areasDisplay1: Area[]): void {
    this.drawScheduleService.changeStartState(true);
    this.isPlay = false;
    this.dataService.sendData([this.IS_PREVIEW_ON_SCR, this.isPlay]);
    this.changeDetectorRef.detectChanges();
    // clear time out
    this.drawScheduleService.clearTimeoutsStopDurationArea(true);
    this.clearTimeoutDisplay(this.timeoutsDisplay1);
    this.clearAllDrawThread();
    // clear Fix Area Timing On
    this.clearFixAreaTimingOn(areasDisplay1);
    this.drawDisplay(this.templateSelected);
    this.drawScheduleService.changeStatePlayPause(this.isPlay);
  }

  /**
   * clear Fix Area Timing On
   * @param areasDisplay1
   * @param areasDisplay2
   */
  private clearFixAreaTimingOn(areasDisplay1: Array<Area>): void {
    this.drawScheduleService.clearFixAreaTimingOn(areasDisplay1?.filter(area => area.isFix && area.isTimingOn));
  }

  /**
   * clear all time out
   * @param timeouts
   */
  private clearTimeoutDisplay(timeouts: any[]): void {
    for (let i = 0; i < timeouts.length; i++) {
      clearTimeout(timeouts[i]);
    }
    timeouts = [];
  }

  /**
   * Clear all draw thread
   */
  private clearAllDrawThread(): void {
    if (this.templateSelected) {
      this.drawScheduleService.clearAllThreadDrawTemplate(this.templateSelected);
    }
  }
  /**
   * clear before draw finish schedule
   */
  private clearBeforeDrawFinishSchedule(): void {
    this.drawScheduleService.clearAreas(this.areasTimetableDrew);
    this.drawScheduleService.clearAreas(this.areasTimetable);
  }
  /**
   * enlarge preview
   */
  public enlargePreview(): void {
    this.isEnlargePreview = !this.isEnlargePreview;
    this.calculateScaleTransformCanvas(this.templateSelected);
  }

  /**
   * choose tool zoom, pan
   *
   * @param tool
   */
  public chooseTool(tool: PreviewToolEnum): void {
    if (tool == PreviewToolEnum.PAN) {
      this.isPan = !this.isPan;
      this.renderer.setStyle(this.divContainCanvasRegistration?.nativeElement, 'cursor', this.isPan ? 'move' : 'default');
    } else {
      this.isZoom = !this.isZoom;
      this.renderer.setStyle(this.divContainCanvasRegistration?.nativeElement, 'cursor', this.isPan ? 'move' : 'default');
    }
    if (this.isZoom && this.isPan) {
      this.renderer.setStyle(this.divContainCanvasRegistration?.nativeElement, 'cursor', 'move');
    }
    this.panzoomDisplay.setOptions({ disablePan: !this.isPan, disableZoom: !this.isZoom, force: true });
  }

  /**
   * change state preview
   */
  public changeStatePreview(): void {
    if (this.timetableSelected?.isEdit || !this.templateSelected) {
      return;
    }
    this.isPlay = !this.isPlay;
    this.drawScheduleService.changeStatePlayPause(this.isPlay);
    this.drawScheduleService.changeStartState(false);
    this.dataService.sendData([this.IS_PREVIEW_ON_SCR, this.isPlay]);
    this.changeDetectorRef.detectChanges();
  }

  /**
   * select month
   * @param month any
   */
  public selectMonth(month: any): void {
    this.selectedMonth = +month;
    // get calendar by selected month and selected year
    this.contentDaysMonth = Helper.getCalendarsByMonthYearSchedule(this.calendarsInTwoYear, this.selectedMonth, this.selectedYear);
  }

  /**
   * show previous month
   */
  public showPrevMonth(): void {
    // return if < start date of device
    if (this.selectedYear == this.currentDate.getFullYear()) {
      if (this.selectedMonth <= this.currentDate.getMonth()) {
        this.isPreviousMonth = true;
        return;
      }
    }
    this.isPreviousMonth = false;
    this.isNextMonth = false;
    this.selectedMonth = this.selectedMonth <= 0 ? 11 : this.selectedMonth - 1;
    this.selectedYear = this.selectedMonth == 11 ? this.selectedYear - 1 : this.selectedYear;
    // get calendar by selected month and selected year
    this.contentDaysMonth = Helper.getCalendarsByMonthYearSchedule(this.calendarsInTwoYear, this.selectedMonth, this.selectedYear);
  }

  /**
   * show next month
   */
  public showNextMonth(): void {
    // return if > finish date of device
    let dateEnd = _.cloneDeep(this.currentDate);
    dateEnd.setFullYear(dateEnd.getFullYear() + Constant.MAX_YEAR);
    dateEnd.setDate(dateEnd.getDate() - 1);
    if (this.selectedYear == this.currentDate.getFullYear() + Constant.MAX_YEAR) {
      if (this.selectedMonth >= dateEnd.getMonth()) {
        this.isNextMonth = true;
        return;
      }
    }
    this.isNextMonth = false;
    this.isPreviousMonth = false;
    this.selectedMonth = this.selectedMonth >= 11 ? 0 : this.selectedMonth + 1;
    this.selectedYear = this.selectedMonth == 0 ? this.selectedYear + 1 : this.selectedYear;
    // get calendar by selected month and selected year
    this.contentDaysMonth = Helper.getCalendarsByMonthYearSchedule(this.calendarsInTwoYear, this.selectedMonth, this.selectedYear);
  }

  // ============ Implement menu Setting ============
  public showDialogTemplateSetting(): void {
    this.dialogService.showDialog(
      DialogTemplateSettingScheduleComponent,
      {
        data: {
          isScheduleRegistration: true
        }
      },
      result => {
        if (result == undefined) {
          return;
        }
        this.templateIds = result;
        this.getTemplateByTemplateIds(this.templateIds);
      }
    );
  }

  /**
   * get All Schedule Registration
   */
  private getAllScheduleRegistration(): void {
    this.scheduleRegistrationService.getAllScheduleRegistrations().subscribe(timetables => {
      if (!timetables.length) {
        return;
      }
      this.timetables = Helper.convertScheduleRegistrationAfterImport(timetables, this.timeDateLine);
      this.dataService.sendData([Constant.IS_RECORDS_TIMETABLE_MAX, this.timetables.length >= Constant.MAX_RECORDS_TIMETABLE]);
      this.timetablesDisplay = [...this.timetables];
      if (!this.isSortFilter) {
        this.selectTimetable(this.timetables[Constant.FIRST_ELEMENT_INDEX], null);
        return;
      }
      // case filter
      if (!_.isEmpty(this.listCurrentFilter)) {
        this.filterTimetableFirstTime();
      }
      // case sort
      if (this.listSorted[Constant.SORT_COLUMN_INDEX]) {
        this.timetablesDisplay.sort(this.dynamicSortMultiple(this.listSorted));
      }
      this.selectTimetable(this.timetablesDisplay[Constant.FIRST_ELEMENT_INDEX], null);
      this.dataService.sendData([Constant.IS_RECORDS_TIMETABLE_MAX, this.timetables.length >= Constant.MAX_RECORDS_TIMETABLE]);
    });
  }

  /**
   * import schedule registration
   */
  private importScheduleRegistration(): void {
    let element = document.getElementById('importedFileSchedule') as HTMLInputElement;
    element.setAttribute('accept', '.xlsx');
    element.click();
  }

  /**
   * get regex time
   */
  private getRegexTime(): RegexTime {
    const regexTimeMinute1 = Helper.formatTimeRegex(Constant.FORMAT_TIME_MINUTE1_REGEX, this.timeDateLine);
    const regexTimeMinute2 = Helper.formatTimeRegex(Constant.FORMAT_TIME_MINUTE2_REGEX, this.timeDateLine);
    const regexTimeSecond = Helper.formatTimeRegex(Constant.FORMAT_TIME_SECOND_REGEX, this.timeDateLine);
    return new RegexTime(regexTimeMinute1, regexTimeMinute2, regexTimeSecond);
  }

  /**
   * upload excel
   */
  public uploadExcel(event): Promise<void> {
    let selectedFiles: File[] = event.target.files;
    const typeFiles = ['xlsx'];
    for (const file of selectedFiles) {
      let typeName = file.name.slice(file.name.lastIndexOf('.') + 1, file.name.length).toLowerCase();
      if (!typeFiles.includes(typeName)) {
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('dialog-error.title'),
            text: this.translateService.instant('schedule-registration.msg.invalid-file')
          }
        });
        return;
      }
    }
    this.scheduleRegistrationService.readExcelFromClient(selectedFiles, this.getRegexTime(), this.timeDateLine).subscribe(
      schedules => {
        this.handleDisplayImport(schedules);
        this.dataStoredSecond = null;
        this.selectTimetable(this.timetablesDisplay[0], null);
      },
      error => {
        this.handleShowErrorMessageFromServerWhenImport(error);
      }
    );
    let element = document.getElementById('importedFileSchedule') as HTMLInputElement;
    element.value = null;
  }

  /**
   * Handle show error message from server when import
   *
   * @param error
   */
  private handleShowErrorMessageFromServerWhenImport(error: any): void {
    switch (error.error?.detail) {
      case Constant.ERROR_MULTIPLE_TIME_FORMATS_VALUE_EXCEL:
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('dialog-error.title'),
            text: this.translateService.instant('schedule-registration.msg.multiple-time-formats-value')
          }
        });
        break;
      case Constant.ERROR_INVALID_DATA_TIMETABLE_EXCEL:
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('dialog-error.title'),
            text: this.translateService.instant('schedule-registration.msg.contains-files-with-invalid-data')
          }
        });
        break;
      case Constant.ERROR_NO_DATA_TIMETABLE_IN_EXCEL:
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('dialog-error.title'),
            text: this.translateService.instant('schedule-registration.msg.no-data-timetable')
          }
        });
        break;
      case Constant.ERROR_HEADER_IS_EMPTY:
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('dialog-error.title'),
            text: this.translateService.instant('schedule-registration.msg.header-is-empty')
          }
        });
        break;
      case Constant.ERROR_EXISTS_NAME_TIMETABLE:
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('dialog-error.title'),
            text: this.translateService.instant('schedule-registration.msg.duplicate-name')
          }
        });
        break;
      case Constant.ERROR_EXISTS_SUFFIX_NO:
        this.dialogService.showDialog(
          DialogConfirmComponent,
          {
            data: {
              text: this.translateService.instant('schedule-registration.msg.duplicate-no-and-suffix'),
              button1: this.translateService.instant('schedule-registration.msg.overwrite-all'),
              button2: this.translateService.instant('schedule-registration.cancel'),
              button3: this.translateService.instant('schedule-registration.msg.add')
            }
          },
          result => {
            if (result == true) {
              this.dialogService.showDialog(
                DialogConfirmComponent,
                {
                  data: {
                    text: this.translateService.instant('schedule-merge.msg.confirm-overwrite-all'),
                    button1: this.translateService.instant('schedule-merge.msg.yes'),
                    button2: this.translateService.instant('schedule-merge.msg.cancel')
                  }
                },
                result => {
                  if (result) {
                    this.scheduleRegistrationService.overWriteTimetables().subscribe(timetablesresult => {
                      this.handleDisplayImport(timetablesresult);
                      this.dataStoredSecond = null;
                      this.timetableSelected = null;
                      this.selectTimetable(this.timetables[0], null);
                      this.dataService.sendData([
                        Constant.IS_RECORDS_TIMETABLE_MAX,
                        this.timetables.length >= Constant.MAX_RECORDS_TIMETABLE
                      ]);
                    });
                  } else {
                    let error = {
                      error: {
                        detail: 'Error exists suffix and no'
                      }
                    };
                    this.handleShowErrorMessageFromServerWhenImport(error);
                  }
                }
              );
            } else if (result == Constant.BUTTON_ADD_IMPORT_TIMETABLE) {
              this.scheduleRegistrationService.addTimetables().subscribe(timetablesresult => {
                this.handleDisplayImport(timetablesresult);
                this.dataStoredSecond = null;
                this.selectTimetable(this.timetables[0], null);
                this.dataService.sendData([Constant.IS_RECORDS_TIMETABLE_MAX, this.timetables.length >= Constant.MAX_RECORDS_TIMETABLE]);
              });
            }
          }
        );
        break;
      default:
        Helper.handleError(error, this.translateService, this.dialogService);
        break;
    }
  }

  /**
   * handleAfterAdds
   * @param timetablesresult
   */
  private handleDisplayImport(timetablesresult: any): void {
    let oldTimetables = _.cloneDeep(this.timetables);
    this.timetables = Helper.convertScheduleRegistrationAfterImport(timetablesresult, this.timeDateLine);
    let timetablesAdd = _.cloneDeep(this.timetables);
    let displayTemp = _.cloneDeep(this.timetablesDisplay);
    let IdsDisplay = [...new Set(displayTemp.map(item => item.id))];
    this.timetablesDisplay = new Array<ScheduleRegistrationDetail>();
    _.remove(timetablesAdd, function(timetable) {
      return oldTimetables.map(item => item.id).includes(timetable.id);
    });
    IdsDisplay = [...IdsDisplay, ...timetablesAdd?.map(data => data.id)];
    IdsDisplay?.forEach(id => {
      this.timetablesDisplay.push(this.timetables.find(timetable => timetable.id == id));
    });
  }

  /**
   * export timetable in schedule registration
   * @returns
   */
  async exportScheduleRegistration(): Promise<void> {
    // check network
    let errorText = await this.commonService
      .checkNetWorkBeforeSave()
      .toPromise()
      .catch(error => {
        if (error.status == Constant.NETWORK_ERROR_CODE) {
          return Constant.NETWORK_ERROR_CODE;
        }
      });
    if (errorText == Constant.NETWORK_ERROR_CODE) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('dialog-error.error-network-api')
        }
      });
      return;
    }
    let timetablesChecked = this.timetables.filter(timetable => timetable.isChecked);
    if (!timetablesChecked?.length) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('schedule-registration.choose-timetable')
        }
      });
      return;
    }
    // case timetables checked > NUMBER_OF_FILES_ALLOWED_TO_EXPORT
    if (timetablesChecked.length > Constant.NUMBER_OF_FILES_ALLOWED_TO_EXPORT) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: Helper.formatString(
            this.translateService.instant('schedule-registration.maximum-files'),
            `${Constant.NUMBER_OF_FILES_ALLOWED_TO_EXPORT}`
          )
        }
      });
      return;
    }

    if (timetablesChecked.some(data => data.name.match(Constant.FORMAT_TIMETABLE_NAME) || data.name.includes('\\'))) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('schedule-registration.name-special-character')
        }
      });
      return;
    }

    timetablesChecked = timetablesChecked.map(timetableCheck => Helper.convertTimetableRegistrationDataBackWardWhenExport(timetableCheck));
    for (const timetable of timetablesChecked) {
      // call api to write to file excel
      this.scheduleRegistrationService.writeExcel(timetable.id).subscribe(
        response => {
          this.uncheckAllTimetable();
          const fileNameResponse = decodeURIComponent(response.headers.get('content-disposition'));
          const file = new File([response.body], fileNameResponse, {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          });
          fileSaver.saveAs(file);
          console.info('File downloaded successfully');
        },
        error => {
          console.log('Error downloading the file');
          this.handleErrorSaveTimetable(error);
        }
      );
    }
  }

  /**
   * handle Error Save Timetable
   * @param error
   */
  private handleErrorSaveTimetable(error: any): void {
    let msg = this.translateService.instant('dialog-error.msg');
    if (error.error?.detail == Constant.ERROR_LIMIT_RECORD) {
      msg = this.translateService.instant('timetable-editor.limit-record-timetable');
    } else if (error.error?.detail == Constant.ERROR_EXISTS_NAME_TIMETABLE) {
      msg = this.translateService.instant('timetable-editor.duplicate-name');
    } else if (error.error?.detail == Constant.ERROR_EXISTS_SUFFIX_NO) {
      msg = this.translateService.instant('timetable-editor.duplicate-timetable');
    }
    this.dialogService.showDialog(DialogMessageComponent, {
      data: {
        title: this.translateService.instant('dialog-error.title'),
        text: msg
      }
    });
  }

  private handleRegister(): void {
    this.dialogService.showDialog(DialogMessageComponent, {
      data: {
        title: this.translateService.instant('dialog-error.title'),
        text: this.translateService.instant('schedule-registration.msg.register-failed')
      }
    });
  }

  /*
   * set repeat
   * @param day
   */
  public setRepeat(day: ContentDay) {
    if (day.isOtherMonth || day.inactive) {
      return;
    }
    this.dialogService.showDialog(
      DialogPlaylistRecurrenceComponent,
      {
        data: {
          contentDay: Object.assign({}, day),
          startDate: day.fullDate,
          calendars: this.calendarsInTwoYear,
          screen: ScreenNameEnum.SCHEDULE_REGISTRATION,
          timeDateLine: this.timeDateLine
        }
      },
      result => {
        if (!result) {
          return;
        }
        let contentDay = result[Constant.CONTENT_DAY_ELEMENT];
        this.isUnlimitedDataFromDialogDataRecurrence = result[Constant.IS_UNLIMITED_ELEMENT];
        if (this.isUnlimitedDataFromDialogDataRecurrence) {
          if (result[Constant.SCREEN_ELEMENT] == ScreenNameEnum.SCHEDULE_REGISTRATION) {
            this.isUnlimited = contentDay.scheduleRegistration ? true : false;
          } else {
            this.isUnlimited = contentDay.timetable ? true : false;
          }
        }
        const date = new Date();
        const currentDate = Helper.getDateByDay(date.getFullYear(), date.getMonth() + 1, date.getDate());
        this.getUsedColors(currentDate);
        this.getUnUsedColors(currentDate);
        // One day
        if (!contentDay.isRepeated) {
          this.setDataContentDay(contentDay, contentDay.fullDate);
          // Repeat
        } else {
          // repeat mode: Every Day
          if (contentDay.repeatMode == RepeatModeEnum.EVERY_DAY) {
            this.handleEveryDayMode(contentDay);
            if (this.isUnlimitedDataFromDialogDataRecurrence) {
              this.updateOldContentDayData(contentDay.startDate);
            }
            // repeat mode: Every Week
          } else if (contentDay.repeatMode == RepeatModeEnum.EVERY_WEEK) {
            let listDay = [];
            // get day selected
            result[Constant.LIST_DAY_ELEMENT].forEach((day, index) => {
              if (day[1]) {
                listDay.push(index);
              }
            });
            this.handleEveryWeekMode(contentDay, listDay);
          }
        }
        this.calendarFromDB = this.calendarsInTwoYear.filter(
          contentDay => contentDay.scheduleRegistration || contentDay.scheduleRegistrationId
        );
        this.contentDaysMonth.forEach(contentDay => {
          let index = this.calendarFromDB.findIndex(content => content.fullDate.getTime() == contentDay.fullDate.getTime());
          if (index != -1) {
            contentDay.scheduleRegistration = this.calendarFromDB[index].scheduleRegistration;
          }
        });
        this.saveContentDaySchedule();
      }
    );
  }

  /**
   * get used colors
   * @param currentDate
   * @returns
   */
  private getUsedColors(currentDate: Date): void {
    // get color being used
    this.colorBeingUsed = new Map<Number, string>();
    this.calendarFromDB?.forEach(contentDay => {
      if (
        contentDay.fullDate >= currentDate &&
        contentDay.scheduleRegistration &&
        !this.colorBeingUsed?.has(contentDay.scheduleRegistration.id)
      ) {
        this.colorBeingUsed.set(contentDay.scheduleRegistration.id, contentDay.color);
      }
    });
  }

  /**
   * Uncheck all timetable
   */
  private uncheckAllTimetable(): void {
    this.timetables.forEach(timetable => (timetable.isChecked = false));
    this.timetablesDisplay.forEach(timetableDisplay => (timetableDisplay.isChecked = false));
  }

  /*
   * get unused colors
   * @param currentDate
   * @returns
   */
  private getUnUsedColors(currentDate: Date): void {
    let colors = new Array<string>();
    let scheduleRegistrationIds = new Set(
      this.calendarFromDB?.filter(contentDay => contentDay.fullDate >= currentDate)?.map(day => day?.scheduleRegistration?.id)
    );
    [...scheduleRegistrationIds].forEach(id => {
      const contentDay = this.calendarFromDB?.find(day => day.fullDate >= currentDate && day?.scheduleRegistration?.id == id && day?.color);
      if (contentDay) {
        colors.push(contentDay.color);
      }
    });

    let colorsOriginal: string[] = _.cloneDeep(this.colorsOriginal);
    while (colors.length >= this.MAX_NUMBER_COLORS) {
      if (!colorsOriginal.length) {
        colorsOriginal = _.cloneDeep(this.colorsOriginal);
      }
      let unUsedColorsOriginal: string[] = _.cloneDeep(colorsOriginal);
      unUsedColorsOriginal.forEach(color => {
        const index = colors.findIndex(c => c == color);
        if (index != -1) {
          colors.splice(index, 1);
          const indexColor = colorsOriginal.findIndex(c => c == color);
          if (indexColor != -1) {
            colorsOriginal.splice(indexColor, 1);
          }
        }
      });
    }
    // if there is an unused original colors
    if (colorsOriginal.length && colorsOriginal.length != this.colorsOriginal.length) {
      this.unUsedColors = colorsOriginal;
      // if using all colors
    } else if (!colors.length) {
      this.unUsedColors = _.cloneDeep(this.colorsOriginal);
    } else {
      let colorsOriginal: string[] = _.cloneDeep(this.colorsOriginal);
      colors.forEach(color => {
        const index = colorsOriginal.findIndex(c => c == color);
        if (index == -1) {
          return;
        }
        colorsOriginal.splice(index, 1);
      });
      this.unUsedColors = colorsOriginal;
    }
  }

  /**
   * set data content day
   * @param contentDay ContentDay
   * @param date Date
   */
  public setDataContentDay(contentDay: ContentDay, date: Date): void {
    let indexRepeat = this.calendarsInTwoYear.findIndex(calendarDay => calendarDay.fullDate.getTime() === date.getTime());
    if (!this.calendarsInTwoYear[indexRepeat].scheduleRegistration && !contentDay.scheduleRegistration) {
      return;
    }
    this.calendarsInTwoYear[indexRepeat].isRegistered = false;
    if (!contentDay.scheduleRegistration) {
      this.calendarsInTwoYear[indexRepeat].color = '';
      this.calendarsInTwoYear[indexRepeat].scheduleRegistration = new ScheduleRegistrationDetail(-1);
      this.calendarsInTwoYear[indexRepeat].scheduleRegistrationId = -1;
      this.handleDataOfUnlimitedDataInfo(indexRepeat, -1, contentDay);
      return;
    }

    this.calendarsInTwoYear[indexRepeat].scheduleRegistration = contentDay.scheduleRegistration;
    this.calendarsInTwoYear[indexRepeat].scheduleRegistrationId = contentDay.scheduleRegistrationId;
    const id = this.calendarsInTwoYear[indexRepeat].scheduleRegistration.id;

    if (this.colorBeingUsed?.has(id)) {
      this.calendarsInTwoYear[indexRepeat].color = this.colorBeingUsed?.get(id);
      this.calendarsInTwoYear[indexRepeat].scheduleRegistrationId = id;
      this.handleDataOfUnlimitedDataInfo(indexRepeat, id, contentDay);
      return;
    }
    const color = this.unUsedColors.shift();
    this.calendarsInTwoYear[indexRepeat].color = color;
    this.colorBeingUsed.set(id, color);
    this.calendarsInTwoYear[indexRepeat].scheduleRegistrationId = id;
    this.handleDataOfUnlimitedDataInfo(indexRepeat, id, contentDay);
  }

  /**
   * Update old content day Data
   *
   * @param startDate
   * @returns
   */
  private updateOldContentDayData(startDate: Date): void {
    let startContentDay = this.calendarFromDB.find(content => content.unlimitedInfo && content.fullDate.getTime() == startDate.getTime());
    if (!startContentDay) {
      return;
    }
    this.calendarFromDB.forEach(contentDay => {
      if (contentDay.fullDate.getTime() < startDate.getTime()) {
        contentDay.unlimitedInfo = startContentDay.unlimitedInfo;
      }
    });
  }

  /**
   * handle repeat mode: Every Day
   * @param contentDay content of day
   */
  private handleEveryDayMode(contentDay: any): void {
    const endYear = contentDay.finishDate.getFullYear();
    const startYear = contentDay.startDate.getFullYear();
    if (endYear == startYear) {
      // if finish date same month
      if (contentDay.finishDate.getMonth() == contentDay.startDate.getMonth()) {
        for (let day = contentDay.startDate.getDate(); day <= contentDay.finishDate.getDate(); day++) {
          let date = Helper.getDateByDay(startYear, contentDay.startDate.getMonth() + 1, day);
          this.setDataContentDay(contentDay, date);
        }
        // if finish date > start date (dif month)
      } else if (contentDay.finishDate.getTime() > contentDay.startDate.getTime()) {
        // get data repeat current month
        let daysInMonth = Helper.daysInMonth(contentDay.startDate);
        for (let day = contentDay.startDate.getDate(); day <= daysInMonth; day++) {
          let date = Helper.getDateByDay(startYear, contentDay.startDate.getMonth() + 1, day);
          this.setDataContentDay(contentDay, date);
        }
        // get data repeat next month
        for (let month = contentDay.startDate.getMonth() + 1; month < contentDay.finishDate.getMonth(); month++) {
          let date = Helper.getDateByMonth(startYear, month + 1);
          let daysInMonth = Helper.daysInMonth(date);
          for (let day = 1; day <= daysInMonth; day++) {
            let date = Helper.getDateByDay(startYear, month + 1, day);
            this.setDataContentDay(contentDay, date);
          }
        }
        // get data repeat end month
        let monthEndDate = Helper.getDateByMonth(startYear, contentDay.finishDate.getMonth() + 1);
        for (let day = monthEndDate.getDate(); day <= contentDay.finishDate.getDate(); day++) {
          let date = Helper.getDateByDay(monthEndDate.getFullYear(), monthEndDate.getMonth() + 1, day);
          this.setDataContentDay(contentDay, date);
        }
      }
    } else if (endYear > startYear) {
      // get data repeat current month
      let daysInMonth = Helper.daysInMonth(contentDay.startDate);
      for (let day = contentDay.startDate.getDate(); day <= daysInMonth; day++) {
        let date = Helper.getDateByDay(startYear, contentDay.startDate.getMonth() + 1, day);
        this.setDataContentDay(contentDay, date);
      }
      // get data repeat month of start date -> 12
      for (let month = contentDay.startDate.getMonth() + 1; month < 12; month++) {
        let date = Helper.getDateByMonth(startYear, month + 1);
        let daysInMonth = Helper.daysInMonth(date);
        for (let day = 1; day <= daysInMonth; day++) {
          let date = Helper.getDateByDay(startYear, month + 1, day);
          this.setDataContentDay(contentDay, date);
        }
      }
      if (endYear == startYear + Constant.MAX_YEAR) {
        for (let i = 1; i < Constant.MAX_YEAR; i++) {
          // get data repeat month of start date 1 -> 12 next year
          for (let month = 0; month < 12; month++) {
            let date = Helper.getDateByMonth(startYear + i, month + 1);
            let daysInMonth = Helper.daysInMonth(date);
            for (let day = 1; day <= daysInMonth; day++) {
              let date = Helper.getDateByDay(startYear + i, month + 1, day);
              this.setDataContentDay(contentDay, date);
            }
          }
        }
      }
      // get data repeat month of end date previous end month
      for (let month = 1; month < contentDay.finishDate.getMonth() + 1; month++) {
        let date = Helper.getDateByMonth(endYear, month);
        let daysInMonth = Helper.daysInMonth(date);
        for (let day = 1; day <= daysInMonth; day++) {
          let date = Helper.getDateByDay(endYear, month, day);
          this.setDataContentDay(contentDay, date);
        }
      }
      // get data repeat end month
      let monthEndDate = Helper.getDateByMonth(endYear, contentDay.finishDate.getMonth() + 1);
      for (let day = monthEndDate.getDate(); day <= contentDay.finishDate.getDate(); day++) {
        let date = Helper.getDateByDay(monthEndDate.getFullYear(), monthEndDate.getMonth() + 1, day);
        this.setDataContentDay(contentDay, date);
      }
    }
  }

  /**
   * handle repeat mode: Every Week
   * @param contentDay content of day
   * @param listDay
   */
  private handleEveryWeekMode(contentDay: any, listDay: any): void {
    const endYear = contentDay.finishDate.getFullYear();
    const startYear = contentDay.startDate.getFullYear();
    if (endYear == startYear) {
      // if finish date same month
      if (contentDay.finishDate.getMonth() == contentDay.startDate.getMonth()) {
        for (let day = contentDay.startDate.getDate(); day <= contentDay.finishDate.getDate(); day++) {
          let date = Helper.getDateByDay(startYear, contentDay.startDate.getMonth() + 1, day);
          if (listDay.findIndex(index => date.getDay() == index) != -1) {
            this.setDataContentDay(contentDay, date);
          }
        }
        // if finish date > start date (dif month)
      } else if (contentDay.finishDate.getTime() > contentDay.startDate.getTime()) {
        // get data repeat current month
        let daysInMonth = Helper.daysInMonth(contentDay.startDate);
        for (let day = contentDay.startDate.getDate(); day <= daysInMonth; day++) {
          let date = Helper.getDateByDay(startYear, contentDay.startDate.getMonth() + 1, day);
          if (listDay.findIndex(index => date.getDay() == index) != -1) {
            this.setDataContentDay(contentDay, date);
          }
        }
        // get data repeat next month
        for (let month = contentDay.startDate.getMonth() + 1; month < contentDay.finishDate.getMonth(); month++) {
          let date = Helper.getDateByMonth(startYear, month + 1);
          let daysInMonth = Helper.daysInMonth(date);
          for (let day = 1; day <= daysInMonth; day++) {
            let date = Helper.getDateByDay(startYear, month + 1, day);
            if (listDay.findIndex(index => date.getDay() == index) != -1) {
              this.setDataContentDay(contentDay, date);
            }
          }
        }
        // get data repeat end month
        let monthEndDate = Helper.getDateByMonth(startYear, contentDay.finishDate.getMonth() + 1);
        for (let day = monthEndDate.getDate(); day <= contentDay.finishDate.getDate(); day++) {
          let date = Helper.getDateByDay(monthEndDate.getFullYear(), monthEndDate.getMonth() + 1, day);
          if (listDay.findIndex(index => date.getDay() == index) != -1) {
            this.setDataContentDay(contentDay, date);
          }
        }
      }
    } else if (endYear > startYear) {
      // get data repeat current month
      let daysInMonth = Helper.daysInMonth(contentDay.startDate);
      for (let day = contentDay.startDate.getDate(); day <= daysInMonth; day++) {
        let date = Helper.getDateByDay(startYear, contentDay.startDate.getMonth() + 1, day);
        if (listDay.findIndex(index => date.getDay() == index) != -1) {
          this.setDataContentDay(contentDay, date);
        }
      }
      // get data repeat month of start date -> 12
      for (let month = contentDay.startDate.getMonth() + 1; month < 12; month++) {
        let date = Helper.getDateByMonth(startYear, month + 1);
        let daysInMonth = Helper.daysInMonth(date);
        for (let day = 1; day <= daysInMonth; day++) {
          let date = Helper.getDateByDay(startYear, month + 1, day);
          if (listDay.findIndex(index => date.getDay() == index) != -1) {
            this.setDataContentDay(contentDay, date);
          }
        }
      }
      if (endYear == startYear + Constant.MAX_YEAR) {
        for (let i = 1; i < Constant.MAX_YEAR; i++) {
          // get data repeat month of start date 1 -> 12 next year
          for (let month = 0; month < 12; month++) {
            let date = Helper.getDateByMonth(startYear + i, month + 1);
            let daysInMonth = Helper.daysInMonth(date);
            for (let day = 1; day <= daysInMonth; day++) {
              let date = Helper.getDateByDay(startYear + i, month + 1, day);
              if (listDay.findIndex(index => date.getDay() == index) != -1) {
                this.setDataContentDay(contentDay, date);
              }
            }
          }
        }
      }
      // get data repeat month of end date previous end month
      for (let month = 1; month < contentDay.finishDate.getMonth() + 1; month++) {
        let date = Helper.getDateByMonth(endYear, month);
        let daysInMonth = Helper.daysInMonth(date);
        for (let day = 1; day <= daysInMonth; day++) {
          let date = Helper.getDateByDay(endYear, month, day);
          if (listDay.findIndex(index => date.getDay() == index) != -1) {
            this.setDataContentDay(contentDay, date);
          }
        }
      }
      // get data repeat end month
      let monthEndDate = Helper.getDateByMonth(endYear, contentDay.finishDate.getMonth() + 1);
      for (let day = monthEndDate.getDate(); day <= contentDay.finishDate.getDate(); day++) {
        let date = Helper.getDateByDay(monthEndDate.getFullYear(), monthEndDate.getMonth() + 1, day);
        if (listDay.findIndex(index => date.getDay() == index) != -1) {
          this.setDataContentDay(contentDay, date);
        }
      }
    }
  }

  /**
   * save content days
   */
  private saveContentDaySchedule(): void {
    let calendarFilter = this.calendarsInTwoYear.filter(calendar => calendar.scheduleRegistrationId);
    this.scheduleContentDayService
      .updateContentDaysSchedule(Helper.convertDataScheduleContentDayBackward(calendarFilter, this.commonObject.setting), this.isUnlimited)
      .subscribe(
        () => {
          this.toast.success(this.translateService.instant('common.save-success'), '');
          this.saveDataSuccess.emit(true);
        },
        error => {
          Helper.handleError(error, this.translateService, this.dialogService);
          this.saveDataSuccess.emit(false);
        }
      );
  }

  /**
   * Handle data of unlimited data information
   *
   * @param indexRepeat
   * @param timetableId
   * @param contentDay
   */
  private handleDataOfUnlimitedDataInfo(indexRepeat: number, scheduleRegistrationId: Number, contentDay: ContentDay): void {
    if (!this.isUnlimited) {
      this.calendarsInTwoYear[indexRepeat].unlimitedInfo = null;
      return;
    }
    if (this.isUnlimitedDataFromDialogDataRecurrence) {
      let unlimitedInfoObject = {};
      unlimitedInfoObject['scheduleRegistrationId'] = scheduleRegistrationId;
      unlimitedInfoObject['color'] = this.colorBeingUsed?.get(scheduleRegistrationId);
      unlimitedInfoObject['deadlineDate'] = Helper.convertDateToFormatDateSave(contentDay.finishDate, this.commonObject.setting);
      unlimitedInfoObject['isRegistered'] = false;
      this.calendarsInTwoYear[indexRepeat].unlimitedInfo = unlimitedInfoObject;
    }
  }

  /**
   * get all calendar
   *
   */
  public getAllCalendar(isRegistered?: boolean): void {
    this.unUsedColors = _.cloneDeep(this.colorsOriginal);
    this.colorBeingUsed = new Map<Number, string>();
    this.scheduleContentDayService.getContentDaysSchedule().subscribe(
      contentDaysData => {
        if (contentDaysData.length > 0) {
          this.calendarFromDB = contentDaysData.map(contentDayData => {
            return Helper.convertDataContentDayForSchedule(contentDayData);
          });
        }
        this.isUnlimited = this.calendarFromDB.filter(contentDay => contentDay.fullDate >= new Date()).some(day => day.unlimitedInfo);
        this.calendarsInTwoYear = Helper.getCalendarsFromCurrentDate();
        this.calendarFromDB.forEach(contentDay => {
          let date = Helper.getDateByDay(
            contentDay.fullDate.getFullYear(),
            contentDay.fullDate.getMonth() + 1,
            contentDay.fullDate.getDate()
          );
          let index = this.calendarsInTwoYear.findIndex(content => content.fullDate.getTime() == date.getTime());
          if (index != -1) {
            contentDay.day = this.calendarsInTwoYear[index].day;
            this.calendarsInTwoYear[index] = contentDay;
            this.colorBeingUsed.set(contentDay.scheduleRegistration?.id, contentDay.color);
          }
        });

        // get contentDays by month selected and year selected
        this.contentDaysMonth = Helper.getCalendarsByMonthYearSchedule(
          this.calendarsInTwoYear,
          this.selectedMonth,
          isRegistered ? this.selectedYear : this.currentDate.getFullYear()
        );
      },
      error => Helper.handleError(error, this.translateService, this.dialogService)
    );
  }

  /**
   * getAllSettingTemplateIds
   */
  private getAllSettingTemplateIds(): void {
    this.scheduleRegistrationService.getDisplaySettingSchedule().subscribe(data => {
      if (!data) {
        return;
      }
      const templateIds = JSON.parse(data['templateIds']);
      this.templateIds = [...new Set(templateIds.filter(element => element))];
      this.getTemplateByTemplateIds(this.templateIds);
    });
  }

  /**
   * get Template By TemplateIds
   * @param templateIds
   */
  private getTemplateByTemplateIds(templateIds: Array<Number>): void {
    this.templateService.getTemplateByTemplateIds(templateIds).subscribe(templates => {
      this.templates = templates.map((template: any) => {
        return Helper.convertDataTemplateBackward(template);
      });
    });
  }

  /**
   * change template
   * @param displayId
   */
  public changeTemplate(): void {
    this.drawScheduleService.clearAllThreadDrawTemplate(this.templateSelected);
    const display = this.templates.find(item => item.id == this.templateIdSelected);
    this.templateSelected = display;
    if (!this.templateSelected) {
      this.isPlay = false;
      this.drawScheduleService.pausePreview();
      Helper.clearNodeChild(this.divContainCanvasRegistration?.nativeElement);
      this.dataService.sendData([this.IS_PREVIEW_ON_SCR, this.isPlay]);
      return;
    }
    this.referencePositionColumnsByTemplate = Helper.getReferencePositionColumnsByTemplate(this.templateSelected);
    this.referencePositionColumnsByTemplate = this.fillDataForReferencePositionColumn();
    if (this.isPlay) {
      this.changeStatePreview();
    }
    this.drawDisplay(display);
  }

  /**
   * subscribe event mouse wheel
   *
   * @param e
   * @returns
   */
  @HostListener('mousewheel', ['$event'])
  mouseWheel(e) {
    if (!this.isZoom) {
      return;
    }
    if (e.target.id.includes('registration-previewCanvas')) {
      this.panzoomDisplay.zoomWithWheel(e);
    }
  }

  /**
   * draw display template
   * @param display Template
   */
  private drawDisplay(display: Template): void {
    this.changeDetectorRef.detectChanges();
    const canvasDisplayNode = this.divContainCanvasRegistration?.nativeElement;
    // clear node child
    Helper.clearNodeChild(canvasDisplayNode);
    if (!display) {
      return;
    }
    let areaTimetableAndIndexWord = Helper.getAllAreaReferenceTimetableAndIndexWord(display);

    this.areasTimetable = areaTimetableAndIndexWord[this.TIMETABLE_INDEX];
    this.areasIndexWord = areaTimetableAndIndexWord[this.INDEX_WORD_INDEX];
    this.drawScheduleService.clearIntervalsClock();
    if (canvasDisplayNode) {
      // draw
      this.createCanvasTemplate(display, this.renderer);
      this.drawScheduleService.createAllCanvasAreaTemplate(display, this.divContainCanvasRegistration, this.renderer);
      this.calculateScaleTransformCanvas(display);
      if (this.isPan) {
        this.renderer.setStyle(this.divContainCanvasRegistration.nativeElement, 'cursor', 'move');
      } else {
        this.renderer.setStyle(this.divContainCanvasRegistration.nativeElement, 'cursor', 'default');
      }
      this.drawScheduleService.setupPreview(this.timetableSelected, this.mediaSetting ?? undefined);
      let timetableSchedule = this.timetablesDisplaySecond;
      this.drawScheduleService.resetData();
      if (timetableSchedule) {
        let areasTimetableDraw = _.cloneDeep(this.areasTimetable);
        if (areasTimetableDraw?.length) {
          // draw time table
          this.referencePositionColumnsByTemplate = Helper.getReferencePositionColumnsByTemplate(display);
          this.referencePositionColumnsByTemplate = this.fillDataForReferencePositionColumn();
          this.drawScheduleService.setDataTimetables(this.currentIndexSchedule, this.referencePositionColumnsByTemplate, this.timeDateLine);
          this.drawScheduleService.setDataPreviewTimetableEditor(timetableSchedule);
        }
        this.areasTimetableDrew = areasTimetableDraw;
        let areasIndexWordDraw = _.cloneDeep(this.areasIndexWord);
        if (areasIndexWordDraw?.length) {
          this.indexWordService.getIndexWordsByNameAndGroupId(this.getParamForGetIndexWord(areasIndexWordDraw)).subscribe(indexWords => {
            Helper.setDataIndexWordForAreasRegistration(
              areasIndexWordDraw,
              indexWords,
              this.timetablesDisplaySecond,
              this.currentIndexSchedule
            );
            this.drawScheduleService.setDataPreviewTimetableEditor(timetableSchedule, null, null, areasIndexWordDraw);
            this.drawScheduleService.drawPreview(display, this.renderer);
            this.drawScheduleService.changeStatePlayPause(this.isPlay);
          });
          this.areasIndexWordDrew = areasIndexWordDraw;
        } else {
          this.drawScheduleService.drawPreview(display, this.renderer);
        }
      } else {
        this.drawScheduleService.drawPreview(display, this.renderer);
      }
    }
  }

  /**
   * get list name index word and list group index word
   * @param areas
   * @returns list name index word and list group index word
   */
  private getParamForGetIndexWord(areas: Area[]): any {
    let listNameIndexWord = [];
    let listGroupId = [];
    if (!this.referencePositionColumnsByTemplate?.length) {
      return [listGroupId, listNameIndexWord];
    }
    areas.forEach(area => {
      let nameIndexWord = Helper.getNameIndexWordFromScheduleRegistration(
        this.timetablesDisplaySecond,
        area,
        this.currentIndexSchedule,
        this.referencePositionColumnsByTemplate.findIndex(reference => area.referencePositionColumn + 1 === reference)
      );
      listGroupId.push(area.indexWordGroupId);
      listNameIndexWord.push(nameIndexWord);
    });
    return [listGroupId, listNameIndexWord];
  }

  /**
   * Fill data for reference position column
   *
   * @returns
   */
  private fillDataForReferencePositionColumn(): number[] {
    let newReferencePositionColumnsByTemplate = [];
    if (this.referencePositionColumnsByTemplate) {
      const lastReferencePositionColumn = this.referencePositionColumnsByTemplate[this.referencePositionColumnsByTemplate.length - 1];
      for (let index = 0; index <= lastReferencePositionColumn; index++) {
        newReferencePositionColumnsByTemplate.push(index);
      }
    }
    return _.sortBy(newReferencePositionColumnsByTemplate);
  }
  /**
   * create canvas template
   * @param template template
   * @param renderer
   */
  public createCanvasTemplate(template: Template, renderer: Renderer2): void {
    const canvas = renderer.createElement('canvas');
    canvas.id = 'registration-previewCanvas';
    canvas.style.position = 'absolute';
    canvas.style.background = '#000';
    canvas.style.width = template.width + 'px';
    canvas.style.height = template.height + 'px';
    canvas.width = template.width;
    canvas.height = template.height;
    renderer.appendChild(this.divContainCanvasRegistration.nativeElement, canvas);
  }

  /**
   * calculate scale transform canvas
   *
   * @param template
   */
  private calculateScaleTransformCanvas(template: Template): void {
    if (!template) {
      return;
    }
    this.changeDetectorRef.detectChanges();
    const maxHeight = this.divPreviewDisplayRegistration.nativeElement.clientHeight - 21;
    const maxWidth = this.divPreviewDisplayRegistration.nativeElement.clientWidth - 24;
    let scaleTransform = { scaleX: 1, scaleY: 1 };
    if (template.width > maxWidth) {
      scaleTransform.scaleX = maxWidth / template.width;
    }
    if (template.height > maxHeight) {
      scaleTransform.scaleY = maxHeight / template.height;
    }
    const scale = Math.min(scaleTransform.scaleX, scaleTransform.scaleY);
    this.renderer.setStyle(this.divContainCanvasRegistration.nativeElement, 'transform', 'scale(' + scale + ')');
    const realHeightTemplate = template.height * scale;
    const realWidthTemplate = template.width * scale;
    const height = (maxHeight - realHeightTemplate) / 2;
    const width = (maxWidth - realWidthTemplate) / 2;
    this.divContainCanvasRegistration.nativeElement.style.marginTop = height + 'px';
    this.divContainCanvasRegistration.nativeElement.style.marginLeft = width + 'px';
    if (this.panzoomDisplay) {
      this.panzoomDisplay.reset({
        startScale: scale,
        minScale: 0.1,
        maxScale: 2,
        startX: 0,
        startY: 0
      });
    } else {
      this.panzoomDisplay = Panzoom(this.divContainCanvasRegistration?.nativeElement, { startScale: scale, minScale: 0.1, maxScale: 2 });
    }
    this.panzoomDisplay.setOptions({ disablePan: !this.isPan, disableZoom: !this.isZoom, force: true });
  }

  /**
   * update data registration
   * @returns
   */
  private updateDataRegistration(): void {
    if (this.tabSelected == Tab_Enum.TIMETABLE) {
      return;
    }
    if (this.checkInvalidTime(_.cloneDeep(this.calendarFromDB))) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('schedule-registration.msg.register-failed')
        }
      });
      return;
    }
    if (!this.checkValidTimeNextDay(_.cloneDeep(this.calendarFromDB)?.filter(contentD => contentD.fullDate >= this.currentDate))) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('dialog-playlist-recurrence.duplicate-schedule')
        }
      });
      return;
    }
    let dataSetting = new PublishSetting(this.commonService.getCommonObject().userName);
    dataSetting.timezone = Helper.getUserTimeZone(this.commonService.getCommonObject().setting);
    this.publishTimetableService.updateDataRegistration(dataSetting).subscribe(
      () => {
        let calendarFilters = this.calendarsInTwoYear.filter(calendar => calendar.scheduleRegistrationId);
        calendarFilters.forEach(calendarFilter => {
          calendarFilter.isRegistered = true;
        });
        this.scheduleContentDayService
          .updateContentDaysSchedule(
            Helper.convertDataScheduleContentDayBackward(calendarFilters, this.commonObject.setting),
            this.isUnlimited
          )
          .subscribe(
            () => {
              this.dialogService.showDialog(DialogMessageComponent, {
                data: {
                  title: this.translateService.instant('dialog-confirm.title'),
                  text: this.translateService.instant('schedule-registration.msg.update-success')
                }
              });
              this.getAllCalendar(true);
              this.saveDataSuccess.emit(true);
            },
            error => {
              Helper.handleError(error, this.translateService, this.dialogService);
              this.saveDataSuccess.emit(false);
            }
          );
      },
      () => {
        this.handleRegister();
      }
    );
  }
  /**
   * export calendar schedule registration
   * @returns
   */
  public exportCalendarScheduleRegistration(): void {
    // check exist data of device
    this.scheduleContentDayService.checkExistDataOfCalendar().subscribe(
      data => {
        if (!data) {
          this.dialogService.showDialog(DialogMessageComponent, {
            data: {
              title: this.translateService.instant('dialog-error.title'),
              text: this.translateService.instant('dialog-export-calendar.no-data')
            }
          });
          return;
        }
        // open popup
        this.dialogService.showDialog(
          DialogExportCalendarComponent,
          {
            data: {
              screen: ScreenNameEnum.SCHEDULE_REGISTRATION
            }
          },
          () => {}
        );
      },
      error => this.handleErrorSaveTimetable(error)
    );
  }
  /**
   * multi language message error
   * @returns
   */
  public multiLanguageTooltip(): void {
    if (!this.timetablesDisplaySecond?.length) {
      return;
    }
    this.timetablesDisplaySecond.forEach(item => {
      let timeInvalid = Helper.formatString(
        this.translateService.instant('schedule-registration.time-invalid'),
        `${this.timetableSelected.headers[0]}`
      );
      if (item.inValidRow) {
        item.titleTooltip = timeInvalid;
      }
    });
  }

  /**
   * add timetable schedule registration
   */
  public addTimetableScheduleRegistration(): void {
    if (this.timetableSelected && this.timetableSelected.isEdit) {
      return;
    }
    this.nameEdit = Constant.EMPTY;
    this.noEdit = Constant.EMPTY;
    this.suffixEdit = Constant.EMPTY;
    let timetableNew = new ScheduleRegistrationDetail(null, this.noEdit, this.suffixEdit, this.nameEdit);
    timetableNew.isEdit = true;
    this.timetables.push(timetableNew);
    this.timetablesDisplay.push(timetableNew);
    this.timetableSelected = timetableNew;
    this.dataService.sendData([this.IS_EDITING_TIMETABLE, this.timetableSelected.isEdit]);
  }

  /**
   * save timetable
   */
  public async saveTimetable(): Promise<void> {
    let oldTimetable = Object.assign({}, this.timetableSelected);
    let no = this.noEdit;
    let suffix = this.suffixEdit;
    let name = this.nameEdit;

    // validate timetable No, Name, suffix
    if (!(await this.validateTimetable(no, suffix, name))) {
      return;
    }

    // enter suffix full empty character
    if (!suffix.trim().length) {
      suffix = suffix.trim();
      this.suffixEdit = suffix;
    }

    // validate duplicate no + suffix
    this.scheduleRegistrationService.checkExistTimetable(no, suffix, this.timetableSelected.id ?? null).subscribe(data => {
      if (data) {
        this.noElementRef.nativeElement.focus();
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('dialog-error.title'),
            text: this.translateService.instant('timetable-editor.duplicate-timetable')
          },
          disableClose: true
        });
        this.saveDataSuccess.emit(false);
        return;
      }
      // validate duplicate name
      this.scheduleRegistrationService.checkExistTimetableName(name, this.timetableSelected.id ?? null).subscribe(data => {
        if (data) {
          this.nameElementRef.nativeElement.focus();
          this.dialogService.showDialog(DialogMessageComponent, {
            data: {
              title: this.translateService.instant('dialog-error.title'),
              text: this.translateService.instant('timetable-editor.duplicate-name')
            },
            disableClose: true
          });
          this.saveDataSuccess.emit(false);
          return;
        }
        // assign value
        this.timetableSelected.name = name;
        this.timetableSelected.no = no;
        this.timetableSelected.suffix = suffix;
        // edit timetable
        if (this.timetableSelected.id) {
          this.scheduleRegistrationService
            .saveTimetable(Helper.convertDataTimetableScheduleRegistrationBackward(this.timetableSelected))
            .subscribe(
              timetableData => {
                this.handleAfterSaveTimetable();
                if (!timetableData) {
                  return;
                }
                let timetableEdited = Helper.convertDataScheduleRegistration(timetableData);
                timetableEdited.isChecked = this.timetableSelected.isChecked;
                timetableEdited.isEdit = this.timetableSelected.isEdit;
                let index = this.timetables.findIndex(timetable => timetable.id === timetableEdited.id);
                if (index == -1) {
                  return;
                }
                this.timetables[index] = timetableEdited;
                let indexTimetableEditOnDisplay = this.timetablesDisplay.findIndex(timetable => timetable.id === timetableEdited.id);
                if (indexTimetableEditOnDisplay > -1) {
                  this.timetablesDisplay[indexTimetableEditOnDisplay] = this.timetables[index];
                }
                this.selectTimetable(this.timetables[index], null);
                if (oldTimetable.name != timetableEdited.name) {
                  this.calendarsInTwoYear
                    .filter(contentDay => contentDay.timetable?.name == oldTimetable.name)
                    .map(contentDayData => {
                      contentDayData.timetable.name = timetableEdited.name;
                    });
                }
              },
              error => {
                this.handleErrorSaveTimetable(error);
                this.saveDataSuccess.emit(false);
              }
            );
        } else {
          //add, duplicate timetable
          this.scheduleRegistrationService
            .saveTimetable(Helper.convertDataTimetableScheduleRegistrationBackward(this.timetableSelected))
            .subscribe(
              timetableData => {
                this.handleAfterSaveTimetable();
                if (!timetableData) {
                  return;
                }
                let timetableAddOrDuplicate = Helper.convertDataScheduleRegistration(timetableData);
                timetableAddOrDuplicate.isEdit = this.timetableSelected.isEdit;
                this.timetables[this.timetables.length - 1] = timetableAddOrDuplicate;
                this.timetablesDisplay[this.timetablesDisplay.length - 1] = timetableAddOrDuplicate;
                this.selectTimetable(this.timetables[this.timetables.length - 1], null);
              },
              error => {
                this.handleErrorSaveTimetable(error);
                this.saveDataSuccess.emit(false);
              }
            );
        }
      });
    });
  }

  /**
   * handleAfterSaveTimetable
   */
  private handleAfterSaveTimetable(): void {
    this.timetableSelected.isEdit = false;
    this.dataService.sendData([this.IS_EDITING_TIMETABLE, this.timetableSelected.isEdit]);
    this.saveDataSuccess.emit(true);
  }

  /**
   * validate timetable when add new/ edit
   * @param no
   * @param suffix
   * @param name
   */
  private async validateTimetable(no: string, suffix: string, name: string): Promise<boolean> {
    // validate timetable no
    if (no.trim().length < Constant.MIN_NO_LENGTH) {
      this.noElementRef.nativeElement.focus();
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('timetable-editor.no-empty')
        }
      });
      this.saveDataSuccess.emit(false);
      return false;
    }
    if (no.length > Constant.MAX_NO_LENGTH) {
      this.noElementRef.nativeElement.focus();
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('timetable-editor.no-length')
        }
      });
      this.saveDataSuccess.emit(false);
      return false;
    }
    if (!no.match(Constant.FORMAT_NO_REGEX)) {
      this.noElementRef.nativeElement.focus();
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('timetable-editor.no-format')
        }
      });
      this.saveDataSuccess.emit(false);
      return false;
    }

    // validate suffix
    if (suffix.trim().length != 0 && suffix.length > Constant.MAX_SUFFIX_LENGTH) {
      this.suffixElementRef.nativeElement.focus();
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('timetable-editor.suffix-length')
        }
      });
      this.saveDataSuccess.emit(false);
      return false;
    }
    if (suffix.trim().length != 0 && !suffix.match(Constant.FORMAT_SUFFIX_REGEX)) {
      this.suffixElementRef.nativeElement.focus();
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('timetable-editor.suffix-format')
        }
      });
      this.saveDataSuccess.emit(false);
      return false;
    }

    // validate timetable name
    if (name.trim().length < Constant.MIN_NAME_LENGTH) {
      this.nameElementRef.nativeElement.focus();
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('timetable-editor.name-empty')
        }
      });
      this.saveDataSuccess.emit(false);
      return false;
    }
    if (name.length > Constant.MAX_NAME_LENGTH) {
      this.nameElementRef.nativeElement.focus();
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('timetable-editor.name-length')
        }
      });
      this.saveDataSuccess.emit(false);
      return false;
    }
    if (name.match(Constant.FORMAT_TIMETABLE_NAME) || name.trim().includes('\\')) {
      this.nameElementRef.nativeElement.focus();
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('timetable-editor.name-special-character')
        }
      });
      this.saveDataSuccess.emit(false);
      return false;
    }
    // check network
    let errorText = await this.commonService
      .checkNetWorkBeforeSave()
      .toPromise()
      .catch(error => {
        if (error.status == Constant.NETWORK_ERROR_CODE) {
          return Constant.NETWORK_ERROR_CODE;
        }
      });
    if (errorText == Constant.NETWORK_ERROR_CODE) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('dialog-error.error-network-api')
        }
      });
      return false;
    }
    return true;
  }

  /**
   * edit time table
   */
  private editTimeTable(): void {
    if (!this.timetableSelected) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('timetable-editor.choose-timetable')
        }
      });
      return;
    } else if (this.timetableSelected.isEdit) {
      return;
    }
    this.noEdit = this.timetableSelected.no;
    this.suffixEdit = this.timetableSelected.suffix;
    this.nameEdit = this.timetableSelected.name;
    this.timetableSelected.isEdit = true;
    let indexTimetable = this.timetables.findIndex(timetable => timetable.id == this.timetableSelected.id);
    if (indexTimetable != -1) {
      this.timetables[indexTimetable].isEdit = true;
    }
    let index = this.timetablesDisplay.findIndex(timetableDisplay => timetableDisplay.id == this.timetableSelected.id);
    if (index != -1) {
      this.timetablesDisplay[index].isEdit = true;
    }
    this.dataService.sendData([this.IS_EDITING_TIMETABLE, this.timetableSelected.isEdit]);
  }

  /**
   * duplicate timetable
   *
   * @returns
   */
  private duplicateTimeTable(): void {
    if (!this.timetableSelected) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('timetable-editor.choose-timetable')
        }
      });
      return;
    } else if (this.timetableSelected.isEdit) {
      return;
    }
    this.noEdit = this.timetableSelected.no;
    this.suffixEdit = this.timetableSelected.suffix;
    this.nameEdit = this.timetableSelected.name;
    let duplicatedTimetable = new ScheduleRegistrationDetail(null, this.noEdit, this.suffixEdit, this.nameEdit);
    duplicatedTimetable.headers = this.timetableSelected?.headers;
    duplicatedTimetable.label = this.timetableSelected?.label;
    duplicatedTimetable.schedules = this.timetableSelected?.schedules;
    duplicatedTimetable.isEdit = true;
    this.timetableSelected = duplicatedTimetable;
    this.timetables.push(duplicatedTimetable);
    this.timetablesDisplay.push(duplicatedTimetable);
    this.dataService.sendData([this.IS_EDITING_TIMETABLE, this.timetableSelected.isEdit]);
  }

  /**
   * delete timetable
   *
   * @returns
   */
  private deleteTimeTable(): void {
    let checkedTimetables = this.timetables.filter(timetable => timetable.isChecked);
    if (!checkedTimetables?.length) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('timetable-editor.choose-timetable')
        }
      });
      return;
    }
    if (this.timetableSelected?.isEdit) {
      return;
    }

    this.scheduleRegistrationService.checkTimetablesSettingCalendar(checkedTimetables.map(timetable => timetable.id)).subscribe(
      data => {
        let key = data ? 'delete-timetable-delivered' : 'delete-checked-timetables';
        this.dialogService.showDialog(
          DialogConfirmComponent,
          {
            data: {
              text: this.translateService.instant(`timetable-editor.${key}`),
              button1: this.translateService.instant('timetable-editor.yes'),
              button2: this.translateService.instant('timetable-editor.btn-no')
            }
          },
          result => {
            if (!result) {
              return;
            }
            this.handleDeleteTimetables(checkedTimetables);
            this.dataStoredSecond = null;
          }
        );
      },
      error => Helper.handleError(error, this.translateService, this.dialogService)
    );
  }

  /**
   * handle delete timetables
   * @param checkedTimetables
   */
  private handleDeleteTimetables(checkedTimetables: Array<ScheduleRegistrationDetail>): void {
    this.scheduleRegistrationService.deleteTimetables(checkedTimetables.map(timetable => timetable.id)).subscribe(
      () => {
        this.timetables = this.timetables.filter(timetable => !timetable.isChecked);
        this.timetablesDisplay = this.timetablesDisplay.filter(timetable => !timetable.isChecked);
        this.dataService.sendData([Constant.IS_RECORDS_TIMETABLE_MAX, this.timetables.length >= Constant.MAX_RECORDS_TIMETABLE]);
        if (this.timetablesDisplay?.length) {
          if (this.timetableSelected?.isChecked) {
            this.selectTimetable(this.timetablesDisplay[Constant.FIRST_ELEMENT_INDEX], null);
          }
        } else {
          this.timetableSelected = undefined;
        }
        // change status nashi of device
        let timetableInfo = this.calendarFromDB.filter(contentDayShowing => contentDayShowing.unlimitedInfo)[0];
        if (timetableInfo && checkedTimetables.find(timetable => timetable.id == timetableInfo?.unlimitedInfo['scheduleRegistrationId'])) {
          this.isUnlimited = false;
        }
        // set data after delete timetable
        checkedTimetables.forEach(timetable => {
          this.colorBeingUsed?.delete(timetable.id);
          this.calendarsInTwoYear.forEach(contentDay => {
            if (contentDay?.scheduleRegistration?.id != timetable.id) {
              return;
            }
            contentDay.scheduleRegistration = undefined;
            contentDay.color = undefined;
            contentDay.scheduleRegistrationId = undefined;
          });
          this.calendarFromDB = this.calendarFromDB.filter(contentDay => contentDay.scheduleRegistrationId != timetable.id);
          if (!this.isUnlimited) {
            this.calendarFromDB.forEach(data => {
              data.unlimitedInfo = undefined;
            });
          }
        });
      },
      error => Helper.handleError(error, this.translateService, this.dialogService)
    );
  }

  /**
   * show icon arrow sort filter
   */
  private sortFilter(): void {
    if (this.timetableSelected?.isEdit) {
      return;
    }
    this.isSortFilter = !this.isSortFilter;
    this.sortFilterObject.isSortFilter = this.isSortFilter;
    this.saveSortFilterStateAction();
    if (!this.isSortFilter) {
      this.resetSortFilter();
    }
  }

  /**
   * reset sort filter action
   */
  private resetSortFilter(): void {
    this.getAllScheduleRegistration();
    this.listSorted = [];
    this.listCurrentFilter = {};
    this.lastColumnFilter = undefined;
    this.isFilter = undefined;
    this.columnSortFiltering = undefined;
    this.headerColumns = _.cloneDeep(this.headerColumnsOriginal);
    // store data sort filter
    this.sortFilterObject.isFilter = this.isFilter;
    this.sortFilterObject.listSorted = this.listSorted;
    this.sortFilterObject.listCurrentFilter = this.listCurrentFilter;
    this.sortFilterObject.columnSortFiltering = this.columnSortFiltering;
    this.sortFilterObject.headerColumns = this.headerColumns;
    this.saveSortFilterStateAction();
    this.multiLanguageHeader();
  }

  /**
   * multi language header
   */
  private multiLanguageHeader(): void {
    this.headerColumns.forEach((element, index) => {
      switch (index) {
        case 0:
          element.headerName = this.translateService.instant('schedule-registration.label');
          break;
        case 1:
          element.headerName = this.translateService.instant('schedule-registration.no');
          break;
        case 2:
          element.headerName = this.translateService.instant('schedule-registration.suffix');
          break;
        case 3:
          element.headerName = this.translateService.instant('schedule-registration.name');
          break;
        default:
          break;
      }
    });
  }

  /**
   * Save sort filter state action
   */
  private saveSortFilterStateAction(): void {
    // Đoạn này lưu lên store để giữ trạng thái sort khi chia mh -> để làm sau nhé.
    // this.store.dispatch(
    //   new SaveSortFilterStateAction({
    //     sortFilterSr: this.sortFilterObject
    //   })
    // );
  }

  /**
   * sort basic
   * @param property property sorted
   * @param type type sort
   */
  public sortProperty(property: string, type: string): void {
    this.listSorted = [[property], [type]];
    this.timetablesDisplay.sort(this.dynamicSortMultiple(this.listSorted));
    this.selectTimetable(this.timetablesDisplay[Constant.FIRST_ELEMENT_INDEX], null);
    this.isShowPopUpSortFilter = false;
    // store data sort filter
    this.sortFilterObject.listSorted = this.listSorted;
    // remove all sort of all column
    this.resetColumnsSort();
    // set columns is sorting
    let indexColumnSort = this.headerColumns.findIndex(data => data.property === property);
    this.headerColumns[indexColumnSort].isSortBy = type;
    this.headerColumns[indexColumnSort][Constant.IS_CHOSEN] = true;
    this.sortFilterObject.headerColumns = this.headerColumns;
    this.saveSortFilterStateAction();
  }

  /**
   * sort multiple
   * @param dataSort list properties and sort type sorted
   */
  public dynamicSortMultiple(dataSort: any): any {
    return function(object1, object2) {
      let output = 0,
        i = 0;
      while (output == 0 && i < dataSort[0]?.length) {
        let value1 = object1[dataSort[0][i]] ?? Constant.EMPTY; // dataSort[0] is list column sorted
        let value2 = object2[dataSort[0][i]] ?? Constant.EMPTY;
        if (dataSort[1][i] === SortTypeEnum.DESC) {
          // dataSort[1] is list sort type corresponding to column
          output = value1 > value2 ? -1 : value1 < value2 ? 1 : 0;
        } else {
          output = value1 > value2 ? 1 : value1 < value2 ? -1 : 0;
        }
        i++;
      }
      return output;
    };
  }

  /**
   * Filter timetable when open timetable editor first time
   *
   * @returns
   */
  private filterTimetableFirstTime(): void {
    if (!this.listCurrentFilter) {
      this.selectTimetable(this.timetables[Constant.FIRST_ELEMENT_INDEX], null);
      return;
    }
    let listCurrentFilter = _.cloneDeep(this.listCurrentFilter);
    let columnSortFilters = Object.keys(listCurrentFilter);
    columnSortFilters.forEach(columnSortFilter => {
      this.listFilterDisplay = _.cloneDeep(listCurrentFilter)[columnSortFilter];
      this.listFilterDisplayOrigin = this.listFilterDisplay;
      this.filterTimetable(columnSortFilter, true);
    });
  }

  /**
   * filter timetable
   *
   * @param property
   * @param isFilterFirstTime
   */
  public filterTimetable(property: string, isFilterFirstTime: boolean): void {
    // do not filter all
    if (this.listFilterDisplayOrigin.every(data => !data.isChecked)) {
      this.isShowPopUpSortFilter = false;
      this.saveSortFilterStateAction();
      return;
    }
    this.isFilter = true;
    this.sortFilterObject.isFilter = this.isFilter;
    this.headerColumns.find(data => data.property === property).isFilterBy = property;
    this.sortFilterObject.headerColumns = this.headerColumns;
    this.lastColumnFilter = property;
    let columnsFiltered = Object.keys(this.listCurrentFilter);
    // if is not clear last column filter
    if (!this.isClear) {
      this.listFilterDisplay = [...this.listFilterDisplayOrigin];
    }
    // if list option filter checked all or clear last column filter
    if (this.listFilterDisplay.findIndex(data => !data.isChecked) === -1) {
      delete this.listCurrentFilter[property];
      this.sortFilterObject.listCurrentFilter = this.listCurrentFilter;
      columnsFiltered = Object.keys(this.listCurrentFilter);
      this.lastColumnFilter = columnsFiltered[columnsFiltered.length - 1];
      this.isClear = false;
      this.isFilter = false;
      this.sortFilterObject.isFilter = this.isFilter;
      this.sortFilterObject.isClear = this.isClear;
      this.headerColumns.find(data => data.property === property).isFilterBy = Constant.EMPTY;
      this.sortFilterObject.headerColumns = this.headerColumns;
      // if filter a column was filtered
    } else {
      if (this.listCurrentFilter[property] && this.lastColumnFilter !== columnsFiltered[columnsFiltered.length - 1]) {
        let nextProperTyFilter = columnsFiltered[columnsFiltered.indexOf(property) + 1];
        this.timetables?.forEach(element => {
          if (element[this.LAST_FILTER] === property) {
            element[this.LAST_FILTER] = nextProperTyFilter;
          }
        });
        let listTimetableGetOptionFilter = this.timetables?.filter(
          data => data[this.LAST_FILTER] === nextProperTyFilter || !data[this.LAST_FILTER]
        );
        let listTimetableOptionFilter = this.getUniqueOption(listTimetableGetOptionFilter, nextProperTyFilter);
        let listOptionFilterNew: Array<OptionFilter> = new Array<OptionFilter>();
        for (let i = 0; i < listTimetableOptionFilter.length; i++) {
          listOptionFilterNew[i] = {
            isChecked: !listTimetableOptionFilter[i].lastFilter,
            name: listTimetableOptionFilter[i][nextProperTyFilter]
          };
        }
        this.listCurrentFilter[nextProperTyFilter] = listOptionFilterNew;
        delete this.listCurrentFilter[property];
        this.sortFilterObject.listCurrentFilter = this.listCurrentFilter;
      }
      // set list option filter property
      this.listCurrentFilter[property] = this.listFilterDisplay;
      this.sortFilterObject.listCurrentFilter = this.listCurrentFilter;
    }
    this.getCurrentFilter(this.listFilterDisplay, property);
    // get list Timetable show up on screen
    // this.timetables?.filter(data => data[this.LAST_FILTER]).map(data => (data[Constant.IS_SELECTED] = false));
    let listFilterTmp = _.cloneDeep(this.timetables);
    for (let filterTmp in this.listCurrentFilter) {
      let filter = this.listCurrentFilter[filterTmp].filter(e => e.isChecked == true)?.map(e => e.name);
      listFilterTmp = listFilterTmp.filter(e => filter.includes(e[filterTmp]));
    }
    this.timetablesDisplay = listFilterTmp;
    this.timetablesDisplay.sort(this.dynamicSortMultiple(this.listSorted));
    this.isShowPopUpSortFilter = false;
    this.saveSortFilterStateAction();
    this.controlCheckBoxCheckAllFilter();
    if (!isFilterFirstTime) {
      this.selectTimetable(this.timetablesDisplay[Constant.FIRST_ELEMENT_INDEX], null);
    }
  }

  /**
   * get array not duplicate value
   * @param array
   * @param property
   */
  public getUniqueOption = (array, property): any => {
    return _.uniqBy(array, property);
  };

  /**
   * set lastFilter for Timetable to filter or un filter
   * @param currentFilter list option filter property
   * @param property column filtering
   */
  public getCurrentFilter(currentFilter: OptionFilter[], property: string): void {
    for (let i = 0; i < currentFilter.length; i++) {
      if (!currentFilter[i].isChecked) {
        let arr = this.timetables?.filter(data => data[property] == currentFilter[i].name);
        arr.forEach(element => {
          element[this.IS_FILTER] = true;
          if (!element[this.LAST_FILTER]) {
            element[this.LAST_FILTER] = property;
          }
        });
      } else {
        let arr = this.timetables?.filter(data => data[property] == currentFilter[i].name);
        arr.forEach(element => {
          if (element[this.LAST_FILTER] == property) {
            element[this.IS_FILTER] = false;
            element[this.LAST_FILTER] = undefined;
          }
        });
      }
    }
  }

  /**
   * control checkBox check all filter when uncheck and checked
   */
  private controlCheckBoxCheckAllFilter(): void {
    this.isCheckAllOptionFilter = this.listFilterDisplayOrigin.every(filter => filter.isChecked);
    this.sortFilterObject.isCheckAllOptionFilter = this.isCheckAllOptionFilter;
  }

  /**
   * reset column sort disable in list
   */
  private resetColumnsSort(): void {
    this.headerColumns.forEach(column => {
      column[Constant.IS_CHOSEN] = false;
      column.isSortBy = Constant.EMPTY;
    });
    this.sortFilterObject.headerColumns = this.headerColumns;
    this.saveSortFilterStateAction();
  }

  /**
   * show custom sort dialog and sort
   */
  public showCustomSort(): void {
    this.isShowPopUpSortFilter = false;
    this.saveSortFilterStateAction();
    let propertySorts = _.cloneDeep(this.headerColumns);
    // show dialog custom sort
    this.dialogService.showDialog(DialogCustomSortComponent, { data: { list: [this.listSorted, propertySorts] } }, result => {
      if (result) {
        this.listSorted = result;
        for (let i = 0; i < this.headerColumns.length; i++) {
          let index = this.listSorted[0].findIndex(column => column === this.headerColumns[i]?.property);
          if (index === -1) {
            this.headerColumns[i].isSortBy = Constant.EMPTY;
          } else {
            this.headerColumns[i].isSortBy = this.listSorted[1][index];
          }
        }
        this.sortFilterObject.listSorted = this.listSorted;
        this.sortFilterObject.headerColumns = this.headerColumns;
        this.saveSortFilterStateAction();
        // sort
        this.timetablesDisplay = this.timetables?.filter(data => !data[this.LAST_FILTER]);
        this.timetablesDisplay.sort(this.dynamicSortMultiple(this.listSorted));
        this.selectTimetable(this.timetablesDisplay[Constant.FIRST_ELEMENT_INDEX], null);
        this.updateColumnCustomSort(this.headerColumns, propertySorts);
      }
    });
  }

  /**
   * set up for disable option in custom sort
   *
   * @param columnsBeforeSort
   * @param columnsAfterSort
   */
  private updateColumnCustomSort(columnsBeforeSort: any, columnsAfterSort: any): void {
    columnsAfterSort?.forEach((columnAfter, index) => {
      columnsBeforeSort[index][Constant.IS_CHOSEN] = columnAfter[Constant.IS_CHOSEN];
    });
  }

  /**
   * clear filter
   * @param property name of column clear filter
   */
  public clearFilter(property: string): void {
    this.headerColumns.find(data => data.property == property).isFilterBy = Constant.EMPTY;
    this.sortFilterObject.headerColumns = this.headerColumns;
    this.isFilter = false;
    this.sortFilterObject.isFilter = this.isFilter;
    // set all option in list is true
    this.listCurrentFilter[property]?.forEach(element => {
      element.isChecked = true;
    });
    this.sortFilterObject.listCurrentFilter = this.listCurrentFilter;
    // if is last column filter
    if (property === this.lastColumnFilter) {
      this.isClear = true;
      this.sortFilterObject.isClear = this.isClear;
      this.listFilterDisplayOrigin.forEach(data => (data.isChecked = true));
      this.filterTimetable(property, false);
      // if is not last column filter
    } else {
      let keys = Object.keys(this.listCurrentFilter);
      let nextProperTyFilter = keys[keys.indexOf(property) + 1];
      // set lastFilter is next column filter
      this.timetables?.forEach(element => {
        if (element[this.LAST_FILTER] === property) {
          element[this.IS_FILTER] = false;
          element[this.LAST_FILTER] = nextProperTyFilter;
        }
      });
      // get new list option filter for next property filter in listCurrentFilter
      let listTimetableGetOptionFilter = this.timetables?.filter(
        data => data[this.LAST_FILTER] === nextProperTyFilter || !data[this.LAST_FILTER]
      );
      let listTimetableOptionFilter = this.getUniqueOption(listTimetableGetOptionFilter, nextProperTyFilter);
      let listOptionFilterNew: Array<OptionFilter> = new Array<OptionFilter>();
      for (let i = 0; i < listTimetableOptionFilter.length; i++) {
        listOptionFilterNew[i] = {
          isChecked: !listTimetableOptionFilter[i].lastFilter,
          name: listTimetableOptionFilter[i][nextProperTyFilter]
        };
      }
      listOptionFilterNew.forEach(element => {
        for (let j = 0; j < this.listCurrentFilter[nextProperTyFilter]?.length; j++) {
          if (element.name === this.listCurrentFilter[nextProperTyFilter][j].name) {
            element.isChecked = this.listCurrentFilter[nextProperTyFilter][j].isChecked;
          }
        }
      });
      // set new list option filter for next property filter in listCurrentFilter
      this.listCurrentFilter[nextProperTyFilter] = listOptionFilterNew;
      this.isShowPopUpSortFilter = false;
      delete this.listCurrentFilter[property];
      this.sortFilterObject.listCurrentFilter = this.listCurrentFilter;
      this.saveSortFilterStateAction();
      this.controlCheckBoxCheckAllFilter();
      this.selectTimetable(this.timetablesDisplay[Constant.FIRST_ELEMENT_INDEX], null);
    }
  }

  /**
   * check select all option
   */
  public checkAllOptionFilter(): void {
    this.isCheckAllOptionFilter = !this.isCheckAllOptionFilter;
    this.sortFilterObject.isCheckAllOptionFilter = this.isCheckAllOptionFilter;
    this.listFilterDisplayOrigin.forEach(option => {
      option.isChecked = this.isCheckAllOptionFilter;
    });
    this.listFilterDisplay = [...this.listFilterDisplayOrigin];
    this.saveSortFilterStateAction();
  }

  /**
   * change checked
   * @param index index of option filter
   */
  public checkOptionFilter(index: number): void {
    this.listFilterDisplayOrigin[index].isChecked = !this.listFilterDisplayOrigin[index].isChecked;
    this.controlCheckBoxCheckAllFilter();
  }

  /**
   * show pop up sort filter
   * @param column name column
   * @param event
   */
  public showPopupSortFilter(column: string, event): void {
    if (this.timetableSelected?.isEdit) {
      return;
    }
    event.stopPropagation();
    this.isShowPopUpSortFilter = !this.isShowPopUpSortFilter;
    // if is show
    if (this.isShowPopUpSortFilter) {
      this.columnSortFiltering = column;
      this.sortFilterObject.columnSortFiltering = this.columnSortFiltering;
      this.fetchFilterData(column);
    }
    this.saveSortFilterStateAction();
  }

  /**
   * fetch data filter to pop up
   * @param property column show popup
   */
  public fetchFilterData(property: string): void {
    // let listDataTableGetOptionFilter = this.dataTablesDisplay.filter(
    //   data => !data[this.LAST_FILTER] || data[this.LAST_FILTER] === property
    // );
    let isFiltered = false;
    let listFilterTmp = _.cloneDeep(this.timetables);
    let listFilterInProperty = [];
    for (let filterTmp in this.listCurrentFilter) {
      if (filterTmp == property) {
        isFiltered = true;
        listFilterInProperty = this.listCurrentFilter[filterTmp].filter(e => e.isChecked == true)?.map(e => e.name);
        continue;
      }

      let filter = this.listCurrentFilter[filterTmp].filter(e => e.isChecked == true)?.map(e => e.name);
      // filter = filter.map(e=> e.name);
      listFilterTmp = listFilterTmp.filter(e => filter.includes(e[filterTmp]));
    }
    let listDataTableGetOptionFilter = _.cloneDeep(listFilterTmp);
    let listDataTableOptionFilter: any[] = this.getUniqueOption(listDataTableGetOptionFilter, property);
    // if not last column filter
    if (this.lastColumnFilter !== property) {
      this.listFilterDisplay = [];
      for (let i = 0; i < listDataTableOptionFilter.length; i++) {
        //get list option filter
        this.listFilterDisplay[i] = {
          isChecked: listFilterInProperty.length == 0 ? true : listFilterInProperty.includes(listDataTableOptionFilter[i][property]),
          // isChecked: !listDataTableOptionFilter[i][this.IS_FILTER],
          name: listDataTableOptionFilter[i][property]
        };
      }
      // if is last column filter
    } else {
      this.listFilterDisplay = this.listCurrentFilter[property];
      // update if add
      listDataTableOptionFilter.forEach(dataTable => {
        if (!this.listFilterDisplay.find(optionFilter => optionFilter.name === dataTable[property])) {
          this.listFilterDisplay.push({
            isChecked: !isFiltered,
            name: dataTable[property]
          });
        }
      });
      // remove old value
      this.listFilterDisplay?.forEach(option => {
        if (option.isChecked && !listDataTableOptionFilter.find(dataTable => dataTable[property] === option.name)) {
          this.listFilterDisplay = this.listFilterDisplay.filter(data => data.name !== option.name);
        }
      });
    }
    this.listFilterDisplay = _.sortBy(this.listFilterDisplay, ['name']);
    // get list memorize checked
    this.listFilterDisplayOrigin = _.cloneDeep(this.listFilterDisplay);
    this.controlCheckBoxCheckAllFilter();
  }
  /**
   * Get all sort filter conditions
   */
  private getAllSortFilterConditions(): void {
    // get data from store
    this.isFilter = this.sortFilterObject?.isFilter;
    this.isSortFilter = this.sortFilterObject?.isSortFilter;
    this.isCheckAllOptionFilter = this.sortFilterObject?.isCheckAllOptionFilter;
    this.columnSortFiltering = this.sortFilterObject?.columnSortFiltering;
    this.listCurrentFilter = this.sortFilterObject?.listCurrentFilter;
    this.isClear = this.sortFilterObject?.isClear;
    this.listSorted = this.sortFilterObject?.listSorted;
    if (this.isSortFilter && (!_.isEmpty(this.listCurrentFilter) || this.listSorted[Constant.SORT_COLUMN_INDEX])) {
      this.headerColumns = this.sortFilterObject?.headerColumns;
    } else {
      this.headerColumns = _.cloneDeep(this.headerColumnsOriginal);
    }
    this.multiLanguageHeader();
  }

  /**
   * show dialog label manager
   */
  public showDialogManagerLabel(): void {
    if (this.timetableSelected?.isEdit) {
      return;
    }
    this.dialogService.showDialog(
      DialogRouteLabelManagerComponent,
      {
        autoFocus: false,
        data: {
          title: this.translateService.instant('dialog-route-label-manager.label-setting'),
          screen: ScreenNameEnum.SCHEDULE_REGISTRATION,
          functionId: ScreenFunctionId.SCHEDULE_REGISTRATION
        }
      },
      result => {
        if (result) {
          // set label for timetables original
          this.setLabelForTimetable(this.timetables, result);
          // set label for timetable display
          this.setLabelForTimetable(this.timetablesDisplay, result);
        }
      }
    );
  }

  /**
   * Set label for timetable
   *
   * @param timetables
   * @param result
   */
  private setLabelForTimetable(timetables: ScheduleRegistrationDetail[], result: any): void {
    timetables.forEach(timetable => {
      timetable.isChecked = false;
      let index = [...result].findIndex(data => data.id == timetable?.label?.id);
      if (index != -1) {
        timetable.label = result[index];
      }
    });
  }

  /**
   * show dialog change label
   */
  public changeLabelTimeTable(): void {
    let checkedTimetables = this.timetables.filter(timetable => timetable.isChecked);
    if (!checkedTimetables?.length) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('timetable-editor.choose-timetable')
        }
      });
      return;
    }
    if (this.timetableSelected?.isEdit) {
      return;
    }
    let timetableCheckedFirst = checkedTimetables[0];
    let labelCommon =
      checkedTimetables.length == 1
        ? timetableCheckedFirst.label
        : checkedTimetables.every(timetable => timetable?.label?.id == timetableCheckedFirst?.label?.id)
        ? timetableCheckedFirst.label
        : null;
    this.dialogService.showDialog(
      DialogChangeLabelComponent,
      {
        data: {
          labelCommon: labelCommon,
          title: this.translateService.instant('dialog-change-label.select-label'),
          screen: ScreenNameEnum.SCHEDULE_REGISTRATION,
          functionId: ScreenFunctionId.SCHEDULE_REGISTRATION
        }
      },
      result => {
        if (result != '') {
          checkedTimetables.forEach(timetable => (timetable.isChecked = false));
          let timetablesSaved = checkedTimetables.map(timetableData => {
            timetableData.label = result;
            return Helper.convertDataTimetableScheduleRegistrationBackward(timetableData);
          });
          this.scheduleRegistrationService.saveLabelForTimetables(timetablesSaved).subscribe(
            data => {
              let timetablesOutput = data.map(timetableData => Helper.convertDataTimetable(timetableData));
              this.timetables.forEach(timetableData => {
                let index = timetablesOutput.findIndex(timetable => timetable.id == timetableData.id);
                if (index != -1) {
                  timetableData.label = timetablesOutput[index].label;
                  timetableData['nameLabel'] = timetablesOutput[index].label?.name;
                }
              });
              this.timetablesDisplay.forEach(timetableData => {
                let index = timetablesOutput.findIndex(timetable => timetable.id == timetableData.id);
                if (index != -1) {
                  timetableData.label = timetablesOutput[index].label;
                  timetableData['nameLabel'] = timetablesOutput[index].label?.name;
                  timetableData.isChecked = false;
                }
              });
              this.selectTimetable(this.timetableSelected, null);
            },
            error => Helper.handleError(error, this.translateService, this.dialogService)
          );
        }
      }
    );
  }

  /**
   * setting channel area preview
   */
  public signageChannelAreaPreview(): void {
    if (this.timetableSelected?.isEdit) {
      return;
    }
    // get setting signage channel by project's id
    this.settingSignageChannelService.getSettingSignageChannelByType(SettingType.SCHEDULE_REGISTRATION).subscribe(
      settingSignageChannel => {
        this.dialogService.showDialog(
          DialogSettingSignageDisplayComponent,
          {
            data: {
              settingSignageChannel: <SettingSignageChannel>settingSignageChannel ?? new SettingSignageChannel(),
              type: SettingType.SCHEDULE_REGISTRATION
            }
          },
          result => {
            if (result) {
              let data = result;
              if (!data || (!data.mediaId && !data.folderId)) {
                this.mediaSetting = undefined;
                this.drawScheduleService.clearCanvasAreas(this.templateSelected);
                return;
              }
              // get media by id
              this.simpleMediaService.getMediaById(data.mediaId).subscribe(
                async mediaData => {
                  if (!mediaData) {
                    this.mediaSetting = undefined;
                    return;
                  }
                  this.mediaSetting = Helper.convertSimpleMediaToMedia(Helper.convertDataSimpleMedia(mediaData, false));
                  this.drawScheduleService.setupPreview(this.timetableSelected, this.mediaSetting ?? undefined);
                  // draw area is set signage channel
                  this.drawScheduleService.drawAreasSignageChannel(this.templateSelected, this.renderer);
                },
                error => Helper.handleError(error, this.translateService, this.dialogService)
              );
            } else {
              this.mediaSetting = undefined;
              this.drawScheduleService.clearCanvasAreas(this.templateSelected);
            }
          }
        );
      },
      error => Helper.handleError(error, this.translateService, this.dialogService)
    );
  }

  /**
   * draw areas index word for display
   * @param display
   * @param areasIndexWordDisplay
   * @param canvasDisplayId
   * @param indexWords
   */
  private drawAreasIndexWordForDisplay(display: Template, areasIndexWordDisplay: Area[], indexWords: IndexWord[]): void {
    Helper.setDataIndexWordForAreas(areasIndexWordDisplay, indexWords);
    this.drawScheduleService.setDataPreviewTimetableEditor(null, null, null, areasIndexWordDisplay);
    let areasIndexWordLayerOff = Helper.getAreasOfLayerOnOff(areasIndexWordDisplay, display, this.areaSwitchingTiming);

    this.drawScheduleService.drawAreasIndexWord(areasIndexWordLayerOff, this.timetablesDisplaySecond, display);
    let areasIndexWordLayerOn = Helper.getAreasOfLayerOnOff(areasIndexWordDisplay, display, this.areaSwitchingTiming, true);
    this.drawScheduleService.drawAreasIndexWordLayerOn(areasIndexWordLayerOn, this.timetablesDisplaySecond, display);
  }

  /**
   * draw areas timetable for display
   * @param display
   * @param index
   * @param areasTimetableDisplay
   * @param canvasDisplayId
   */
  private drawAreasTimetableForDisplay(display: Template, areasTimetableDisplay: TextArea[]): void {
    let areasTimetableLayerOff = Helper.getAreasOfLayerOnOff(areasTimetableDisplay, display, this.areaSwitchingTiming);
    this.drawScheduleService.drawAreasTimetable(
      areasTimetableLayerOff as TextArea[],
      this.renderer,
      this.timetablesDisplaySecond,
      this.currentIndexSchedule,
      this.referencePositionColumnsByTemplate
    );
    let areasTimetableLayerOn = Helper.getAreasOfLayerOnOff(areasTimetableDisplay, display, this.areaSwitchingTiming, true);
    this.drawScheduleService.drawAreasTimetableLayerOn(
      areasTimetableLayerOn as TextArea[],
      this.renderer,
      this.timetablesDisplaySecond,
      this.currentIndexSchedule,
      this.referencePositionColumnsByTemplate
    );
  }

  /**
   * get information change date line
   */
  private getInformationChangeDateLine(): void {
    this.commonTableService.getValueCommonTableByKey(Constant.KEY_TIMETABLE_CHANGE_DATE_LINE_TIMETABLE).subscribe(
      data => {
        this.timeDateLine = data ? JSON.parse(data.value) : Constant.TIME_DATE_LINE_DEFAULT;
        this.getAllScheduleRegistration();
      },
      error => Helper.handleError(error, this.translateService, this.dialogService)
    );
  }

  /**
   * check valid time of list contents day
   * @param contentDays
   * @returns true if valid
   */
  private checkValidTimeNextDay(contentDays: ContentDay[]): boolean {
    if (!contentDays?.length) {
      return true;
    }
    for (let i = 0; i < contentDays.length; i++) {
      if (i === contentDays.length - 1) {
        return true;
      }
      if (contentDays[i].scheduleRegistration?.schedules?.filter(schedule => schedule.columnData[0]) == null) {
        continue;
      }
      if (!this.checkNextDay(contentDays[i].fullDate, contentDays[i + 1].fullDate)) {
        continue;
      }
      let minTimeNextDay = this.getTimeSchedule(contentDays[i + 1].scheduleRegistration?.id, true);
      let maxTimeCurrentDay = this.getTimeSchedule(contentDays[i].scheduleRegistration?.id, false);
      if (minTimeNextDay == null || maxTimeCurrentDay == null) {
        continue;
      }
      if (maxTimeCurrentDay > Constant.SECONDS_OF_ONE_DAY + minTimeNextDay) {
        return false;
      }
    }
  }

  /**
   * check next day is tomorrow of current day
   * @param dayFirst
   * @param daySecond
   * @returns true if daySecond is tomorrow of dayFirst
   */
  private checkNextDay(dayFirst: Date, daySecond: Date): boolean {
    dayFirst.setDate(dayFirst.getDate() + 1);
    return (
      dayFirst.getFullYear() === daySecond.getFullYear() &&
      dayFirst.getDate() === daySecond.getDate() &&
      dayFirst.getMonth() === daySecond.getMonth()
    );
  }

  /**
   * get time by minutes
   * @param timetableId
   * @param isGetMin true if get min, else get max
   * @returns time by minutes
   */
  private getTimeSchedule(timetableId: Number, isGetMin): any {
    let timetable = this.timetables.find(timetable => timetable.id === timetableId);
    if (!timetable?.schedules?.length) {
      return null;
    }
    let times = Helper.convertTimesToSeconds(timetable?.schedules.map(detail => detail.columnData[0]));
    if (isGetMin) {
      return Math.min(...times);
    }
    return Math.max(...times);
  }

  /**
   * getAreaSwitching
   */
  private async getAreaSwitching(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.scheduleRegistrationService.getAreaSwitchingTiming().subscribe(
        data => {
          this.areaSwitchingTiming = data;
          this.drawScheduleService.setAreaSwitchingTiming(this.areaSwitchingTiming);
          resolve();
        },
        error => reject()
      );
    });
  }

  /**
   * check Invalid time
   * @param contentDays
   * @returns
   */
  public checkInvalidTime(contentDays: ContentDay[]): boolean {
    if (!contentDays?.length) {
      return;
    }
    let listIdInContentDay = [
      ...new Set(
        contentDays?.filter(contentD => contentD.fullDate >= this.currentDate)?.map(contentDay => contentDay.scheduleRegistration?.id)
      )
    ];
    let timetableFilter = this.timetables?.filter(timetable => listIdInContentDay.includes(timetable.id));
    if (!timetableFilter?.length) {
      return;
    }
    for (let i = 0; i < timetableFilter.length; i++) {
      if (timetableFilter[i].schedules?.some(schedule => schedule.inValidRow)) {
        return true;
      }
    }
  }
}

export enum Tab_Enum {
  TIMETABLE,
  CALENDAR
}
