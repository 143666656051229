import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild
} from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Helper } from 'app/common/helper';
import {
  Constant,
  DestinationEnum,
  DisplaysEnum,
  FIELD_COMPONENT,
  LinkDataPictureEnum,
  LinkDataTextEnum,
  MODULE_NAME,
  ScreenNameEnum,
  SettingType,
  TEMPLATE_TYPE
} from 'app/config/constants';
import { DialogChangeTemplateComponent } from 'app/dialog/dialog-change-template/dialog-change-template.component';
import { DialogConfirmComponent } from 'app/dialog/dialog-confirm/dialog-confirm.component';
import { DialogMessageComponent } from 'app/dialog/dialog-message/dialog-message.component';
import { DialogPublishDataSignageDisplayComponent } from 'app/dialog/dialog-publish-data-signage-display/dialog-publish-data-signage-display.component';
import { DialogSettingSignageDisplayComponent } from 'app/dialog/dialog-setting-signage-display/dialog-setting-signage-display.component';
import { Area } from 'app/model/entity/area';
import { Common } from 'app/model/entity/common';
import { DataExternalSetting } from 'app/model/entity/data-external-setting';
import { DisplayTemplate } from 'app/model/entity/display-template';
import { Image } from 'app/model/entity/image';
import { Media } from 'app/model/entity/media';
import { PictureArea } from 'app/model/entity/picture-area';
import { SettingSignageChannel } from 'app/model/entity/simple/setting-signage-channel';
import { Style } from 'app/model/entity/style';
import { StyleDetail } from 'app/model/entity/style-detail';
import { Template } from 'app/model/entity/template';
import { TextArea } from 'app/model/entity/text-area';
import { SaveMainStateAction, SaveSignageDisplayEditorStateAction } from 'app/ngrx-component-state-management/component-state.action';
import { CommonService } from 'app/service/common.service';
import { DataService } from 'app/service/data.service';
import { DialogService } from 'app/service/dialog.service';
import { DrawService } from 'app/service/draw.service';
import { ExecutingService } from 'app/service/executing.service';
import { MediaService } from 'app/service/media.service';
import { PictureAreaService } from 'app/service/picture-area-service';
import { SettingSignageChannelService } from 'app/service/setting-signage-channel.service';
import { DrawSignageService } from 'app/service/signage-draw.service';
import { SimpleMediaService } from 'app/service/simple/simple-media.service';
import { StyleDetailService } from 'app/service/style-detail.service';
import { StyleServive } from 'app/service/style.service';
import { TemplateService } from 'app/service/template.service';
import { AppState } from 'app/store/app.state';
import _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { interval, Subject, Subscription } from 'rxjs';
import { repeatWhen, takeUntil, timeInterval } from 'rxjs/operators';
import { MenuActionService } from '../../service/menu-action.service';
@Component({
  selector: 'signage-display-editor',
  templateUrl: './signage-display-editor.component.html',
  styleUrls: ['./signage-display-editor.component.scss']
})
export class SignageDisplayEditorComponent implements OnInit, OnDestroy {
  /**
   * PATH_ANGLE_DOUBLE_RIGHT
   */
  PATH_ANGLE_DOUBLE_RIGHT = Constant.PATH_ANGLE_DOUBLE_RIGHT;
  /**
   * TEMPLATE_TYPE
   */
  TEMPLATE_TYPE = TEMPLATE_TYPE;
  /**
   * DestinationEnum
   */
  DestinationEnum = DestinationEnum;
  /**
   * DisplaysEnum
   */
  DisplaysEnum = DisplaysEnum;
  Helper = Helper;
  /**
   * ElementRef
   */
  @ViewChild('styleNo') styleNoElementRef: ElementRef;
  @ViewChild('suffix') suffixElementRef: ElementRef;
  @ViewChild('styleName') styleNameElementRef: ElementRef;

  /**
   * array contain timeout for display 1
   */
  timeoutsDisplay1: any[] = [];
  /**
   * array contain timeout for display 2
   */
  timeoutsDisplay2: any[] = [];
  /**
   * save data success
   */
  @Output() saveDataSuccess: EventEmitter<boolean> = new EventEmitter<boolean>();

  /**
   * true if enlarge preview
   */
  isEnlargeScreen: boolean;
  /**
   * true if select display 2
   */
  isDisplay2: boolean;
  /**
   * list of action subscriptions
   */
  subscriptions: Array<Subscription> = new Array<Subscription>();
  /**
   * style is selected
   */
  styleSelected: Style;
  /**
   * true if check all style
   */
  isCheckedAll: boolean;
  /**
   * style list
   */
  styles: Array<Style>;
  /**
   * list area display 1 (free picture/free text)
   */
  areasDisplay1: Array<Area>;
  /**
   * list area display 2 (free picture/free text)
   */
  areasDisplay2: Array<Area>;
  /**
   * constants
   */
  readonly AREA_DISPLAY_1 = 'areaDisplay1';
  readonly AREA_DISPLAY_2 = 'areaDisplay2';
  readonly DIV_DISPLAY_1 = 'sinage-li-display-1';
  readonly DIV_DISPLAY_2 = 'sinage-li-display-2';
  readonly IS_PREVIEW_ON = 'isPreviewOn';
  readonly IS_EDITING_STYLE = 'isEditingStyle';

  /**
   * style name is editting
   */
  styleNameEdit: string;
  /**
   * style no is editting
   */
  styleNoEdit: string;
  /**
   * suffix is editting
   */
  suffixEdit: string;
  /**
   * project id
   */
  projectId: Number;
  /**
   * true if show text column
   */
  isShowTextColumn: boolean;
  /**
   * style detail is selected
   */
  styleDetailSelected: StyleDetail;
  /**
   * true if disabled button add
   */
  isDisabledButtonAdd: boolean;
  /**
   * true if change data
   */
  isChangedData: boolean;
  /**
   * true if style selected
   */
  isShowDetail: boolean;
  /**
   * canvasContainerDisplay1
   */
  @ViewChild('canvasSignageDisplay1', { static: false })
  canvasDisplay1: ElementRef;
  /**
   * canvasContainerDisplay2
   */
  @ViewChild('canvasSignageDisplay2', { static: false })
  canvasDisplay2: ElementRef;
  /**
   * type of template display 1 on screen
   */
  templateSelectedTypeDisplay1: DestinationEnum = DestinationEnum.MAIN;
  /**
   * type of template display 2 on screen
   */
  templateSelectedTypeDisplay2: DestinationEnum = DestinationEnum.MAIN;
  /**
   * true if preview template
   */
  isPlay: boolean;
  /**
   * buttons preview display 1
   */
  buttonsPreviewDisplay1: Array<{ key: DestinationEnum; value: string }>;
  /**
   * buttons preview display 2
   */
  buttonsPreviewDisplay2: Array<{ key: DestinationEnum; value: string }>;
  /**
   * true if show vertical
   */
  isShowVertical: boolean = true;
  /**
   * media is set (setting signage channel)
   */
  mediaSetting: Media;
  /**
   * key code map
   */
  keyCodeMap = {
    17: false, // Ctrl
    18: false, // Alt
    83: false // S
  };

  /**
   * list interval draw news picture display 1
   */
  intervalsDrawNewsDisplay1: Array<any> = new Array<any>();

  /**
   * list interval draw news picture display 2
   */
  intervalsDrawNewsDisplay2: Array<any> = new Array<any>();

  /**
   * list external content of style
   */
  externalContentOfStyle: Array<any>;
  /**
   * subscribe for get url media presigned for display 1
   */
  subscribesGetUrlPresignedDisplay1: Subscription[] = [];
  /**
   * subscribe for get url media presigned for display 2
   */
  subscribesGetUrlPresignedDisplay2: Subscription[] = [];
  /**
   * subscribe for get data external content to preview
   */
  subscribesGetDataExternal: Subscription[] = [];
  /**
   * controller request
   */
  controllerDisplay1: AbortController = new AbortController();
  /**
   * controller request
   */
  controllerDisplay2: AbortController = new AbortController();
  private readonly pausePreviewSubject = new Subject<void>();
  private readonly startPreviewSubject = new Subject<void>();

  /**
   * stateOfComponent
   */
  stateOfComponent: {
    isChangeLayout: boolean;
    isEnlargeScreen: boolean;
    isCheckedAll: boolean;
    isChangedData: boolean;
    isDisplay2: boolean;
    styleSelected: Style;
    styleDetailSelected: StyleDetail;
    styles: Array<Style>;
    styleDetails: Array<StyleDetail>;
    areasDisplay1: Array<Area>;
    areasDisplay2: Array<Area>;
    styleNameEdit: string;
    styleNoEdit: string;
    suffixEdit: string;
    isShowTextColumn: boolean;
    isShowDetail: boolean;
    isPlay: boolean;
    buttonsPreviewDisplay1: Array<any>;
    buttonsPreviewDisplay2: Array<any>;
    isShowVertical: boolean;
    mediaSetting: Media;
  };
  /**
   * common object
   */
  commonObject: Common;
  constructor(
    private actionService: MenuActionService,
    private dialogService: DialogService,
    private styleService: StyleServive,
    private styleDetailService: StyleDetailService,
    private toast: ToastrService,
    public readonly store: Store<AppState>,
    private changeDetectorRef: ChangeDetectorRef,
    private renderer: Renderer2,
    private dataService: DataService,
    private drawSignageService: DrawSignageService,
    private settingSignageChannelService: SettingSignageChannelService,
    private mediaService: MediaService,
    private simpleMediaService: SimpleMediaService,
    private pictureAreaService: PictureAreaService,
    private drawService: DrawService,
    private templateService: TemplateService,
    private commonService: CommonService,
    public translateService: TranslateService,
    private executingService: ExecutingService
  ) {
    // save
    this.subscriptions.push(
      this.actionService.actionSave.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.SignageDisplayEditorComponent]) {
          if (this.styleSelected?.isEdit) {
            this.saveStyle();
          } else {
            this.saveStyleDetailsForStyle(true);
          }
        }
      })
    );
    // add new style
    this.subscriptions.push(
      this.actionService.actionAdd.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.SignageDisplayEditorComponent]) {
          this.addNewStyle();
        }
      })
    );
    // edit style
    this.subscriptions.push(
      this.actionService.actionEdit.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.SignageDisplayEditorComponent]) {
          this.editStyle();
        }
      })
    );
    // duplicate style
    this.subscriptions.push(
      this.actionService.actionDuplicate.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.SignageDisplayEditorComponent]) {
          this.duplicateStyle();
        }
      })
    );
    // change template
    this.subscriptions.push(
      this.actionService.actionChangeTemplate.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.SignageDisplayEditorComponent]) {
          this.changeTemplate();
        }
      })
    );
    // delete
    this.subscriptions.push(
      this.actionService.actionDelete.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.SignageDisplayEditorComponent]) {
          if (this.styleDetailSelected) {
            this.deleteStyleDetail();
          } else {
            this.deleteStyle();
          }
        }
      })
    );
    // change display
    this.subscriptions.push(
      this.actionService.actionChangeDisplay.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.SignageDisplayEditorComponent]) {
          this.changeDisplay();
        }
      })
    );
    // setting
    this.subscriptions.push(
      this.actionService.actionSetting.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.SignageDisplayEditorComponent]) {
          this.setting();
        }
      })
    );
    // publish data
    this.subscriptions.push(
      this.actionService.actionPublishData.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.SignageDisplayEditorComponent]) {
          this.publishData();
        }
      })
    );
    // on-click go to
    this.subscriptions.push(
      this.drawSignageService.toSwitchBetweenPage.subscribe((destinationDisplay: { key: string; value: DestinationEnum }) => {
        if (!this.isPlay) {
          return;
        }
        if (destinationDisplay.key == Constant.CANVAS_DISPLAY_1_ID && this.styleSelected?.display1Templates) {
          if (this.styleSelected.display1Templates[this.templateSelectedTypeDisplay1]?.isAutoTransition) {
            this.clearTimeoutDisplay(this.timeoutsDisplay1);
          }
          this.handleEventClickAreaOnCanvas(
            this.canvasDisplay1,
            Constant.CANVAS_DISPLAY_1_ID,
            this.styleSelected.display1Templates,
            destinationDisplay.value
          );
        } else if (destinationDisplay.key == Constant.CANVAS_DISPLAY_2_ID && this.styleSelected?.display2Templates) {
          if (this.styleSelected.display2Templates[this.templateSelectedTypeDisplay2]?.isAutoTransition) {
            this.clearTimeoutDisplay(this.timeoutsDisplay2);
          }
          this.handleEventClickAreaOnCanvas(
            this.canvasDisplay2,
            Constant.CANVAS_DISPLAY_2_ID,
            this.styleSelected.display2Templates,
            destinationDisplay.value
          );
        }
      })
    );

    this.subscriptions.push(
      this.store
        .select(state => state)
        .subscribe((componentState: any) => {
          this.stateOfComponent = {
            isChangeLayout: componentState?.signageDisplayEditorState?.stateOfComponent.isChangeLayout,
            isEnlargeScreen: componentState?.signageDisplayEditorState?.stateOfComponent.isEnlargeScreen,
            isCheckedAll: componentState?.signageDisplayEditorState?.stateOfComponent.isCheckedAll,
            isChangedData: componentState?.signageDisplayEditorState?.stateOfComponent.isChangedData,
            isDisplay2: componentState?.signageDisplayEditorState?.stateOfComponent.isDisplay2,
            styleSelected: componentState?.signageDisplayEditorState?.stateOfComponent.styleSelected,
            styleDetailSelected: componentState?.signageDisplayEditorState?.stateOfComponent.styleDetailSelected,
            styles: componentState?.signageDisplayEditorState?.stateOfComponent.styles,
            styleDetails: componentState?.signageDisplayEditorState?.stateOfComponent.styleDetails,
            areasDisplay1: componentState?.signageDisplayEditorState?.stateOfComponent.areasDisplay1,
            areasDisplay2: componentState?.signageDisplayEditorState?.stateOfComponent.areasDisplay2,
            styleNameEdit: componentState?.signageDisplayEditorState?.stateOfComponent.styleNameEdit,
            styleNoEdit: componentState?.signageDisplayEditorState?.stateOfComponent.styleNoEdit,
            suffixEdit: componentState?.signageDisplayEditorState?.stateOfComponent.suffixEdit,
            isShowTextColumn: componentState?.signageDisplayEditorState?.stateOfComponent.isShowTextColumn,
            isShowDetail: componentState?.signageDisplayEditorState?.stateOfComponent.isShowDetail,
            isPlay: componentState?.signageDisplayEditorState?.stateOfComponent.isPlay,
            buttonsPreviewDisplay1: componentState?.signageDisplayEditorState?.stateOfComponent.buttonsPreviewDisplay1,
            buttonsPreviewDisplay2: componentState?.signageDisplayEditorState?.stateOfComponent.buttonsPreviewDisplay2,
            isShowVertical: componentState?.signageDisplayEditorState?.stateOfComponent.isShowVertical,
            mediaSetting: componentState?.signageDisplayEditorState?.stateOfComponent.mediaSetting
          };
        })
    );
    this.commonObject = commonService.getCommonObject();
  }

  async ngOnInit() {
    this.isDisplay2 = this.commonObject.isDisplay2Signage;
    this.projectId = this.commonObject.projectId;
    if (!this.stateOfComponent?.isChangeLayout) {
      this.executingService.executing();
      await Helper.loadFontsToPreview(this.store, this.commonObject, this.translateService, this.dialogService);
      this.executingService.executed();
      // get all styles
      this.getAllStyles(this.projectId);
      // get setting signage channel by project id
      this.settingSignageChannelService.getSettingSignageChannelByType(SettingType.SIGNAGE).subscribe(
        settingSignageChannel => {
          if (!settingSignageChannel || (!settingSignageChannel.folderId && !settingSignageChannel.mediaId)) {
            return;
          }
          // get media by id
          this.simpleMediaService.getMediaById(settingSignageChannel.mediaId).subscribe(
            async data => {
              if (!data) {
                this.mediaSetting = undefined;
                return;
              }
              this.mediaSetting = Helper.convertSimpleMediaToMedia(Helper.convertDataSimpleMedia(data, false));
            },
            () => {
              this.dialogService.showDialog(DialogMessageComponent, {
                data: {
                  title: `Error`,
                  text: `An error has occurred. Please try again.`
                }
              });
            }
          );
        },
        error => {
          this.dialogService.showDialog(DialogMessageComponent, {
            data: {
              title: `Error`,
              text: `An error has occurred. Please try again.`
            }
          });
        }
      );
    } else {
      this.handleAfterChangeLayout();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    // clear timeout
    this.clearTimeoutDisplay(this.timeoutsDisplay1);
    this.clearTimeoutDisplay(this.timeoutsDisplay2);
    this.drawSignageService.changeStatePlayPause(false, Constant.CANVAS_DISPLAY_1_ID);
    this.drawSignageService.changeStatePlayPause(false, Constant.CANVAS_DISPLAY_2_ID);
    // clear all thread
    this.controllerDisplay1.abort();
    this.controllerDisplay2.abort();
    this.clearAllIntervalDrawsNews(this.intervalsDrawNewsDisplay1);
    this.clearAllIntervalDrawsNews(this.intervalsDrawNewsDisplay2);
    this.subscribesGetUrlPresignedDisplay1.forEach(subscription => subscription?.unsubscribe());
    this.subscribesGetUrlPresignedDisplay2.forEach(subscription => subscription?.unsubscribe());
    this.subscribesGetDataExternal.forEach(subscription => subscription?.unsubscribe());
    this.drawSignageService.clearAllThreadDrawTemplate(
      _.get(this.styleSelected?.display1Templates, `[${this.templateSelectedTypeDisplay1}]`, undefined),
      Constant.CANVAS_DISPLAY_1_ID
    );
    this.drawSignageService.clearAllThreadDrawTemplate(
      _.get(this.styleSelected?.display2Templates, `[${this.templateSelectedTypeDisplay2}]`, undefined),
      Constant.CANVAS_DISPLAY_2_ID
    );
    this.store.dispatch(
      new SaveSignageDisplayEditorStateAction({
        isChangeLayout: true,
        isEnlargeScreen: this.isEnlargeScreen,
        isDisplay2: this.isDisplay2,
        isCheckedAll: this.isCheckedAll,
        isChangedData: this.isChangedData,
        styleSelected: this.styleSelected,
        styleDetailSelected: this.styleDetailSelected,
        styles: this.styles,
        styleDetails: this.styleSelected?.styleDetails,
        areasDisplay1: this.areasDisplay1,
        areasDisplay2: this.areasDisplay2,
        styleNameEdit: this.styleNameEdit,
        styleNoEdit: this.styleNoEdit,
        suffixEdit: this.suffixEdit,
        isShowTextColumn: this.isShowTextColumn,
        isShowDetail: this.isShowDetail,
        isPlay: this.isPlay,
        buttonsPreviewDisplay1: this.buttonsPreviewDisplay1,
        buttonsPreviewDisplay2: this.buttonsPreviewDisplay2,
        isShowVertical: this.isShowVertical,
        mediaSetting: this.mediaSetting
      })
    );
  }

  /**
   * handle after change layout
   */
  handleAfterChangeLayout(): void {
    this.isEnlargeScreen = this.stateOfComponent?.isEnlargeScreen;
    this.isCheckedAll = this.stateOfComponent?.isCheckedAll;
    this.isChangedData = this.stateOfComponent?.isChangedData;
    this.isDisplay2 = this.stateOfComponent?.isDisplay2;
    this.styleSelected = this.stateOfComponent?.styleSelected;
    this.styleDetailSelected = this.stateOfComponent?.styleDetailSelected;
    this.styles = this.stateOfComponent?.styles;
    this.areasDisplay1 = this.stateOfComponent?.areasDisplay1;
    this.areasDisplay2 = this.stateOfComponent?.areasDisplay2;
    this.styleNameEdit = this.stateOfComponent?.styleNameEdit;
    this.styleNoEdit = this.stateOfComponent?.styleNoEdit;
    this.suffixEdit = this.stateOfComponent?.suffixEdit;
    this.isShowTextColumn = this.stateOfComponent?.isShowTextColumn;
    this.styleSelected.styleDetails = this.stateOfComponent?.styleDetails;
    this.isShowDetail = this.stateOfComponent?.isShowDetail;
    this.isPlay = this.stateOfComponent?.isPlay;
    this.buttonsPreviewDisplay1 = this.stateOfComponent?.buttonsPreviewDisplay1;
    this.buttonsPreviewDisplay2 = this.stateOfComponent?.buttonsPreviewDisplay2;
    this.isShowVertical = this.stateOfComponent?.isShowVertical;
    this.mediaSetting = this.stateOfComponent?.mediaSetting;
    this.dataService.sendData([this.IS_PREVIEW_ON, this.isPlay]);
    this.dataService.sendData([this.IS_EDITING_STYLE, this.styleSelected?.isEdit]);

    this.changeDetectorRef.detectChanges();

    // preview
    this.previewTemplate(this.isPlay);
  }

  /**
   * get all styles by project id
   * @param projectId project id
   */
  getAllStyles(projectId: Number): void {
    this.styleService.getStylesByProjectId(projectId).subscribe(
      data => {
        this.styles = Helper.convertDataStyles(data);
        if (this.styles?.length > 0) {
          this.selectStyle(this.styles[0], null, false);
        }
      },
      error => {
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: `Error`,
            text: `An error has occurred. Please try again.`
          }
        });
      }
    );
  }

  /**
   * enlarge the screen
   */
  enlargeScreen(): void {
    this.isEnlargeScreen = !this.isEnlargeScreen;
    // calculate scale canvas for display 1
    if (this.styleSelected?.display1Templates) {
      const divDisplay1 = document.getElementById(this.DIV_DISPLAY_1);
      this.calculateScaleTransformCanvas(
        this.canvasDisplay1,
        _.get(this.styleSelected?.display1Templates, `[${this.templateSelectedTypeDisplay1}]`, undefined),
        divDisplay1
      );
    }
    // calculate scale canvas for display 2
    if (this.styleSelected?.display2Templates) {
      const divDisplay2 = document.getElementById(this.DIV_DISPLAY_2);
      this.calculateScaleTransformCanvas(
        this.canvasDisplay2,
        _.get(this.styleSelected?.display2Templates, `[${this.templateSelectedTypeDisplay2}]`, undefined),
        divDisplay2
      );
    }
  }

  /**
   * change display
   */
  changeDisplay(): void {
    this.isDisplay2 = !this.isDisplay2;
    this.commonObject.isDisplay2Signage = this.isDisplay2;
    this.store.dispatch(
      new SaveMainStateAction({
        common: this.commonObject
      })
    );
    // check show text column
    this.checkShowTextColumn();

    // check show input text at text column
    this.styleSelected?.styleDetails?.forEach(styleDetail => {
      styleDetail.hasText = !this.isDisplay2
        ? styleDetail?.areaDisplay1 instanceof TextArea && !Helper.isText(styleDetail?.mediaMain)
        : (styleDetail?.areaDisplay1 instanceof TextArea || styleDetail?.areaDisplay2 instanceof TextArea) &&
          !Helper.isText(styleDetail?.mediaMain);
    });

    // check active area
    this.checkActiveArea();
    this.drawSignageService.clearAllThreadDrawTemplate(
      _.get(this.styleSelected?.display1Templates, `[${this.templateSelectedTypeDisplay1}]`, undefined),
      Constant.CANVAS_DISPLAY_1_ID
    );
    this.drawSignageService.clearAllThreadDrawTemplate(
      _.get(this.styleSelected?.display2Templates, `[${this.templateSelectedTypeDisplay2}]`, undefined),
      Constant.CANVAS_DISPLAY_2_ID
    );
    // abort fetch request
    this.controllerDisplay1.abort();
    this.controllerDisplay2.abort();
    // clear thread draw external content
    this.clearAllIntervalDrawsNews(this.intervalsDrawNewsDisplay1);
    this.clearAllIntervalDrawsNews(this.intervalsDrawNewsDisplay2);
    this.clearTimeoutDisplay(this.timeoutsDisplay1);
    this.clearTimeoutDisplay(this.timeoutsDisplay2);
    this.subscribesGetUrlPresignedDisplay1.forEach(subscription => subscription?.unsubscribe());
    this.subscribesGetUrlPresignedDisplay2.forEach(subscription => subscription?.unsubscribe());
    this.subscribesGetDataExternal.forEach(subscription => subscription?.unsubscribe());
    // preview
    this.previewTemplate(this.isPlay);
  }

  /**
   * key down
   * @param e
   */
  @HostListener('document:keydown', ['$event'])
  keyDown(e) {
    if (e.keyCode in this.keyCodeMap) {
      this.keyCodeMap[e.keyCode] = true;
      // save data
      if (this.keyCodeMap[17] && this.keyCodeMap[18] && this.keyCodeMap[83]) {
        this.saveStyleDetailsForStyle(true);
      }
    }
  }

  /**
   * key up
   * @param e
   */
  @HostListener('document:keyup', ['$event'])
  keyUp(e) {
    if (e.keyCode in this.keyCodeMap) {
      this.keyCodeMap[e.keyCode] = false;
    }
  }

  /**
   * save style details for style
   * @param isOnPreview
   */
  saveStyleDetailsForStyle(isOnPreview?: boolean): void {
    // validate max length text
    const MAX_TEXT_LENGTH = 256;
    this.styleSelected?.styleDetails?.forEach(styleDetail => {
      if (styleDetail?.text?.length > MAX_TEXT_LENGTH) {
        styleDetail['isError'] = true;
      } else {
        delete styleDetail['isError'];
      }
    });
    if (this.styleSelected?.styleDetails?.some(styleDetail => styleDetail?.text?.length > MAX_TEXT_LENGTH)) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: `Error`,
          text: `Text must contain no more than ${MAX_TEXT_LENGTH} characters.`
        }
      });
      return;
    }
    // filter list style details exists areaDisplay 1 or areaDisplay 2
    const styleDetails = this.styleSelected?.styleDetails
      ?.filter(styleDetail => styleDetail?.areaDisplay1 || styleDetail?.areaDisplay2)
      .map(styleDetail => {
        return Helper.convertDataDetailStyleBackward(styleDetail);
      });
    // save data
    this.styleDetailService.saveStyleDetails(this.styleSelected?.id, styleDetails).subscribe(
      data => {
        this.toast.success(Constant.SAVE_SUCCESS, '');
        this.isChangedData = false;
        this.saveDataSuccess.emit(true);
        if (isOnPreview) {
          // preview
          this.previewTemplate();
        }
      },
      error => {
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: `Error`,
            text: `An error has occurred. Please try again.`
          }
        });
        this.saveDataSuccess.emit(false);
      }
    );
  }

  /**
   * select a style
   * @param style Style object
   * @param event
   * @param isUpdate
   */
  selectStyle(style: Style, event, isUpdate: boolean): void {
    if (this.styleSelected?.isEdit) {
      return;
    }
    // check click button change template, checkbox
    if (
      event?.target?.id === 'checkBoxStyle' ||
      event?.target?.id === 'showDialogDisplay1' ||
      event?.target?.id === 'showDialogDisplay2' ||
      (style?.id == this.styleSelected?.id && !isUpdate)
    ) {
      this.styleDetailSelected = undefined;
      return;
    }
    // save data when change data
    if (this.isChangedData) {
      this.dialogService.showDialog(
        DialogConfirmComponent,
        {
          data: {
            text: `Do you want to save changes before selecting another style?`,
            button1: 'Yes',
            button2: 'No',
            title: 'Confirmation'
          }
        },
        result => {
          if (result) {
            this.saveStyleDetailsForStyle(false);
            const sub = this.saveDataSuccess.subscribe(isSuccess => {
              sub.unsubscribe();
              if (isSuccess) {
                this.selectStyle(style, null, false);
              }
            });
          } else {
            this.isChangedData = false;
            this.selectStyle(style, null, false);
          }
        }
      );
    } else {
      this.styleDetailSelected = undefined;
      // clear all thread draw template
      if (this.styleSelected?.display1Templates) {
        this.drawSignageService.clearAllThreadDrawTemplate(
          _.get(this.styleSelected?.display1Templates, `[${this.templateSelectedTypeDisplay1}]`, undefined),
          Constant.CANVAS_DISPLAY_1_ID
        );
      }
      if (this.styleSelected?.display2Templates) {
        this.drawSignageService.clearAllThreadDrawTemplate(
          _.get(this.styleSelected?.display2Templates, `[${this.templateSelectedTypeDisplay2}]`, undefined),
          Constant.CANVAS_DISPLAY_2_ID
        );
      }
      this.styleSelected = style;

      this.templateSelectedTypeDisplay1 = DestinationEnum.MAIN;
      this.templateSelectedTypeDisplay2 = DestinationEnum.MAIN;

      this.isShowDetail = this.styleSelected.id && !this.styleSelected.isEdit;
      // get data style is selected
      this.getStyleWithFullData();
    }
  }

  /**
   * get style with full data
   */
  getStyleWithFullData(): void {
    // get fill data template main - sub by style id
    this.styleService.getStyleWithFullDataTemplateById(this.styleSelected.id).subscribe(
      data => {
        // assign value styleSelected
        const styleOutput = Helper.convertDataStyle(data);

        // get buttons preview
        this.buttonsPreviewDisplay1 = this.getButtonsPreview(styleOutput?.displayTemplate1);
        this.buttonsPreviewDisplay2 = this.getButtonsPreview(styleOutput?.displayTemplate2);

        // set newest display
        this.styleSelected.displayTemplate1 = styleOutput?.displayTemplate1;
        this.styleSelected.displayTemplate2 = styleOutput?.displayTemplate2;
        this.styleSelected.display1Templates = styleOutput.display1Templates;
        this.styleSelected.display2Templates = styleOutput.display2Templates;
        // get all areas display
        this.getAllAreasDisplay();

        // get data detail
        this.styleDetailService.getDetailsInformationForStyle(this.styleSelected.id).subscribe(
          data => {
            this.styleSelected.styleDetails = Helper.convertDataDetailsStyle(data);

            // set template type for area display 1
            this.setTemplateTypeForArea(this.areasDisplay1, this.AREA_DISPLAY_1);

            // set template type for area display 2
            this.setTemplateTypeForArea(this.areasDisplay2, this.AREA_DISPLAY_2);

            // sort by id area and template type display 1
            const styleDetailsDisplay1 = this.styleSelected.styleDetails
              .filter(styleDetail => styleDetail?.areaDisplayId1)
              .sort((styleDetail1, styleDetail2) => {
                return (
                  styleDetail1?.areaDisplay1?.templateType - styleDetail2?.areaDisplay1?.templateType ||
                  (styleDetail1?.areaDisplayId1 as number) - (styleDetail2?.areaDisplayId1 as number)
                );
              });
            // sort by id area and template type display 2
            const styleDetailsDisplay2 = this.styleSelected.styleDetails
              .filter(styleDetail => !styleDetail?.areaDisplayId1)
              .sort((styleDetail1, styleDetail2) => {
                return (
                  styleDetail1?.areaDisplay2?.templateType - styleDetail2?.areaDisplay2?.templateType ||
                  (styleDetail1?.areaDisplayId2 as number) - (styleDetail2?.areaDisplayId2 as number)
                );
              });

            this.styleSelected.styleDetails = styleDetailsDisplay1.concat(styleDetailsDisplay2);

            if (this.styleSelected?.styleDetails?.length == 0 && this.areasDisplay2?.length > 0) {
              this.styleSelected?.styleDetails.push(new StyleDetail(this.styleSelected.id));
            }
            // check active area
            this.checkActiveArea();

            // check show text column
            this.checkShowTextColumn();

            // check show input text at text column
            this.styleSelected?.styleDetails?.forEach(styleDetail => {
              styleDetail.hasText = !this.isDisplay2
                ? styleDetail?.areaDisplay1 instanceof TextArea && !Helper.isText(styleDetail?.mediaMain)
                : (styleDetail?.areaDisplay1 instanceof TextArea || styleDetail?.areaDisplay2 instanceof TextArea) &&
                  !Helper.isText(styleDetail?.mediaMain);
              styleDetail.text = styleDetail.text ?? '';
            });
            // preview
            this.previewTemplate();
          },
          error => {
            this.dialogService.showDialog(DialogMessageComponent, {
              data: {
                title: `Error`,
                text: `An error has occurred. Please try again.`
              }
            });
            this.saveDataSuccess.emit(false);
          }
        );
      },
      error => {
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: `Error`,
            text: `An error has occurred. Please try again.`
          }
        });
        this.saveDataSuccess.emit(false);
      }
    );
  }

  /**
   * get full data picture to draw
   * @param canvasDisplayId id area draw
   * @param dataExternalSettings list data external setting(id area, id external content)
   * @param typeOfTemplate type of template
   * @returns
   */
  private async getFullDataExternalContentToPreview(
    canvasDisplayId: string,
    dataExternalSettings: DataExternalSetting[],
    typeOfTemplate: DestinationEnum
  ) {
    if (!dataExternalSettings) {
      return;
    }

    let displayTemplates = 'display1Templates';
    if (canvasDisplayId !== Constant.CANVAS_DISPLAY_1_ID) {
      displayTemplates = 'display2Templates';
      // setup AbortController
      this.controllerDisplay2 = new AbortController();
    } else {
      // setup AbortController
      this.controllerDisplay1 = new AbortController();
    }
    let intervals = new Array<any>();
    // get full data to draw

    let subscription = this.pictureAreaService.getFullDataPictureAreaExternalContent(dataExternalSettings).subscribe(async dataResponse => {
      for (let i = 0; i < dataResponse?.length; i++) {
        // get area to draw
        let area = this.getAreaExternalContent(this.styleSelected[displayTemplates][typeOfTemplate], dataResponse[i].idArea);
        // create media by url to draw
        let media = this.createNewImage('png', dataResponse[i]['mediaUrl']);
        // draw weather
        if (area && !dataResponse[i]['isNews']) {
          area['media'] = media;
          if (media) {
            this.drawService.drawAreaPicture(area);
          }
          // draw news
        } else if (area && dataResponse[i]['isNews']) {
          intervals.push(null);
          area['media'] = media;
          area['countPage'] = 1;
          await this.drawAreaPictureNews(canvasDisplayId, intervals.length - 1, area, dataResponse[i]);
        }
      }
    });
    // set intervals and subscription for display drawing
    if (canvasDisplayId !== Constant.CANVAS_DISPLAY_1_ID) {
      this.intervalsDrawNewsDisplay2 = intervals;
      this.subscribesGetDataExternal[DisplaysEnum.DISPLAY_2] = subscription;
    } else {
      this.subscribesGetDataExternal[DisplaysEnum.DISPLAY_1] = subscription;
      this.intervalsDrawNewsDisplay1 = intervals;
    }
  }

  /**
   * draw news picture
   * @param indexInterval index interval drawing
   * @param area area draw
   * @param dataExternalSetting data external content to draw
   * @param countPage page drawing
   * @param interval interval drawing
   */
  private async drawAreaPictureNews(
    canvasDisplayId: string,
    indexInterval: number,
    area: PictureArea,
    dataExternalSetting: DataExternalSetting
  ) {
    // repeat
    if (area['countPage'] > dataExternalSetting['numberPage']) {
      area['countPage'] = 1;
      this.drawAreaPictureNews(canvasDisplayId, indexInterval, area, dataExternalSetting);
      // draw news picture
    } else if (canvasDisplayId !== Constant.CANVAS_DISPLAY_1_ID) {
      this.drawPictureNews(
        this.subscribesGetUrlPresignedDisplay2,
        indexInterval,
        dataExternalSetting,
        area,
        canvasDisplayId,
        this.intervalsDrawNewsDisplay2
      );
    } else {
      this.drawPictureNews(
        this.subscribesGetUrlPresignedDisplay1,
        indexInterval,
        dataExternalSetting,
        area,
        canvasDisplayId,
        this.intervalsDrawNewsDisplay1
      );
    }
  }

  /**
   * draw news
   * @param subscriptionUrl
   * @param index
   * @param countPage
   * @param dataExternalSetting
   * @param area
   * @param canvasDisplayId
   * @param intervals
   */
  private drawPictureNews(
    subscriptionUrl: Subscription[],
    index: number,
    dataExternalSetting: DataExternalSetting,
    area: PictureArea,
    canvasDisplayId: string,
    intervals: any
  ) {
    intervals[index]?.unsubscribe();
    subscriptionUrl[index]?.unsubscribe();
    // get news picture url next page
    subscriptionUrl[index] = this.pictureAreaService
      .getNewsPictureUrlPresigned(area['countPage'], dataExternalSetting.idExternalContent)
      .subscribe(async data => {
        area['media'] = this.createNewImage('png', data['url']);
        // draw present page
        if (area['media']) {
          const isCancelFetch = await this.drawService.drawAreaPicture(area);
          if (isCancelFetch) {
            intervals[index]?.unsubscribe();
            return;
          }
        } else {
          let canvas = area.canvas;
          let ctx = canvas.getContext('2d');
          ctx.clearRect(0, 0, canvas.width, canvas.height);
        }
        // draw next page
        const timeOut = interval(+dataExternalSetting['duration'] * 1000);
        intervals[index] = timeOut
          .pipe(
            timeInterval(),
            takeUntil(this.pausePreviewSubject),
            repeatWhen(() => this.startPreviewSubject)
          )
          .subscribe(() => {
            area['countPage'] += 1;
            this.drawAreaPictureNews(canvasDisplayId, index, area, dataExternalSetting);
          });
        if (!this.isPlay) {
          this.pausePreviewSubject.next();
        }
      });
  }

  /**
   * clear all thread draw news page
   */
  private clearAllIntervalDrawsNews(intervals: any) {
    intervals.forEach(subscription => subscription?.unsubscribe());
  }

  /**
   * create new image by url
   * @param type type of image
   * @param url url media
   * @returns new image
   */
  private createNewImage(type: string, url: string): Image {
    if (!url) {
      return null;
    }
    let media: Media = new Image();
    media.type = type;
    media.url = url;
    return media as Image;
  }

  /**
   * get area external content by id
   * @param template
   * @param idArea of external content
   * @returns area external content
   */
  private getAreaExternalContent(template: Template, idArea: number): PictureArea {
    if (!template) {
      return null;
    }
    for (let layer of template?.layers) {
      for (let area of layer?.areas) {
        if (area.id === idArea) {
          return area as PictureArea;
        }
      }
    }
  }

  /**
   * get all areas display
   */
  getAllAreasDisplay() {
    this.areasDisplay1 = new Array<Area>();
    this.areasDisplay2 = new Array<Area>();

    // get list area (free picture/free text) display 1
    if (this.styleSelected.display1Templates) {
      this.styleSelected.display1Templates
        .filter(template => template)
        .forEach(templateData => {
          this.getAllAreaTemplateForStyle(templateData).forEach(area => {
            this.areasDisplay1.push(area);
          });
        });
    }

    // get list area (free picture/free text) display 2
    if (this.styleSelected.display2Templates) {
      this.styleSelected.display2Templates
        .filter(template => template)
        .forEach(templateData => {
          this.getAllAreaTemplateForStyle(templateData).forEach(area => {
            this.areasDisplay2.push(area);
          });
        });
    }
  }

  /**
   * preview display template
   * @param isOn
   */
  previewTemplate(isOn?: boolean): void {
    if (!isOn) {
      this.isPlay = false;
      this.dataService.sendData([this.IS_PREVIEW_ON, this.isPlay]);
    }
    this.clearTimeoutDisplay(this.timeoutsDisplay1);
    this.clearTimeoutDisplay(this.timeoutsDisplay2);
    // preview template
    this.drawDisplay(
      Constant.CANVAS_DISPLAY_1_ID,
      this.canvasDisplay1,
      _.get(this.styleSelected?.display1Templates, `[${this.templateSelectedTypeDisplay1}]`, undefined)
    );
    if (this.isDisplay2) {
      this.drawDisplay(
        Constant.CANVAS_DISPLAY_2_ID,
        this.canvasDisplay2,
        _.get(this.styleSelected?.display2Templates, `[${this.templateSelectedTypeDisplay2}]`, undefined)
      );
    }
    //
    this.drawSignageService.changeStatePlayPause(this.isPlay, Constant.CANVAS_DISPLAY_1_ID);
    this.drawSignageService.changeStatePlayPause(this.isPlay, Constant.CANVAS_DISPLAY_2_ID);
  }

  /**
   * set template type for area
   * @param areas
   * @param areaDisplayType
   */
  setTemplateTypeForArea(areas: Array<Area>, areaDisplayType: string): void {
    areas.forEach(area => {
      const index =
        areaDisplayType == this.AREA_DISPLAY_1
          ? this.styleSelected?.styleDetails?.findIndex(styleDetail => styleDetail?.areaDisplayId1 == area.id)
          : this.styleSelected?.styleDetails?.findIndex(styleDetail => styleDetail?.areaDisplayId2 == area.id);
      if (index == -1) {
        return;
      }
      this.styleSelected.styleDetails[index][areaDisplayType].templateType = area.templateType;
    });
  }

  /**
   * select style detail
   * @param styleDetail
   */
  selectStyleDetail(styleDetail: StyleDetail): void {
    this.styleDetailSelected = styleDetail;
  }

  /**
   * check show text column
   */
  checkShowTextColumn(): void {
    // check show text column
    this.isShowTextColumn = !this.isDisplay2
      ? this.styleSelected?.styleDetails?.some(styleDetail => styleDetail?.areaDisplay1?.checkTypeTextArea())
      : this.styleSelected?.styleDetails?.some(
          styleDetail => styleDetail?.areaDisplay1?.checkTypeTextArea() || styleDetail?.areaDisplay2?.checkTypeTextArea()
        );
  }

  /**
   * get all area template for style
   * @param template Template object
   */
  private getAllAreaTemplateForStyle(template: Template): Array<Area> {
    return Helper.getAllAreaTemplate(template).filter(
      area =>
        area &&
        !area.isFix &&
        (area.getArea().attribute == LinkDataPictureEnum.FREE_PICTURE || area.getArea().linkReferenceData == LinkDataTextEnum.FREE_TEXT)
    );
  }

  /**
   * check or uncheck a style
   * @param index index of check-changed style
   * @param e
   */
  changeChecked(index: number, e): void {
    e.stopPropagation();
    this.styles[index].isChecked = !this.styles[index].isChecked;
    this.isCheckedAll = this.styles.every(styleData => styleData.isChecked);
  }

  /**
   * check or uncheck all style
   */
  checkAll(): void {
    this.isCheckedAll = !this.isCheckedAll;
    this.styles.forEach(styleData => (styleData.isChecked = this.isCheckedAll));
  }

  /**
   * preview display 1
   * @param item
   */
  previewDisplay1(item: any): void {
    if (this.isPlay || this.styleSelected?.isEdit) {
      return;
    }
    this.templateSelectedTypeDisplay1 = item.key;
    // clear all thread template display 1
    this.drawSignageService.clearAllThreadDrawTemplate(
      _.get(this.styleSelected?.display1Templates, `[${this.templateSelectedTypeDisplay1}]`, undefined),
      Constant.CANVAS_DISPLAY_1_ID
    );
    this.changeDetectorRef.detectChanges();
    // preview template
    this.drawDisplay(
      Constant.CANVAS_DISPLAY_1_ID,
      this.canvasDisplay1,
      _.get(this.styleSelected?.display1Templates, `[${this.templateSelectedTypeDisplay1}]`, undefined)
    );
  }

  /**
   * preview display 2
   * @param item
   */
  previewDisplay2(item: any): void {
    if (this.isPlay || this.styleSelected?.isEdit) {
      return;
    }
    this.templateSelectedTypeDisplay2 = item.key;
    // clear all thread template display 1
    this.drawSignageService.clearAllThreadDrawTemplate(
      _.get(this.styleSelected?.display2Templates, `[${this.templateSelectedTypeDisplay2}]`, undefined),
      Constant.CANVAS_DISPLAY_2_ID
    );
    this.changeDetectorRef.detectChanges();
    // preview template
    this.drawDisplay(
      Constant.CANVAS_DISPLAY_2_ID,
      this.canvasDisplay2,
      _.get(this.styleSelected?.display2Templates, `[${this.templateSelectedTypeDisplay2}]`, undefined)
    );
  }

  /**
   * add new style
   */
  addNewStyle(): void {
    if (this.styleSelected && this.styleSelected.isEdit) {
      return;
    }
    // save data when change data
    if (this.isChangedData) {
      this.dialogService.showDialog(
        DialogConfirmComponent,
        {
          data: {
            text: `Do you want to save changes before selecting another style?`,
            button1: 'Yes',
            button2: 'No',
            title: 'Confirmation'
          }
        },
        result => {
          if (result) {
            this.saveStyleDetailsForStyle(false);
            const sub = this.saveDataSuccess.subscribe(isSuccess => {
              sub.unsubscribe();
              if (isSuccess) {
                this.addNewStyle();
              }
            });
          } else {
            this.isChangedData = false;
            this.addNewStyle();
          }
        }
      );
    } else {
      this.styleNameEdit = Constant.EMPTY;
      this.styleNoEdit = Constant.EMPTY;
      this.suffixEdit = Constant.EMPTY;
      let styleNew = new Style(null, this.styleNameEdit, this.styleNoEdit, this.suffixEdit, this.projectId);
      this.styles.push(styleNew);
      this.styleSelected = styleNew;
      this.styleSelected.isEdit = true;
      this.isShowDetail = false;
      this.isChangedData = true;
      this.dataService.sendData([this.IS_EDITING_STYLE, this.styleSelected.isEdit]);
    }
  }

  /**
   * edit style
   */
  editStyle(): void {
    // save data when change data
    if (this.isChangedData) {
      this.dialogService.showDialog(
        DialogConfirmComponent,
        {
          data: {
            text: `Do you want to save changes before selecting another style?`,
            button1: 'Yes',
            button2: 'No',
            title: 'Confirmation'
          }
        },
        result => {
          if (result) {
            this.saveStyleDetailsForStyle(false);
            const sub = this.saveDataSuccess.subscribe(isSuccess => {
              sub.unsubscribe();
              if (isSuccess) {
                this.styleSelected['isChangedStyleDetail'] = false;
                this.editStyle();
              }
            });
          } else {
            this.isChangedData = false;
            this.styleSelected['isChangedStyleDetail'] = true;
            this.editStyle();
          }
        }
      );
    } else {
      if (!this.styleSelected) {
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: `Error`,
            text: 'Please select a style.'
          }
        });
        return;
      }
      this.styleNoEdit = this.styleSelected.styleNo;
      this.suffixEdit = this.styleSelected.suffix;
      this.styleNameEdit = this.styleSelected.name;
      this.styleSelected.isEdit = true;
      this.isChangedData = true;
      this.dataService.sendData([this.IS_EDITING_STYLE, this.styleSelected.isEdit]);
    }
  }

  /**
   * cancel save style
   */
  cancelSaveStyle(): void {
    this.styleSelected.isEdit = false;
    this.dataService.sendData([this.IS_EDITING_STYLE, this.styleSelected.isEdit]);
    this.setValueForIsChangedData();
    if (!this.styleSelected.id) {
      this.styles.pop();
      if (this.styles.length > 0) {
        this.selectStyle(this.styles[0], null, false);
      } else {
        this.styleSelected = undefined;
      }
    } else {
      this.selectStyle(this.styleSelected, null, false);
    }
    this.isCheckedAll = this.styles.length > 0 && this.styles.every(style => style.isChecked);
  }

  /**
   * validate style when add new/ edit
   * @param styleNo
   * @param suffix
   * @param styleName
   */
  validateStyle(styleNo: string, suffix: string, styleName: string): boolean {
    // validate style no
    if (styleNo.length < Constant.MIN_NO_LENGTH) {
      this.styleNoElementRef.nativeElement.focus();
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: `Error`,
          text: 'Style No. cannot be empty.'
        }
      });
      this.saveDataSuccess.emit(false);
      return false;
    }
    if (styleNo.length > Constant.MAX_NO_LENGTH) {
      this.styleNoElementRef.nativeElement.focus();
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: `Error`,
          text: `Style No. must contain no more than ${Constant.MAX_NO_LENGTH} characters.`
        }
      });
      this.saveDataSuccess.emit(false);
      return false;
    }

    // validate suffix
    if (suffix.length > Constant.MAX_SUFFIX_LENGTH) {
      this.suffixElementRef.nativeElement.focus();
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: `Error`,
          text: `Suffix must contain no more than ${Constant.MAX_SUFFIX_LENGTH} characters.`
        }
      });
      this.saveDataSuccess.emit(false);
      return false;
    }

    // validate style name
    if (styleName.length < Constant.MIN_NAME_LENGTH) {
      this.styleNameElementRef.nativeElement.focus();
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: `Error`,
          text: 'Style Name cannot be empty.'
        }
      });
      this.saveDataSuccess.emit(false);
      return false;
    }
    if (styleName.length > Constant.MAX_NAME_LENGTH) {
      this.styleNameElementRef.nativeElement.focus();
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: `Error`,
          text: `Style Name must contain no more than ${Constant.MAX_NAME_LENGTH} characters.`
        }
      });
      this.saveDataSuccess.emit(false);
      return false;
    }
    return true;
  }

  /**
   * save style
   */
  saveStyle(): void {
    if (this.styleSelected && !this.styleSelected.isEdit) {
      return;
    }
    let styleNo = this.styleNoEdit.trim();
    let suffix = this.suffixEdit.trim();
    let styleName = this.styleNameEdit.trim();

    // validate style No, Name, suffix
    if (!this.validateStyle(styleNo, suffix, styleName)) {
      return;
    }
    // validate duplicate style
    this.styleService.checkExistStyle(styleNo, suffix, this.projectId, this.styleSelected.id ?? null).subscribe(data => {
      if (data) {
        this.styleNoElementRef.nativeElement.focus();
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: `Error`,
            text: 'Duplicated style.'
          }
        });
        this.saveDataSuccess.emit(false);
        return;
      }
      // asign value
      this.styleSelected.isEdit = false;
      this.dataService.sendData([this.IS_EDITING_STYLE, this.styleSelected.isEdit]);
      this.styleSelected.name = styleName;
      this.styleSelected.styleNo = styleNo;
      this.styleSelected.suffix = suffix;
      // edit style
      if (this.styleSelected.id) {
        this.styleService.saveStyle(Helper.convertDataStyleBackward(this.styleSelected)).subscribe(
          styleData => {
            let styleEdited = Helper.convertDataStyle(styleData);
            this.setValueForIsChangedData();
            styleEdited.isChecked = this.styleSelected.isChecked;
            styleEdited.styleDetails = this.styleSelected.styleDetails;
            styleEdited.display1Templates = this.styleSelected.display1Templates;
            styleEdited.display2Templates = this.styleSelected.display2Templates;
            let styleIndex = this.styles.findIndex(style => style.id == styleEdited.id);
            if (styleIndex == -1) {
              return;
            }
            this.styles[styleIndex] = styleEdited;
            this.styleSelected = this.styles[styleIndex];
            this.saveDataSuccess.emit(true);
          },
          error => {
            this.dialogService.showDialog(DialogMessageComponent, {
              data: {
                title: `Error`,
                text: `An error has occurred. Please try again.`
              }
            });
            this.saveDataSuccess.emit(false);
          }
        );
        // style's id null
      } else {
        if (!this.styleSelected?.styleDetails) {
          // Add style
          this.styleService.saveStyle(Helper.convertDataStyleBackward(this.styleSelected)).subscribe(
            styleData => {
              this.styles[this.styles.length - 1] = Helper.convertDataStyle(styleData);
              this.isChangedData = false;
              this.selectStyle(this.styles[this.styles.length - 1], null, false);
              this.isCheckedAll = this.styles.every(style => style.isChecked);
              this.saveDataSuccess.emit(true);
            },
            error => {
              this.dialogService.showDialog(DialogMessageComponent, {
                data: {
                  title: `Error`,
                  text: `An error has occurred. Please try again.`
                }
              });
              this.saveDataSuccess.emit(false);
            }
          );
        } else {
          // Duplicate style
          const duplicatedStyle = this.styleSelected;
          this.styleService.duplicateStyle(Helper.convertDataStyleBackward(duplicatedStyle), duplicatedStyle['oldId']).subscribe(
            styleData => {
              this.styles[this.styles.length - 1] = Helper.convertDataStyle(styleData);
              this.isChangedData = false;
              this.selectStyle(this.styles[this.styles.length - 1], null, false);
              this.isCheckedAll = this.styles.every(style => style.isChecked);
              this.saveDataSuccess.emit(true);
            },
            error => {
              this.dialogService.showDialog(DialogMessageComponent, {
                data: {
                  title: `Error`,
                  text: `An error has occurred. Please try again.`
                }
              });
              this.saveDataSuccess.emit(false);
            }
          );
        }
      }
    });
  }

  /**
   * set value for isChangedData when change style detail
   */
  setValueForIsChangedData() {
    if (this.styleSelected['isChangedStyleDetail'] != undefined) {
      this.isChangedData = this.styleSelected['isChangedStyleDetail'];
      delete this.styleSelected['isChangedStyleDetail'];
    } else {
      this.isChangedData = false;
    }
  }

  /**
   * allow drop
   * @param event
   */
  allowDrop(event): void {
    if (this.isPlay || this.styleSelected?.isEdit) {
      return;
    }
    event.preventDefault();
  }

  /**
   * validate drag drop media
   * @param media Media object
   */
  validateDragDropMedia(media: Media): boolean {
    // one display
    if (!this.isDisplay2) {
      if (this.styleDetailSelected?.areaDisplay1?.checkTypeTextArea() == !Helper.isText(media)) {
        // show message error
        this.dialogService.showDialog(DialogMessageComponent, {
          data: { title: 'Error', text: 'Invalid media file. Please select another type of media to drop here.' }
        });
        return false;
      }
      // two display
    } else {
      // area display 1 not exists
      if (!this.styleDetailSelected?.areaDisplay1) {
        // area display 2 not exists
        if (!this.styleDetailSelected?.areaDisplay2) {
          // show message error
          this.dialogService.showDialog(DialogMessageComponent, {
            data: { title: 'Error', text: 'Please select an area.' }
          });
          return false;
        } else {
          if (this.styleDetailSelected.areaDisplay2.checkTypeTextArea() == !Helper.isText(media)) {
            // show message error
            this.dialogService.showDialog(DialogMessageComponent, {
              data: { title: 'Error', text: 'Invalid media file. Please select another type of media to drop here.' }
            });
            return false;
          }
        }
        // area display 1 exists
      } else {
        // area display 2 not exists
        if (!this.styleDetailSelected?.areaDisplay2) {
          if (this.styleDetailSelected.areaDisplay1.checkTypeTextArea() == !Helper.isText(media)) {
            // show message error
            this.dialogService.showDialog(DialogMessageComponent, {
              data: { title: 'Error', text: 'Invalid media file. Please select another type of media to drop here.' }
            });
            return false;
          }
        } else {
          if (this.styleDetailSelected.areaDisplay2.checkTypeTextArea() == !Helper.isText(media)) {
            // show message error
            this.dialogService.showDialog(DialogMessageComponent, {
              data: { title: 'Error', text: 'Invalid media file. Please select another type of media to drop here.' }
            });
            return false;
          }
        }
      }
    }
    return true;
  }

  /**
   * drop media
   * @param event
   * @param styleDetail
   */
  dropMedia(event, styleDetail: StyleDetail): void {
    if (
      JSON.parse(event.dataTransfer.getData(Constant.IS_MEDIA_IN_STATION_CONTENT_FOLDER)) ||
      JSON.parse(event.dataTransfer.getData(Constant.IS_MEDIA_IN_LCD_LAYOUT_EDITOR)) ||
      JSON.parse(event.dataTransfer.getData(Constant.FOLDER_INDEX_WORD_EDITOR))
    ) {
      return;
    }

    event.preventDefault();
    this.styleDetailSelected = styleDetail;
    // convert data media
    const mediaData = JSON.parse(event.dataTransfer.getData(Constant.MEDIA_VALUE));
    const media = Helper.convertMediaData(mediaData);
    // validate media drag drop
    if (!this.validateDragDropMedia(media)) {
      return;
    }

    // case drag drop image + sound
    if (
      Helper.isSound(media) &&
      Helper.isImage(this.styleDetailSelected?.mediaMain) &&
      !Helper.isSound(this.styleDetailSelected?.mediaSound)
    ) {
      this.styleDetailSelected.mediaSound = media;
      // case other
    } else {
      // clear sound
      this.styleDetailSelected.mediaSound = undefined;
      if (Helper.isText(media)) {
        this.styleDetailSelected.hasText = false;
        this.styleDetailSelected.text = undefined;
      }
      // asign value for main media
      this.styleDetailSelected.mediaMain = media;
    }
    this.isChangedData = true;
  }

  /**
   * change area display 2
   * @param areaId area's id
   * @param styleDetail StyleDetail object
   */
  changeAreaDisplay2(areaId: Number, styleDetail: StyleDetail): void {
    const area = this.areasDisplay2.find(area => area.id == +areaId);
    if (area) {
      styleDetail.areaDisplay2 = area;
      // case 1: area display1 exists
      if (styleDetail?.areaDisplay1) {
        if (styleDetail.areaDisplay2.checkTypeTextArea()) {
          styleDetail.hasText = !Helper.isText(styleDetail.mediaMain);
          styleDetail.text = styleDetail.text ?? '';
        }
        // case 2: area display1 not exists
      } else {
        // case 1: area is TextArea
        if (area.checkTypeTextArea()) {
          styleDetail.hasText = !Helper.isText(styleDetail.mediaMain);
          styleDetail.text = styleDetail.text ?? '';
          if (!Helper.isText(styleDetail.mediaMain)) {
            _.set(styleDetail, 'mediaMain', undefined);
            _.set(styleDetail, 'mediaSound', undefined);
          }
          // case 2: area is PictureArea
        } else {
          styleDetail.hasText = false;
          styleDetail.text = undefined;
          if (Helper.isText(styleDetail.mediaMain)) {
            _.set(styleDetail, 'mediaMain', undefined);
          }
        }
      }
      // clear data
    } else {
      styleDetail.areaDisplay2 = undefined;
      if (!styleDetail?.areaDisplay1) {
        _.set(styleDetail, 'mediaMain', undefined);
        _.set(styleDetail, 'mediaSound', undefined);
        styleDetail.hasText = false;
        styleDetail.text = undefined;
      }
    }
    this.isChangedData = true;
    // check active area
    this.checkActiveArea();
    // check show text column
    this.checkShowTextColumn();
  }

  /**
   * check active area
   */
  checkActiveArea(): void {
    const areas = this.styleSelected?.styleDetails?.map(styleDetail => styleDetail?.areaDisplay2);
    this.areasDisplay2?.forEach(area => {
      area.isActive = areas?.findIndex(areaData => areaData?.id == area?.id) == -1;
    });
    this.isDisabledButtonAdd = this.areasDisplay2?.every(area => !area.isActive);
  }

  /**
   * add a row
   */
  addRow(): void {
    const newStyleDetail = new StyleDetail(this.styleSelected.id);
    this.styleSelected?.styleDetails?.push(newStyleDetail);
  }

  /**
   * delete style detail
   */
  deleteStyleDetail(): void {
    this.isChangedData = true;
    if (this.styleDetailSelected?.areaDisplay1) {
      this.styleDetailSelected.mediaMain = undefined;
      this.styleDetailSelected.mediaSound = undefined;
      this.styleDetailSelected.hasText = this.styleDetailSelected.areaDisplay1.checkTypeTextArea();
      this.styleDetailSelected.text = !this.styleDetailSelected.areaDisplay1.checkTypeTextArea() ? undefined : '';
    } else {
      const index = this.styleSelected?.styleDetails.findIndex(styleDetail => styleDetail === this.styleDetailSelected);
      if (index == -1) {
        return;
      }
      this.styleSelected.styleDetails.splice(index, 1);
      if (this.styleSelected?.styleDetails?.length == 0) {
        this.styleSelected.styleDetails.push(new StyleDetail(this.styleSelected.id));
        this.styleDetailSelected = undefined;
      }
      this.checkActiveArea();
      this.checkShowTextColumn();
    }
  }

  /**
   * delete style
   */
  deleteStyle(): void {
    let checkedStyles = this.styles.filter(style => style.isChecked);
    if (checkedStyles.length == 0) {
      this.dialogService.showDialog(DialogMessageComponent, { data: { title: 'Error', text: 'Please select a style.' } });
      return;
    }
    if (this.styleSelected?.isEdit) {
      return;
    }
    // save data when change data
    if (this.isChangedData) {
      this.dialogService.showDialog(
        DialogConfirmComponent,
        {
          data: {
            text: `Do you want to save changes before selecting another style?`,
            button1: 'Yes',
            button2: 'No',
            title: 'Confirmation'
          }
        },
        result => {
          if (result) {
            this.saveStyleDetailsForStyle(false);
            const sub = this.saveDataSuccess.subscribe(isSuccess => {
              sub.unsubscribe();
              if (isSuccess) {
                this.styleSelected['isChangedStyleDetail'] = false;
                this.deleteStyle();
              }
            });
          } else {
            this.styleSelected['isChangedStyleDetail'] = true;
            this.isChangedData = false;
            this.deleteStyle();
          }
        }
      );
    } else {
      this.dialogService.showDialog(
        DialogConfirmComponent,
        {
          data: {
            text: checkedStyles.length > 1 ? 'Do you want to delete checked styles?' : 'Do you want to delete the selected style?',
            button1: 'Yes',
            button2: 'No',
            title: 'Confirmation'
          }
        },
        result => {
          if (result) {
            this.styleService.deleteStyles(checkedStyles.map(style => style?.id)).subscribe(
              () => {
                this.styles = this.styles.filter(style => !style.isChecked);
                if (this.styles.length > 0) {
                  if (this.styleSelected?.isChecked) {
                    this.selectStyle(this.styles[0], null, false);
                  }
                  this.setValueForIsChangedData();
                } else {
                  this.styleSelected = undefined;
                }
                this.isCheckedAll = false;
              },
              error => {
                this.dialogService.showDialog(DialogMessageComponent, {
                  data: {
                    title: `Error`,
                    text: `An error has occurred. Please try again.`
                  }
                });
                return;
              }
            );
          } else {
            this.setValueForIsChangedData();
          }
        }
      );
    }
  }

  /**
   * duplicate style
   */
  duplicateStyle(): void {
    if (!this.styleSelected) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: `Error`,
          text: 'Please select a style.'
        }
      });
      return;
    }
    if (this.styleSelected && this.styleSelected.isEdit) {
      return;
    }
    if (this.isChangedData) {
      this.dialogService.showDialog(
        DialogConfirmComponent,
        {
          data: {
            text: `Do you want to save changes before selecting another style?`,
            button1: 'Yes',
            button2: 'No',
            title: 'Confirmation'
          }
        },
        result => {
          if (result) {
            this.saveStyleDetailsForStyle(false);
            const sub = this.saveDataSuccess.subscribe(isSuccess => {
              sub.unsubscribe();
              if (isSuccess) {
                this.duplicateStyle();
              }
            });
          } else {
            this.isChangedData = false;
            this.duplicateStyle();
          }
        }
      );
    } else {
      this.styleNoEdit = this.styleSelected.styleNo;
      this.suffixEdit = this.styleSelected.suffix;
      this.styleNameEdit = this.styleSelected.name;
      let duplicatedStyle = new Style(null, this.styleNameEdit, this.styleNoEdit, this.suffixEdit, this.projectId);
      duplicatedStyle.styleDetails = this.styleSelected?.styleDetails;
      duplicatedStyle.displayTemplate1 = this.styleSelected.displayTemplate1;
      duplicatedStyle.displayTemplate2 = this.styleSelected.displayTemplate2;
      duplicatedStyle['oldId'] = this.styleSelected.id;
      this.styleSelected = duplicatedStyle;
      this.styles.push(duplicatedStyle);
      this.isChangedData = true;
      this.styleSelected.isEdit = true;
      this.isShowDetail = false;
      this.dataService.sendData([this.IS_EDITING_STYLE, this.styleSelected.isEdit]);
    }
  }

  /**
   * change template
   */
  changeTemplate(style?: Style, displayType?: DisplaysEnum): void {
    if (this.styleSelected?.isEdit) {
      return;
    }
    // save data when change data
    if (this.isChangedData) {
      this.dialogService.showDialog(
        DialogConfirmComponent,
        {
          data: {
            text: `Do you want to save changes before changing template?`,
            button1: 'Yes',
            button2: 'No',
            title: 'Confirmation'
          }
        },
        result => {
          if (result) {
            this.saveStyleDetailsForStyle(false);
            const sub = this.saveDataSuccess.subscribe(isSuccess => {
              sub.unsubscribe();
              if (isSuccess) {
                this.styleSelected['isChangedStyleDetail'] = false;
                this.changeTemplate(style, displayType);
              }
            });
          } else {
            this.styleSelected['isChangedStyleDetail'] = true;
            this.isChangedData = false;
            this.changeTemplate();
          }
        }
      );
    } else {
      let stylesSelected;
      if (style) {
        stylesSelected = [style];
      } else {
        stylesSelected = this.styles.filter(style => style.isChecked);
      }
      if (stylesSelected.length == 0) {
        this.dialogService.showDialog(DialogMessageComponent, { data: { title: 'Error', text: 'Please select a style.' } });
        return;
      } else {
        const idMainPageDisplay1 = Helper.getIdTemplateByType(stylesSelected, Constant.DISPLAY_1, DestinationEnum.MAIN);
        const idSubPage1Display1 = Helper.getIdTemplateByType(stylesSelected, Constant.DISPLAY_1, DestinationEnum.SUB_PAGE_1);
        const idSubPage2Display1 = Helper.getIdTemplateByType(stylesSelected, Constant.DISPLAY_1, DestinationEnum.SUB_PAGE_2);
        const idSubPage3Display1 = Helper.getIdTemplateByType(stylesSelected, Constant.DISPLAY_1, DestinationEnum.SUB_PAGE_3);
        const idSubPage4Display1 = Helper.getIdTemplateByType(stylesSelected, Constant.DISPLAY_1, DestinationEnum.SUB_PAGE_4);

        const displayTemplate1 = idMainPageDisplay1
          ? new DisplayTemplate(idMainPageDisplay1, idSubPage1Display1, idSubPage2Display1, idSubPage3Display1, idSubPage4Display1)
          : new DisplayTemplate();

        const idMainPageDisplay2 = Helper.getIdTemplateByType(stylesSelected, Constant.DISPLAY_2, DestinationEnum.MAIN);
        const idSubPage1Display2 = Helper.getIdTemplateByType(stylesSelected, Constant.DISPLAY_2, DestinationEnum.SUB_PAGE_1);
        const idSubPage2Display2 = Helper.getIdTemplateByType(stylesSelected, Constant.DISPLAY_2, DestinationEnum.SUB_PAGE_2);
        const idSubPage3Display2 = Helper.getIdTemplateByType(stylesSelected, Constant.DISPLAY_2, DestinationEnum.SUB_PAGE_3);
        const idSubPage4Display2 = Helper.getIdTemplateByType(stylesSelected, Constant.DISPLAY_2, DestinationEnum.SUB_PAGE_4);

        const displayTemplate2 = idMainPageDisplay2
          ? new DisplayTemplate(idMainPageDisplay2, idSubPage1Display2, idSubPage2Display2, idSubPage3Display2, idSubPage4Display2)
          : new DisplayTemplate();
        let idsStyleChecked = stylesSelected.map(style => style.id);
        this.externalContentOfStyle = [];
        this.templateService.getSameExternalContent(idsStyleChecked, this.isDisplay2, false, displayType).subscribe(data => {
          if (data) {
            this.externalContentOfStyle = data;
          }
          this.isChangedData = true;
          if (!style) {
            this.dialogService.showDialog(
              DialogChangeTemplateComponent,
              {
                data: this.isDisplay2
                  ? {
                      screen: ScreenNameEnum.SIGNAGE_DISPLAY,
                      displayTemplate1: displayTemplate1,
                      displayTemplate2: displayTemplate2,
                      isDisplay2: this.isDisplay2,
                      dataExternalContent: this.externalContentOfStyle
                    }
                  : {
                      screen: ScreenNameEnum.SIGNAGE_DISPLAY,
                      displayTemplate1: displayTemplate1,
                      isDisplay2: this.isDisplay2,
                      dataExternalContent: this.externalContentOfStyle
                    }
              },
              result => {
                this.isChangedData = false;
                if (result) {
                  if (!this.isDisplay2) {
                    stylesSelected.forEach(style => {
                      style.displayTemplate1 = result;
                    });
                  } else {
                    stylesSelected.forEach(style => {
                      style.displayTemplate1 = result[0];
                      style.displayTemplate2 = result[1];
                    });
                  }
                  this.saveChangeTemplateForStyles(stylesSelected);
                } else {
                  this.setValueForIsChangedData();
                }
              }
            );
          } else {
            let isDisplay2 = displayType == DisplaysEnum.DISPLAY_2;
            let display = isDisplay2 ? displayTemplate2 : displayTemplate1;
            let externalContent = _.cloneDeep(this.externalContentOfStyle);
            if (isDisplay2) {
              externalContent['display1'] = externalContent['display2'];
            }
            this.changeTemplateForDisplay(display, style, isDisplay2, externalContent);
          }
        });
      }
    }
  }

  /**
   * change template for display
   * @param displayTemplate
   * @param style
   * @param isDisplay2
   * @param externalContentOfStyle
   */
  private changeTemplateForDisplay(displayTemplate: DisplayTemplate, style: Style, isDisplay2: boolean, externalContentOfStyle: any) {
    this.dialogService.showDialog(
      DialogChangeTemplateComponent,
      {
        data: {
          screen: ScreenNameEnum.SIGNAGE_DISPLAY,
          displayTemplate1: displayTemplate,
          isDisplay2: false,
          dataExternalContent: externalContentOfStyle,
          isChangeInlineDisplay2: isDisplay2
        }
      },
      result => {
        this.isChangedData = false;
        if (result) {
          if (!isDisplay2) {
            style.displayTemplate1 = result;
          } else {
            style.displayTemplate2 = result;
          }
          this.saveChangeTemplateForStyle(style);
        } else {
          this.setValueForIsChangedData();
        }
      }
    );
  }

  /**
   * get buttons preview
   * @param displayTemplate
   */
  getButtonsPreview(displayTemplate: DisplayTemplate): Array<{ key: DestinationEnum; value: string }> {
    if (!displayTemplate) {
      return;
    }
    let buttonsPreview = new Array<{ key: DestinationEnum; value: string }>();
    if (displayTemplate.idMainPage) {
      buttonsPreview.push({ key: DestinationEnum.MAIN, value: 'Main' });
    }
    if (displayTemplate.idSubPage1) {
      buttonsPreview.push({ key: DestinationEnum.SUB_PAGE_1, value: 'Sub1' });
    }
    if (displayTemplate.idSubPage2) {
      buttonsPreview.push({ key: DestinationEnum.SUB_PAGE_2, value: 'Sub2' });
    }
    if (displayTemplate.idSubPage3) {
      buttonsPreview.push({ key: DestinationEnum.SUB_PAGE_3, value: 'Sub3' });
    }
    if (displayTemplate.idSubPage4) {
      buttonsPreview.push({ key: DestinationEnum.SUB_PAGE_4, value: 'Sub4' });
    }
    return buttonsPreview;
  }

  /**
   * save change template for style
   * @param style
   */
  saveChangeTemplateForStyle(style: Style): void {
    this.styleService.updateTemplatesForStyle(Helper.convertDataStyleBackward(style)).subscribe(
      styleData => {
        const styleOutput = Helper.convertDataStyle(styleData);
        const index = this.styles.findIndex(item => item.id == styleOutput.id);
        if (index != -1) {
          this.styles[index] = styleOutput;
        }
        this.setValueForIsChangedData();
        this.isCheckedAll = false;
        if (this.styleSelected?.id != this.styles[index]?.id) {
          return;
        }
        this.selectStyle(this.styles[index], null, true);
      },
      error => {
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: `Error`,
            text: `An error has occurred. Please try again.`
          }
        });
      }
    );
  }

  /**
   * save change template for styles
   * @param styles
   */
  saveChangeTemplateForStyles(styles: Array<Style>): void {
    let stylesChanged = styles.map(style => Helper.convertDataStyleBackward(style));
    this.styleService.updateTemplatesForStyles(stylesChanged).subscribe(
      styleDatas => {
        let stylesOutput = Helper.convertDataStyles(styleDatas);
        this.setValueForIsChangedData();
        stylesOutput.forEach(style => {
          let index = this.styles.findIndex(styleData => styleData.id == style.id);
          if (index != -1) {
            this.styles[index] = style;
          }
          if (this.styleSelected?.id != this.styles[index]?.id) {
            return;
          }
          this.selectStyle(this.styles[index], null, true);
        });
        this.isCheckedAll = this.styles.every(style => style.isChecked);
      },
      error => {
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: `Error`,
            text: `An error has occurred. Please try again.`
          }
        });
      }
    );
  }

  /**
   * draw display template
   * @param canvasDisplayId string (canvasDisplay1 or canvasDisplay2)
   * @param display Template
   * @param canvasDisplay
   */
  drawDisplay(canvasDisplayId: string, canvasDisplay, display: Template): void {
    this.changeDetectorRef.detectChanges();
    const canvasDisplayNode = document.getElementById(canvasDisplayId);
    // clear node child
    Helper.clearNodeChild(canvasDisplayNode);
    // clear all thread draw
    if (canvasDisplayId == Constant.CANVAS_DISPLAY_1_ID) {
      this.subscribesGetDataExternal[DisplaysEnum.DISPLAY_1]?.unsubscribe();
      this.subscribesGetUrlPresignedDisplay1.forEach(sub => sub.unsubscribe());
      this.clearAllIntervalDrawsNews(this.intervalsDrawNewsDisplay1);
      this.drawSignageService.clearAllThreadDrawTemplate(
        _.get(this.styleSelected?.display1Templates, `[${this.templateSelectedTypeDisplay1}]`, undefined),
        Constant.CANVAS_DISPLAY_1_ID
      );
    } else {
      this.subscribesGetDataExternal[DisplaysEnum.DISPLAY_2]?.unsubscribe();
      this.subscribesGetUrlPresignedDisplay2.forEach(sub => sub.unsubscribe());
      this.clearAllIntervalDrawsNews(this.intervalsDrawNewsDisplay2);
      this.drawSignageService.clearAllThreadDrawTemplate(
        _.get(this.styleSelected?.display2Templates, `[${this.templateSelectedTypeDisplay2}]`, undefined),
        Constant.CANVAS_DISPLAY_2_ID
      );
    }
    if (!display) {
      return;
    }
    if (canvasDisplayNode) {
      // draw
      Helper.createCanvasTemplate(display, canvasDisplay, this.renderer);
      this.drawSignageService.createAllCanvasAreaTemplate(display, canvasDisplay, this.renderer);
      const divDisplay =
        this.styleSelected?.display1Templates && this.styleSelected.display1Templates[this.templateSelectedTypeDisplay1]
          ? document.getElementById(this.DIV_DISPLAY_1)
          : document.getElementById(this.DIV_DISPLAY_2);
      // scale canvas display
      this.calculateScaleTransformCanvas(canvasDisplay, display, divDisplay);
      this.drawSignageService.setupPreview(this.styleSelected, this.mediaSetting ?? undefined);
      this.drawSignageService.drawPreviewSignage(display, this.renderer, canvasDisplayId);
    }
    if (this.isPlay) {
      this.drawDisplayDestinationIndex(display, canvasDisplayId);
    }
    let destination = (display.templateType as unknown) as DestinationEnum;
    this.getFullDataExternalContentToPreview(canvasDisplayId, this.getDataExternalSetting(destination, canvasDisplayId), destination);
  }

  /**
   * get data external content of style
   * @param destinationEnum type of template
   * @param canvasDisplayId id area draw
   * @returns data external content of style
   */
  private getDataExternalSetting(destinationEnum: DestinationEnum, canvasDisplayId: string): DataExternalSetting[] {
    if (canvasDisplayId == Constant.CANVAS_DISPLAY_1_ID) {
      switch (destinationEnum) {
        case DestinationEnum.MAIN:
          return this.styleSelected.displayTemplate1.externalContentMainPage;
        case DestinationEnum.SUB_PAGE_1:
          return this.styleSelected.displayTemplate1.externalContentPage1;
        case DestinationEnum.SUB_PAGE_2:
          return this.styleSelected.displayTemplate1.externalContentPage2;
        case DestinationEnum.SUB_PAGE_3:
          return this.styleSelected.displayTemplate1.externalContentPage3;
        case DestinationEnum.SUB_PAGE_4:
          return this.styleSelected.displayTemplate1.externalContentPage4;
        default:
          return null;
      }
    } else {
      switch (destinationEnum) {
        case DestinationEnum.MAIN:
          return this.styleSelected.displayTemplate2.externalContentMainPage;
        case DestinationEnum.SUB_PAGE_1:
          return this.styleSelected.displayTemplate2.externalContentPage1;
        case DestinationEnum.SUB_PAGE_2:
          return this.styleSelected.displayTemplate2.externalContentPage2;
        case DestinationEnum.SUB_PAGE_3:
          return this.styleSelected.displayTemplate2.externalContentPage3;
        case DestinationEnum.SUB_PAGE_4:
          return this.styleSelected.displayTemplate2.externalContentPage4;
        default:
          return null;
      }
    }
  }

  /**
   * calculate scale transform canvas
   * @param canvasDisplayNode
   * @param display Template object
   * @param divDisplay
   */
  private calculateScaleTransformCanvas(canvasDisplay, display: Template, divDisplay): number {
    if (!divDisplay || !display) {
      return;
    }
    this.changeDetectorRef.detectChanges();
    let divInfoHeight =
      this.styleSelected?.display1Templates && this.styleSelected.display1Templates[this.templateSelectedTypeDisplay1]
        ? document.getElementById('div-information-display-1')
        : document.getElementById('div-information-display-2');
    let boxHeight =
      this.styleSelected?.display1Templates && this.styleSelected.display1Templates[this.templateSelectedTypeDisplay1]
        ? document.getElementById('signage-div-canvas-display-1')
        : document.getElementById('signage-div-canvas-display-2');

    let divDisplay1Height = divDisplay.clientHeight - divInfoHeight?.clientHeight - boxHeight?.clientHeight;
    let divDisplay1Width = divDisplay.clientWidth;
    let scaleTransform = { scaleX: 1, scaleY: 1 };
    if (display.width > divDisplay1Width) {
      scaleTransform.scaleX = divDisplay1Width / display.width;
    }
    if (display.height > divDisplay1Height) {
      scaleTransform.scaleY = divDisplay1Height / display.height;
    }
    let scale = Math.min(scaleTransform.scaleX, scaleTransform.scaleY);
    this.renderer.setStyle(canvasDisplay.nativeElement, 'transform', 'scale(' + scale + ')');
    canvasDisplay.nativeElement.style.left = (divDisplay1Width - display.width * scale) / 2 + 'px';
    canvasDisplay.nativeElement.style.top = (divDisplay1Height - display.height * scale) / 2 + 'px';
    return scale;
  }

  /**
   * change state preview
   */
  changeStatePreview(): void {
    if (this.styleSelected?.isEdit) {
      return;
    }
    this.isPlay = !this.isPlay;
    if (this.isPlay) {
      this.startPreviewSubject.next();
    } else {
      this.pausePreviewSubject.next();
    }
    this.dataService.sendData([this.IS_PREVIEW_ON, this.isPlay]);
    this.changeDetectorRef.detectChanges();
    if (this.isPlay) {
      if (this.isChangedData) {
        this.previewTemplate(true);
      } else {
        this.drawDisplayDestinationIndex(
          _.get(this.styleSelected?.display1Templates, `[${this.templateSelectedTypeDisplay1}]`, undefined),
          Constant.CANVAS_DISPLAY_1_ID
        );
        this.drawDisplayDestinationIndex(
          _.get(this.styleSelected?.display2Templates, `[${this.templateSelectedTypeDisplay2}]`, undefined),
          Constant.CANVAS_DISPLAY_2_ID
        );
      }
    } else {
      // clear time out
      this.clearTimeoutDisplay(this.timeoutsDisplay1);
      this.clearTimeoutDisplay(this.timeoutsDisplay2);
    }
    this.drawSignageService.changeStatePlayPause(this.isPlay, Constant.CANVAS_DISPLAY_1_ID);
    this.drawSignageService.changeStatePlayPause(this.isPlay, Constant.CANVAS_DISPLAY_2_ID);
  }

  /**
   * clear all time out
   * @param timeouts
   */
  private clearTimeoutDisplay(timeouts: any[]): void {
    for (let i = 0; i < timeouts.length; i++) {
      clearTimeout(timeouts[i]);
    }
    timeouts = [];
  }

  /**
   * handle event click area on canvas
   * @param canvasDisplay
   * @param canvasDisplayId
   * @param displayTemplates
   * @param destinationDisplayData
   */
  private handleEventClickAreaOnCanvas(
    canvasDisplay: any,
    canvasDisplayId: string,
    displayTemplates: Template[],
    destinationDisplayData: DestinationEnum
  ): void {
    if (displayTemplates && !displayTemplates[destinationDisplayData]) {
      return;
    }
    this.drawDisplay(canvasDisplayId, canvasDisplay, displayTemplates[destinationDisplayData]);
    if (canvasDisplayId == Constant.CANVAS_DISPLAY_1_ID) {
      this.templateSelectedTypeDisplay1 = destinationDisplayData;
    } else {
      this.templateSelectedTypeDisplay2 = destinationDisplayData;
    }
    this.drawSignageService.changeStatePlayPause(this.isPlay, canvasDisplayId);
  }

  /**
   * draw display destination index (isAutoTransition)
   * @param display Template object
   * @param canvasDisplayId
   */
  drawDisplayDestinationIndex(display: Template, canvasDisplayId): void {
    if (!display?.isAutoTransition) {
      return;
    }
    let isCanvasDisplay1: boolean = canvasDisplayId == Constant.CANVAS_DISPLAY_1_ID;
    let destinationIndex = display.destination;
    let timeout = setTimeout(() => {
      let display1Template = _.get(this.styleSelected?.display1Templates, `[${destinationIndex}]`, undefined);
      let display2Template = _.get(this.styleSelected?.display2Templates, `[${destinationIndex}]`, undefined);
      if ((isCanvasDisplay1 && display1Template) || (!isCanvasDisplay1 && display2Template)) {
        if (isCanvasDisplay1) {
          // clear interval and time out list
          this.drawSignageService.clearAllThreadDrawTemplate(
            _.get(this.styleSelected?.display1Templates, `[${this.templateSelectedTypeDisplay1}]`, undefined),
            Constant.CANVAS_DISPLAY_1_ID
          );
        } else {
          this.drawSignageService.clearAllThreadDrawTemplate(
            _.get(this.styleSelected?.display2Templates, `[${this.templateSelectedTypeDisplay2}]`, undefined),
            Constant.CANVAS_DISPLAY_2_ID
          );
        }
        // draw display
        this.drawDisplay(
          isCanvasDisplay1 ? Constant.CANVAS_DISPLAY_1_ID : Constant.CANVAS_DISPLAY_2_ID,
          isCanvasDisplay1 ? this.canvasDisplay1 : this.canvasDisplay2,
          isCanvasDisplay1 ? display1Template : display2Template
        );
        this.templateSelectedTypeDisplay1 = isCanvasDisplay1 ? destinationIndex : this.templateSelectedTypeDisplay1;
        this.templateSelectedTypeDisplay2 = !isCanvasDisplay1 ? destinationIndex : this.templateSelectedTypeDisplay2;
        this.drawSignageService.changeStatePlayPause(this.isPlay, canvasDisplayId);
      }
    }, display.transitionTime * 1000);
    if (isCanvasDisplay1) {
      this.timeoutsDisplay1.push(timeout);
    } else {
      this.timeoutsDisplay2.push(timeout);
    }
  }

  /**
   * switch show horizontal/vertical
   */
  switchShowHorizontalVertical(): void {
    this.isShowVertical = !this.isShowVertical;
    this.changeDetectorRef.detectChanges();

    this.controllerDisplay1.abort();
    this.controllerDisplay2.abort();
    this.clearAllIntervalDrawsNews(this.intervalsDrawNewsDisplay1);
    this.clearAllIntervalDrawsNews(this.intervalsDrawNewsDisplay2);
    this.subscribesGetUrlPresignedDisplay1.forEach(subscription => subscription?.unsubscribe());
    this.subscribesGetUrlPresignedDisplay2.forEach(subscription => subscription?.unsubscribe());
    this.subscribesGetDataExternal.forEach(subscription => subscription?.unsubscribe());

    if (!this.isPlay) {
      // draw canvas
      this.previewTemplate();
    } else {
      this.templateSelectedTypeDisplay1 = DestinationEnum.MAIN;
      this.templateSelectedTypeDisplay2 = DestinationEnum.MAIN;
      // draw main template
      this.previewTemplate(true);
    }
  }

  /**
   * publish data
   */
  publishData(): void {
    if (this.isPlay || this.styleSelected?.isEdit) {
      return;
    }
    var styleSelecteds = this.styles.filter(style => style.isChecked);
    if (styleSelecteds.length == 0) {
      this.dialogService.showDialog(DialogMessageComponent, { data: { title: 'Error', text: 'Please select style.' } });
      return;
    }
    // handle data null
    styleSelecteds.forEach(style => {
      if (!style?.displayTemplate1) {
        style.displayTemplate1 = new DisplayTemplate(null);
      }
      if (!style?.displayTemplate2) {
        style.displayTemplate2 = new DisplayTemplate(null);
      }
    });
    // show message error
    if (styleSelecteds.findIndex(style => !style.displayTemplate1.idMainPage && !style.displayTemplate2.idMainPage) != -1) {
      this.dialogService.showDialog(DialogMessageComponent, { data: { title: 'Error', text: 'There is a style with no template set.' } });
      return;
    }
    // confirm save data when change data
    if (this.isChangedData) {
      this.dialogService.showDialog(
        DialogConfirmComponent,
        {
          data: {
            text: `Do you want to save changes and publish data?`,
            button1: 'Yes',
            button2: 'Cancel',
            title: 'Confirmation'
          }
        },
        result => {
          if (result) {
            this.saveStyleDetailsForStyle();
            const sub = this.saveDataSuccess.subscribe(isSuccess => {
              sub.unsubscribe();
              if (isSuccess) {
                this.isChangedData = false;
                this.publishData();
              }
            });
          }
        }
      );
    } else {
      this.isChangedData = true;
      // show dialog publish
      this.dialogService.showDialog(
        DialogPublishDataSignageDisplayComponent,
        {
          data: {
            styleSelecteds: styleSelecteds
          }
        },
        result => {
          this.isChangedData = false;
        }
      );
    }
  }

  /**
   * setting
   */
  public setting(): void {
    const isChangedDataOld = this.isChangedData;
    if (this.isPlay || this.styleSelected?.isEdit) {
      return;
    }
    // get setting signage channel by project's id
    this.settingSignageChannelService.getSettingSignageChannelByType(SettingType.SIGNAGE).subscribe(settingSignageChannel => {
      this.isChangedData = true;
      this.dialogService.showDialog(
        DialogSettingSignageDisplayComponent,
        {
          data: {
            settingSignageChannel: <SettingSignageChannel>settingSignageChannel ?? new SettingSignageChannel(),
            type: SettingType.SIGNAGE
          }
        },
        result => {
          this.isChangedData = isChangedDataOld;
          if (result) {
            let data = result;
            if (!data || (!data.folderId && !data.mediaId)) {
              this.mediaSetting = undefined;
              // clear canvas display 1
              this.drawSignageService.clearCanvasAreas(
                _.get(this.styleSelected?.display1Templates, `[${this.templateSelectedTypeDisplay1}]`, undefined),
                Constant.CANVAS_DISPLAY_1_ID
              );
              // clear canvas display 2
              if (this.isDisplay2) {
                this.drawSignageService.clearCanvasAreas(
                  _.get(this.styleSelected?.display2Templates, `[${this.templateSelectedTypeDisplay2}]`, undefined),
                  Constant.CANVAS_DISPLAY_2_ID
                );
              }
              return;
            }
            // get media by id
            this.simpleMediaService.getMediaById(data.mediaId).subscribe(
              async mediaData => {
                if (!mediaData) {
                  this.mediaSetting = undefined;
                  return;
                }
                this.mediaSetting = Helper.convertSimpleMediaToMedia(Helper.convertDataSimpleMedia(mediaData, false));
                this.drawSignageService.setupPreview(this.styleSelected, this.mediaSetting ?? undefined);
                // draw area is set(signage channel) display 1
                this.drawSignageService.drawAreasSignageChannel(
                  _.get(this.styleSelected?.display1Templates, `[${this.templateSelectedTypeDisplay1}]`, undefined),
                  this.renderer,
                  Constant.CANVAS_DISPLAY_1_ID
                );
                // draw area is set(signage channel) display 2
                if (this.isDisplay2) {
                  this.drawSignageService.drawAreasSignageChannel(
                    _.get(this.styleSelected?.display2Templates, `[${this.templateSelectedTypeDisplay2}]`, undefined),
                    this.renderer,
                    Constant.CANVAS_DISPLAY_2_ID
                  );
                }
              },
              error => this.handleError(error)
            );
          }
        }
      );
    });
  }

  /**
   * handle error
   * @param error
   */
  private handleError(error: any) {
    this.dialogService.showDialog(DialogMessageComponent, {
      data: {
        title: 'Error',
        text: error.status == Constant.NETWORK_ERROR_CODE ? 'Network error.' : 'An error has occurred. Please try again.'
      }
    });
  }
}
