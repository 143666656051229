import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Common } from 'app/model/entity/common';
import { FolderMedia } from 'app/model/entity/folder-media';
import { SaveMediaManagerStateAction } from 'app/ngrx-component-state-management/component-state.action';
import { CommonService } from 'app/service/common.service';
import { DataService } from 'app/service/data.service';
import { DrawService } from 'app/service/draw.service';
import { FolderMediaService } from 'app/service/folder-media.service';
import { SendDataService } from 'app/service/send-data.service';
import { AppState } from 'app/store/app.state';
import _ from 'lodash';
import moment from 'moment';
import { interval, Subject, Subscription } from 'rxjs';
import { fromFetch } from 'rxjs/fetch';
import { repeatWhen, switchMap, takeUntil } from 'rxjs/operators';
import { Helper } from '../../common/helper';
import { Constant, FIELD_COMPONENT, MODULE_NAME, ObjectFitEnum, TypeMediaFileEnum } from '../../config/constants';
import { DialogAddSequenceComponent } from '../../dialog/dialog-add-sequence/dialog-add-sequence.component';
import { DialogConfirmComponent } from '../../dialog/dialog-confirm/dialog-confirm.component';
import { DialogMessageComponent } from '../../dialog/dialog-message/dialog-message.component';
import { Image } from '../../model/entity/image';
import { Media } from '../../model/entity/media';
import { Sequence } from '../../model/entity/sequence';
import { Sound } from '../../model/entity/sound';
import { Text } from '../../model/entity/text';
import { Video } from '../../model/entity/video';
import { DialogService } from '../../service/dialog.service';
import { MediaService } from '../../service/media.service';
import { MenuActionService } from '../../service/menu-action.service';

@Component({
  selector: 'app-media-manager',
  templateUrl: './media-manager.component.html',
  styleUrls: ['./media-manager.component.scss']
})
export class MediaManagerComponent implements OnInit, OnDestroy {
  /**
   * constants
   */
  readonly DISABLE_MENU_VALUE = 'disableMenu';
  readonly DISABLE_MENU_DELETE = 'disableMenuDelete';
  readonly RESIZE_SEQ = ['Fill', 'Cover', 'Contain'];
  readonly START_TIME_DEFAULT = '00:00:00';
  readonly MAX_TIME_SEQUENCE = 10800;
  readonly MAX_LENGTH_CONTENT_FILE = 256;

  private keyCodeMap = {
    17: false, // Ctrl
    18: false, // Alt
    65: false // A
  };

  private readonly key = {
    Ctrl: 17,
    Alt: 18,
    A: 65
  };
  /**
   * save data success
   */
  @Output() saveDataSuccess: EventEmitter<boolean> = new EventEmitter<boolean>();
  /**
   * TypeMediaFileEnum
   */
  TypeMediaFileEnum = TypeMediaFileEnum;
  Helper = Helper;
  /**
   * ElementRef mediaName
   */
  @ViewChild('mediaName') private elementRefName: ElementRef;
  /**
   * ElementRef mediaOwner
   */
  @ViewChild('mediaOwner') private elementRefOwner: ElementRef;
  /**
   * ElementRef mediaDescription
   */
  @ViewChild('mediaDescription') private elementRefDescription: ElementRef;
  /**
   * ElementRef mediaDescription
   */
  @ViewChild('mediaText') private elementRefText: ElementRef;
  /**
   * search input
   */
  @ViewChild('searchInput') searchInput: ElementRef;
  /**
   * folder list element
   */
  @ViewChild('folderList') folderListElement: ElementRef;
  /**
   * list folder media
   */
  folders: Array<FolderMedia>;
  /**
   * folder selected
   */
  folderSelected: FolderMedia;
  /**
   * media selected
   */
  mediaSelected: Media;
  /**
   * true if create sequence
   */
  isCreateSequence: boolean = false;
  /**
   * true if medias[length - 1]
   */
  isActiveNextButton: boolean;
  /**
   * true if medias[0]
   */
  isActivePreButton: boolean;
  /**
   * list media of sequence
   */
  mediaOfSequences: Array<Media>;
  /**
   * speed original sound/video
   */
  speedMedia: number = 1;
  /**
   * list of action subscriptions
   */
  subscriptions: Array<Subscription> = new Array<Subscription>();
  /**
   * type media default
   */
  mediaTypeFilter: TypeMediaFileEnum;
  /**
   * old media type value
   */
  mediaTypeFilterOld: TypeMediaFileEnum;
  /**
   * true if edit media info
   */
  isEditMedia: boolean = false;
  /**
   * true if add or edit
   */
  isChangedData: boolean = false;
  /**
   * media name when edit
   */
  mediaNameEdit: string = '';
  /**
   * media owner when edit
   */
  mediaOwnerEdit: string = '';
  /**
   * media text when edit
   */
  mediaTextEdit: string = '';
  /**
   * media description when edit
   */
  mediaDescriptionEdit: string = '';
  /**
   * width div time line
   */
  readonly WIDTH = 420;
  /**
   * total duration of sequence
   */
  totalDurationOfSequence: string;
  /**
   * selected media of sequence
   */
  mediaOfSequenceSelected: Media;
  /**
   * true when play sequence
   */
  isPlaySequence: boolean;
  /**
   * Constant
   */
  Constant = Constant;
  /**
   * list media to show
   */
  medias: Array<Media>;
  /**
   * list media origin
   */
  mediasOrigin: Array<Media>;
  /**
   * type medias
   */
  mediaTypesCurrent: Array<TypeMediaFileEnum>;
  /**
   * current user
   */
  userId: Number;
  /**
   *  true when pointing on sequence maker area
   */
  isShowPlaceholder: boolean;
  /**
   * Contains objects to save sequence
   */
  sequenceSave: Sequence = new Sequence();
  /**
   * true if edit sequence
   */
  isEditSequence: boolean = true;
  /**
   * true if play media
   */
  isPlayMedia: boolean;
  /**
   * true if choose tab preview
   */
  isActiveTabPreview: boolean;
  /**
   * true if choose tab properties
   */
  isActiveTabProperties: boolean = true;
  /**
   * value of search input
   */
  searchInputValue: string = '';
  /**
   * medias selected (delete media)
   */
  mediasSelected: Array<Media>;
  /**
   * true if open station content folder
   */
  isOpenStationContentFolder: boolean;
  /**
   * medias read from json file
   */
  mediasRead: Array<Media>;
  /**
   * folder contain sequence
   */
  folderContainSequence: FolderMedia;
  /**
   * name media is sequence selected origin
   */
  lastSelectedSequence: Media;
  mediaListOfSequence: Array<Media>;
  /**
   * true if tab clip mode is showed and vice versa
   */
  isShowClipMode: boolean = false;
  /**
   * true if media is loading, false if the media has finished loading
   */
  public isLoadingMediaOnCanvas: boolean = false;
  /**
   * resume preview sequence subject
   */
  private readonly resumePreviewSequence = new Subject();
  /**
   * pause preview sequence subject
   */
  private readonly pausePreviewSequence = new Subject();
  /**
   * cancel fetch request subject
   */
  private readonly cancelFetchRequest = new Subject();
  /**
   * preview sequence subscription
   */
  private previewSequenceSubscription: Subscription;
  /**
   * video on canvas object
   */
  private videoOnCanvasObject: VideoOnCanvasObject;
  /**
   * video on canvas object
   */
  private audioOnCanvasObject: AudioObject;
  /**
   * id interval
   */
  idInterval: any;

  /**
   * value cursor point mode sequence
   */
  valueCursorPoint: number = 0;
  /**
   * value time mode sequence
   */
  valueTime: string = this.START_TIME_DEFAULT;

  /**
   * value total time mode sequence
   */
  valueTotalTime: string = this.START_TIME_DEFAULT;
  /**
   * value cursor point save old value
   */
  valueCursorPointOld: number = 0;
  /**
   * time IN second
   */
  timeInSecond: number = 0;
  /**
   * time OUT second
   */
  timeOutSecond: number = 0;
  /**
   * time Duration second
   */
  timeDurationSecond: number = 0;
  /**
   * time cursor point time line
   */
  timeCursorPointSecond: number = 0;
  /**
   * position left: green triangular position left
   */
  positionLeft: number = 3;
  /**
   * position left: green triangular position right
   */
  positionRight: number = 100;
  /**
   * time IN format hh:mm:ss
   */
  timeIn = new TimeSequence('00', '00', '00');
  /**
   * time OUT format hh:mm:ss
   */
  timeOut: TimeSequence;

  /**
   * max time mode sequence
   */
  maxTime: number;
  /**
   * list point number
   */
  pointListNumber: Array<number>;
  /**
   * list point percent
   */
  pointListPercent: Array<string>;
  /**
   * audio object
   */
  audioObject = new AudioObject();
  /**
   * video Element
   */
  video: HTMLVideoElement;

  /**
   * canvas's width
   */
  widthCanvasPreviewSequence: number;

  /**
   * canvas's height
   */
  heightCanvasPreviewSequence: number;

  /**
   * isInvalid
   */
  isInvalid: boolean = false;

  /**
   * canvasPreviewMedia
   */
  @ViewChild('canvasPreviewSequence', { static: false })
  /**
   * canvasPreviewSequence
   */
  canvasPreviewSequence: ElementRef;
  /**
   * idCustomInputTimeDuration
   */
  public readonly idCustomInputTimeDuration = 'timeDuration';
  /**
   * idCustomInputTimeIn
   */
  public readonly idCustomInputTimeIn = 'timeIn';
  /**
   * idCustomInputTimeOut
   */
  public readonly idCustomInputTimeOut = 'timeOut';
  /**
   * idCustomInputTimeCursorPoint
   */
  public readonly idCustomInputTimeCursorPoint = 'timeCursorPoint';
  /**
   * inputElement
   */
  private inputElement: any;

  /**
   * Common object
   */
  private commonObject: Common;

  /**
   * is focus input time
   */
  isFocusInputTime: boolean;
  /**
   * state of component
   */
  stateOfComponent: {
    isChangeLayout: boolean;
    folders: FolderMedia[];
    folderSelected: FolderMedia;
    mediaSelected: Media;
    isCreateSequence: boolean;
    isActiveNextButton: boolean;
    isActivePreButton: boolean;
    mediaOfSequences: Media[];
    mediaTypeFilter: any;
    isEditMedia: boolean;
    mediaNameEdit: string;
    mediaOwnerEdit: string;
    mediaTextEdit: string;
    mediaDescriptionEdit: string;
    totalDurationOfSequence: string;
    mediaOfSequenceSelected: Media;
    medias: Media[];
    mediasOrigin: Media[];
    isShowPlaceholder: boolean;
    isActiveTabPreview: boolean;
    isActiveTabProperties: boolean;
    searchInputValue: string;
    isOpenStationContentFolder: boolean;
    mediaTypesCurrent: Array<TypeMediaFileEnum>;
    isChangedData: boolean;
    lastSelectedSequence: Media;
    mediaTypeFilterOld: any;
    speedMedia: number;
    userId: Number;
    sequenceSave: Sequence;
    isEditSequence: boolean;
    mediasSelected: Array<Media>;
    mediasRead: Array<Media>;
    folderContainSequence: FolderMedia;
    mediaListOfSequence: Array<Media>;
    isShowClipMode: boolean;
    timeCursorPointSecond: number;
    valueTotalTime: string;
    timeInSecond: number;
    timeOutSecond: number;
    timeDurationSecond: number;
    timeIn: TimeSequence;
    timeOut: TimeSequence;
    maxTime: number;
    pointListNumber: Array<number>;
    pointListPercent: Array<string>;
    widthCanvasPreviewSequence: number;
    heightCanvasPreviewSequence: number;
    canvasPreviewSequence: ElementRef;
    isFocusInputTime: boolean;
  };

  /**
   * Constructor
   * @param projectService service for handling project data
   * @param mediaService service for handling media data
   * @param actionService service for handling menu action
   * @param dragDropService service for handling drag drop
   */
  constructor(
    private dialogService: DialogService,
    private el: ElementRef,
    private folderMediaService: FolderMediaService,
    private mediaService: MediaService,
    private actionService: MenuActionService,
    public readonly store: Store<AppState>,
    private dataService: DataService,
    private sendDataService: SendDataService,
    private drawService: DrawService,
    private changeDetectorRef: ChangeDetectorRef,
    private commonService: CommonService,
    private translateService: TranslateService
  ) {
    this.subscriptions.push(
      this.actionService.actionSave.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.MediaManagerComponent]) {
          if (this.isEditMedia) {
            this.saveChangeMedia();
          } else if (this.isCreateSequence) {
            this.saveSequence();
          }
        }
      })
    );
    this.subscriptions.push(
      this.actionService.actionAddSequence.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.MediaManagerComponent]) {
          this.addNewPlaySequence();
        }
      })
    );
    this.subscriptions.push(
      this.actionService.actionImportMedia.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.MediaManagerComponent]) {
          this.importMedia();
        }
      })
    );
    this.subscriptions.push(
      this.actionService.actionEditSequence.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.MediaManagerComponent]) {
          this.editSequence();
        }
      })
    );
    this.subscriptions.push(
      this.actionService.actionEdit.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.MediaManagerComponent]) {
          this.editMedia();
        }
      })
    );
    this.subscriptions.push(
      this.actionService.actionDelete.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.MediaManagerComponent]) {
          this.deleteMedia();
        }
      })
    );
    this.subscriptions.push(
      this.store
        .select(state => state)
        .subscribe((componentState: any) => {
          this.stateOfComponent = {
            isChangeLayout: componentState.mediaManagerState?.stateOfComponent.isChangeLayout,
            folders: componentState.mediaManagerState?.stateOfComponent.folders,
            folderSelected: componentState.mediaManagerState?.stateOfComponent.folderSelected,
            mediaSelected: componentState.mediaManagerState?.stateOfComponent.mediaSelected,
            isCreateSequence: componentState.mediaManagerState?.stateOfComponent.isCreateSequence,
            isActiveNextButton: componentState.mediaManagerState?.stateOfComponent.isActiveNextButton,
            isActivePreButton: componentState.mediaManagerState?.stateOfComponent.isActivePreButton,
            mediaOfSequences: componentState.mediaManagerState?.stateOfComponent.mediaOfSequences,
            mediaTypeFilter: componentState.mediaManagerState?.stateOfComponent.mediaTypeFilter,
            isEditMedia: componentState.mediaManagerState?.stateOfComponent.isEditMedia,
            mediaNameEdit: componentState.mediaManagerState?.stateOfComponent.mediaNameEdit,
            mediaOwnerEdit: componentState.mediaManagerState?.stateOfComponent.mediaOwnerEdit,
            mediaTextEdit: componentState.mediaManagerState?.stateOfComponent.mediaTextEdit,
            mediaDescriptionEdit: componentState.mediaManagerState?.stateOfComponent.mediaDescriptionEdit,
            totalDurationOfSequence: componentState.mediaManagerState?.stateOfComponent.totalDurationOfSequence,
            mediaOfSequenceSelected: componentState.mediaManagerState?.stateOfComponent.mediaOfSequenceSelected,
            medias: componentState.mediaManagerState?.stateOfComponent.medias,
            mediasOrigin: componentState.mediaManagerState?.stateOfComponent.mediasOrigin,
            isShowPlaceholder: componentState.mediaManagerState?.stateOfComponent.isShowPlaceholder,
            isActiveTabPreview: componentState.mediaManagerState?.stateOfComponent.isActiveTabPreview,
            isActiveTabProperties: componentState.mediaManagerState?.stateOfComponent.isActiveTabProperties,
            searchInputValue: componentState.mediaManagerState?.stateOfComponent.searchInputValue,
            isOpenStationContentFolder: componentState.mediaManagerState?.stateOfComponent.isOpenStationContentFolder,
            mediaTypesCurrent: componentState.mediaManagerState?.stateOfComponent.mediaTypesCurrent,
            isChangedData: componentState.mediaManagerState?.stateOfComponent.isChangedData,
            lastSelectedSequence: componentState.mediaManagerState?.stateOfComponent.lastSelectedSequence,
            mediaTypeFilterOld: componentState.mediaManagerState?.stateOfComponent.mediaTypeFilterOld,
            speedMedia: componentState.mediaManagerState?.stateOfComponent.speedMedia,
            userId: componentState.mediaManagerState?.stateOfComponent.userId,
            sequenceSave: componentState.mediaManagerState?.stateOfComponent.sequenceSave,
            isEditSequence: componentState.mediaManagerState?.stateOfComponent.isEditSequence,
            mediasSelected: componentState.mediaManagerState?.stateOfComponent.mediasSelected,
            mediasRead: componentState.mediaManagerState?.stateOfComponent.mediasRead,
            folderContainSequence: componentState.mediaManagerState?.stateOfComponent.folderContainSequence,
            mediaListOfSequence: componentState.mediaManagerState?.stateOfComponent.mediaListOfSequence,
            isShowClipMode: componentState.mediaManagerState?.stateOfComponent.isShowClipMode,
            timeCursorPointSecond: componentState.mediaManagerState?.stateOfComponent.timeCursorPointSecond,
            valueTotalTime: componentState.mediaManagerState?.stateOfComponent.valueTotalTime,
            timeInSecond: componentState.mediaManagerState?.stateOfComponent.timeInSecond,
            timeOutSecond: componentState.mediaManagerState?.stateOfComponent.timeOutSecond,
            timeDurationSecond: componentState.mediaManagerState?.stateOfComponent.timeDurationSecond,
            timeIn: componentState.mediaManagerState?.stateOfComponent.timeIn,
            timeOut: componentState.mediaManagerState?.stateOfComponent.timeOut,
            maxTime: componentState.mediaManagerState?.stateOfComponent.maxTime,
            pointListNumber: componentState.mediaManagerState?.stateOfComponent.pointListNumber,
            pointListPercent: componentState.mediaManagerState?.stateOfComponent.pointListPercent,
            widthCanvasPreviewSequence: componentState.mediaManagerState?.stateOfComponent.widthCanvasPreviewSequence,
            heightCanvasPreviewSequence: componentState.mediaManagerState?.stateOfComponent.heightCanvasPreviewSequence,
            canvasPreviewSequence: componentState.mediaManagerState?.stateOfComponent.canvasPreviewSequence,
            isFocusInputTime: componentState.mediaManagerState?.stateOfComponent.isFocusInputTime
          };
        })
    );

    this.sendDataService.currentData.subscribe(data => {
      if (data.object.key != Constant.FOCUS_INPUT_TIME) {
        return;
      }
      if (data.object.isFocus) {
        this.isFocusInputTime = true;
      } else {
        this.isFocusInputTime = false;
      }
    });
    this.commonObject = this.commonService.getCommonObject();
  }

  ngOnInit(): void {
    this.userId = this.commonObject?.userId;
    if (!this.stateOfComponent?.isChangeLayout) {
      this.isActiveTabPreview = !!this.commonObject?.isActiveTabPreview;
      this.isActiveTabProperties = !this.isActiveTabPreview;
      this.getListFolderMedia();
    } else {
      this.handleAfterChangeLayout();
    }
    this.resumePreviewSequence.subscribe(() => {
      this.isLoadingMediaOnCanvas = false;
    });
    this.pausePreviewSequence.subscribe(() => {
      this.audioOnCanvasObject?.audioHtmlElement?.pause();
      this.videoOnCanvasObject?.videoHtmlElement?.pause();
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.stopPlayMedia();
    this.store.dispatch(
      new SaveMediaManagerStateAction({
        isChangeLayout: true,
        folders: this.folders,
        folderSelected: this.folderSelected,
        mediaSelected: this.mediaSelected,
        isCreateSequence: this.isCreateSequence,
        isActiveNextButton: this.isActiveNextButton,
        isActivePreButton: this.isActivePreButton,
        mediaOfSequences: this.mediaOfSequences,
        mediaTypeFilter: this.mediaTypeFilter,
        isEditMedia: this.isEditMedia,
        mediaNameEdit: this.mediaNameEdit,
        mediaOwnerEdit: this.mediaOwnerEdit,
        mediaTextEdit: this.mediaTextEdit,
        mediaDescriptionEdit: this.mediaDescriptionEdit,
        totalDurationOfSequence: this.totalDurationOfSequence,
        mediaOfSequenceSelected: this.mediaOfSequenceSelected,
        medias: this.medias,
        mediasOrigin: this.mediasOrigin,
        isShowPlaceholder: this.isShowPlaceholder,
        isActiveTabPreview: this.isActiveTabPreview,
        isActiveTabProperties: this.isActiveTabProperties,
        searchInputValue: this.searchInputValue,
        isOpenStationContentFolder: this.isOpenStationContentFolder,
        mediaTypesCurrent: this.mediaTypesCurrent,
        isChangedData: this.isChangedData,
        lastSelectedSequence: this.lastSelectedSequence,
        mediaTypeFilterOld: this.mediaTypeFilterOld,
        speedMedia: this.speedMedia,
        userId: this.userId,
        sequenceSave: this.sequenceSave,
        isEditSequence: this.isEditSequence,
        mediasSelected: this.mediasSelected,
        mediasRead: this.mediasRead,
        folderContainSequence: this.folderContainSequence,
        mediaListOfSequence: this.mediaListOfSequence,
        isShowClipMode: this.isShowClipMode,
        timeCursorPointSecond: this.timeCursorPointSecond,
        valueTotalTime: this.valueTotalTime,
        timeInSecond: this.timeInSecond,
        timeOutSecond: this.timeOutSecond,
        timeDurationSecond: this.timeDurationSecond,
        timeIn: this.timeIn,
        timeOut: this.timeOut,
        maxTime: this.maxTime,
        pointListNumber: this.pointListNumber,
        pointListPercent: this.pointListPercent,
        widthCanvasPreviewSequence: this.widthCanvasPreviewSequence,
        heightCanvasPreviewSequence: this.heightCanvasPreviewSequence,
        canvasPreviewSequence: this.canvasPreviewSequence,
        isFocusInputTime: this.isFocusInputTime
      })
    );
    delete this.commonObject.isActiveTabPreview;
    clearInterval(this.idInterval);
  }

  /**
   * handle after change layout
   */
  private handleAfterChangeLayout() {
    this.folders = this.stateOfComponent.folders;
    this.folderSelected = this.stateOfComponent.folderSelected;
    this.mediaSelected = this.stateOfComponent.mediaSelected;
    this.isCreateSequence = this.stateOfComponent.isCreateSequence;
    this.isActiveNextButton = this.stateOfComponent.isActiveNextButton;
    this.isActivePreButton = this.stateOfComponent.isActivePreButton;
    this.mediaOfSequences = this.stateOfComponent.mediaOfSequences;
    this.mediaTypeFilter = this.stateOfComponent.mediaTypeFilter;
    this.isEditMedia = this.stateOfComponent.isEditMedia;
    this.mediaNameEdit = this.stateOfComponent.mediaNameEdit;
    this.mediaOwnerEdit = this.stateOfComponent.mediaOwnerEdit;
    this.mediaTextEdit = this.stateOfComponent.mediaTextEdit;
    this.mediaDescriptionEdit = this.stateOfComponent.mediaDescriptionEdit;
    this.totalDurationOfSequence = this.stateOfComponent.totalDurationOfSequence;
    this.mediaOfSequenceSelected = this.stateOfComponent.mediaOfSequenceSelected;
    this.medias = this.stateOfComponent.medias;
    this.mediasOrigin = this.stateOfComponent.mediasOrigin;
    this.isShowPlaceholder = this.stateOfComponent.isShowPlaceholder;
    this.isActiveTabPreview = this.stateOfComponent.isActiveTabPreview;
    this.isActiveTabProperties = this.stateOfComponent.isActiveTabProperties;
    this.searchInputValue = this.stateOfComponent.searchInputValue;
    this.isOpenStationContentFolder = this.stateOfComponent.isOpenStationContentFolder;
    this.mediaTypesCurrent = this.stateOfComponent.mediaTypesCurrent;
    this.isChangedData = this.stateOfComponent.isChangedData;
    this.lastSelectedSequence = this.stateOfComponent.lastSelectedSequence;
    this.mediaTypeFilterOld = this.stateOfComponent.mediaTypeFilterOld;
    this.speedMedia = this.stateOfComponent.speedMedia;
    this.userId = this.stateOfComponent.userId;
    this.sequenceSave = this.stateOfComponent.sequenceSave;

    this.isEditSequence = this.stateOfComponent.isEditSequence;
    this.mediasSelected = this.stateOfComponent.mediasSelected;
    this.mediasRead = this.stateOfComponent.mediasRead;
    this.folderContainSequence = this.stateOfComponent.folderContainSequence;
    this.mediaListOfSequence = this.stateOfComponent.mediaListOfSequence;
    this.isShowClipMode = this.stateOfComponent.isShowClipMode;
    this.timeCursorPointSecond = this.stateOfComponent.timeCursorPointSecond;
    this.valueTotalTime = this.stateOfComponent.valueTotalTime;
    this.timeInSecond = this.stateOfComponent.timeInSecond;
    this.timeOutSecond = this.stateOfComponent.timeOutSecond;
    this.timeDurationSecond = this.stateOfComponent.timeDurationSecond;
    this.timeIn = this.stateOfComponent.timeIn;
    this.timeOut = this.stateOfComponent.timeOut;
    this.maxTime = this.stateOfComponent.maxTime;
    this.pointListNumber = this.stateOfComponent.pointListNumber;
    this.pointListPercent = this.stateOfComponent.pointListPercent;
    this.widthCanvasPreviewSequence = this.stateOfComponent.widthCanvasPreviewSequence;
    this.heightCanvasPreviewSequence = this.stateOfComponent.heightCanvasPreviewSequence;
    this.canvasPreviewSequence = this.stateOfComponent.canvasPreviewSequence;
    this.dataService.sendData([this.DISABLE_MENU_VALUE, this.isCreateSequence]);
    this.changeDetectorRef.detectChanges();
    if ((this.isCreateSequence || Helper.isSequence(this.mediaSelected)) && this.isActiveTabPreview && this.mediaOfSequenceSelected) {
      if (this.isShowClipMode) {
        this.valueCursorPoint = 0;
      } else {
        this.valueCursorPoint = Helper.convertDuration(this.mediaOfSequenceSelected.startTime);
      }
      this.drawSequence();
    }
    this.isFocusInputTime = this.stateOfComponent.isFocusInputTime;
    this.commonObject.isActiveTabPreview = this.isActiveTabPreview;
    Helper.saveMainStateAction(this.store, this.commonObject);
  }

  /**
   * edit sequence
   */
  async editSequence() {
    // if not select a folder -> show error
    if (!this.folderSelected) {
      this.dialogService.showDialog(DialogMessageComponent, { data: { title: 'Error', text: 'Please choose folder media.' } });
      return;
    }
    if (this.isPlaySequence || this.isCreateSequence || !this.isEditSequence) {
      return;
    }
    this.dataService.sendData([this.DISABLE_MENU_VALUE, true]);
    // if not select a sequence -> show error
    if (!this.mediaSelected || !Helper.isSequence(this.mediaSelected)) {
      this.dialogService.showDialog(DialogMessageComponent, { data: { title: 'Error', text: 'Please choose a sequence file.' } });
      this.dataService.sendData([this.DISABLE_MENU_VALUE, false]);
      return;
    }
    this.isEditSequence = true;
    this.chooseTab('Properties');
    this.mediaOfSequences = new Array<Media>();
    this.lastSelectedSequence = this.mediaSelected;
    this.sequenceSave.name = this.mediaSelected.name;
    this.sequenceSave.width = this.mediaSelected.getMedia().width;
    this.sequenceSave.height = this.mediaSelected.getMedia().height;
    this.sequenceSave.objectFit = this.mediaSelected.getMedia().objectFit;
    this.sequenceSave.mediaNameEncode = this.mediaSelected.mediaNameEncode;
    this.folderContainSequence = _.cloneDeep(this.folderSelected);
    this.isCreateSequence = true;
    this.isChangedData = true;
    this.mediaOfSequences = Helper.convertDataMediasData(this.mediaListOfSequence).filter(media => media);
    if (this.mediaOfSequences.length > 0) {
      this.mediaOfSequences.forEach((media, index) => {
        if (Helper.isVideo(media)) {
          media.durationDisplay = Constant.DEPENDS_ON_FILE;
          media.getMedia().in = media.getMedia().in;
          media.getMedia().out = media.getMedia().out;
        } else if (Helper.isSound(media)) {
          media.durationDisplay = media.getMedia().duration;
        } else if (Helper.isImage(media)) {
          media.durationDisplay = this.mediasRead[index][Constant.FIELD_DURATION];
        }
        if (this.mediasRead[index]?.validFrom) {
          media.validFrom = this.mediasRead[index][Constant.FIELD_VALID_FROM].split('T')[0];
        }
        if (this.mediasRead[index]?.validTo) {
          media.validTo = this.mediasRead[index][Constant.FIELD_VALID_TO].split('T')[0];
        }
      });
      this.handleDuration();
      this.calculatorPointList();
      this.selectMediaOfSequence(this.mediaOfSequences[0]);
    }
  }

  /**
   * delete media
   */
  deleteMedia() {
    // if not select a folder -> show error
    if (!this.folderSelected) {
      this.dialogService.showDialog(DialogMessageComponent, { data: { title: 'Error', text: 'Please choose folder media.' } });
      return;
    }
    if (this.isPlaySequence) {
      return;
    }
    if (this.isCreateSequence) {
      this.deleteMediaOfSequence();
    } else {
      this.deleteMediaOfFolder();
    }
  }

  /**
   * close sequence maker
   */
  quitSequence() {
    if (this.isInvalid) {
      return;
    }
    this.mediaSelected = undefined;
    this.pauseAllPreviewAtSequenceMode();
    this.resetPreviewToDefault();
    this.isShowClipMode = false;
    this.mediaOfSequenceSelected = undefined;
    this.mediaListOfSequence = [];
    this.mediaOfSequences = undefined;
    this.isCreateSequence = false;
    this.isChangedData = false;
    this.dataService.sendData([this.DISABLE_MENU_VALUE, this.isCreateSequence]);
    this.dataService.sendData([this.DISABLE_MENU_DELETE, false]);
    this.selectMedia(this.medias[0]);
    this.isActiveTabProperties = true;
    this.isActiveTabPreview = false;
    this.commonObject.isActiveTabPreview = this.isActiveTabPreview;
    Helper.saveMainStateAction(this.store, this.commonObject);
  }

  /**
   * add new play sequence
   */
  addNewPlaySequence() {
    if (this.isPlayMedia) {
      this.pauseMedia();
    }
    this.isPlaySequence = false;
    this.dataService.sendData([Constant.IS_PLAY_MEDIA, this.isPlaySequence]);
    // if not select a folder -> show error
    if (!this.folderSelected) {
      this.dialogService.showDialog(DialogMessageComponent, { data: { title: 'Error', text: 'Please choose folder media.' } });
      return;
    }
    // if show sequence maker -> return
    if (this.isCreateSequence) {
      return;
    }
    this.folderContainSequence = _.cloneDeep(this.folderSelected);
    this.isChangedData = true;
    if (this.mediasOrigin.length == 0) {
      this.mediasOrigin = new Array<Media>();
    }
    let sequenceNew = new Sequence();
    this.dialogService.showDialog(
      DialogAddSequenceComponent,
      {
        data: {
          title: 'Add new play sequence',
          sequence: sequenceNew,
          mediaList: this.mediasOrigin
        }
      },
      result => {
        this.isChangedData = false;
        if (result) {
          this.sequenceSave = result;
          this.sequenceSave.folderS3 = this.folderSelected;
          this.mediaService.addSequence(this.sequenceSave).subscribe(data => {
            if (data.id == Constant.ERROR_CODE_MAX_LENGTH_NAME) {
              this.dialogService.showDialog(DialogMessageComponent, {
                data: {
                  title: 'Error',
                  text: 'Media name must contain no more than 16 characters.'
                }
              });
              return;
            }
            this.dataService.sendData([this.DISABLE_MENU_VALUE, true]);
            this.lastSelectedSequence = Helper.convertMediaData(data);
            this.sequenceSave = Helper.convertMediaData(data);
            if (this.mediaTypeFilter == TypeMediaFileEnum.ALL || this.mediaTypeFilter == TypeMediaFileEnum.SEQ) {
              this.medias.push(Helper.convertMediaData(data));
            }
            this.mediasOrigin.push(Helper.convertMediaData(data));
            this.isEditSequence = true;
            this.mediaOfSequences = new Array<Media>();
            this.isChangedData = true;
            const valueInput = this.searchInput.nativeElement.value;
            if (valueInput) {
              this.searchMediaByName(valueInput);
            }
            this.selectMedia(this.medias[this.medias.length - 1]);
            this.isCreateSequence = true;
          });
        }
      }
    );
  }

  /**
   * get medias of folder
   */
  fetchMediasOfFolder() {
    this.mediaService.getMediasByFolderId(this.folderSelected.id).subscribe(
      data => {
        this.mediasOrigin = Helper.convertDataMediasData(data).filter(media => media);
        this.medias = _.cloneDeep(this.mediasOrigin);
        this.setMediaTypesCurrentToFilter();
        if (this.medias.length > 0) {
          this.selectMedia(this.medias[0]);
        } else {
          this.mediaSelected = undefined;
        }
      },
      error => {
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: `Error`,
            text: `An error has occurred. Please try again.`
          }
        });
      }
    );
  }

  /**
   * reset state media before select folder
   */
  resetStateMedia() {
    this.mediaTypeFilter = TypeMediaFileEnum.ALL;
    this.mediaTypeFilterOld = this.mediaTypeFilter;
    if (this.isPlaySequence) {
      return;
    }
    if (!this.folderSelected || this.medias.length == 0) {
      this.mediaSelected = undefined;
    }
    if (this.isPlayMedia) {
      this.isPlayMedia = false;
      this.dataService.sendData([Constant.IS_PLAY_MEDIA, this.isPlayMedia]);
    }
  }

  /**
   * selected Folder
   * @param folder Object Folder
   */
  selectFolder(folder: FolderMedia) {
    this.resetStateMedia();
    if (this.isEditMedia) {
      this.dialogService.showDialog(
        DialogConfirmComponent,
        {
          data: {
            text: 'Do you want to save changes?',
            button1: 'Save',
            button2: 'Cancel',
            title: 'Confirmation'
          }
        },
        result => {
          if (result) {
            this.saveChangeMedia();
            const sub = this.saveDataSuccess.subscribe(isSuccess => {
              sub.unsubscribe();
              if (isSuccess) {
                this.isEditMedia = false;
                this.isChangedData = false;
                this.mediaSelected = undefined;
                this.selectFolder(folder);
              }
            });
          }
        }
      );
    } else {
      if (this.isCreateSequence) {
        if (this.isShowClipMode) {
          this.resetPreviewToDefault();
        } else {
          this.pauseAllPreviewAtSequenceMode();
        }
      }
      this.isOpenStationContentFolder = false;
      this.dataService.sendData(['isOpenStationContentFolder', this.isOpenStationContentFolder]);
      this.searchInputValue = '';
      if (this.mediaSelected) {
        this.mediaSelected = undefined;
      }
      if (this.mediaOfSequenceSelected) {
        this.mediaOfSequenceSelected = undefined;
      }
      this.folderSelected = folder;
      this.fetchMediasOfFolder();
      if(this.isPlaySequence) {
        this.stopPlayMedia();
      }
    }
  }

  /**
   *
   * @param e
   */
  @HostListener('document:keydown', ['$event'])
  keyDown(e) {
    if (e.keyCode in this.keyCodeMap) {
      this.keyCodeMap[e.keyCode] = true;
      // select all media
      if (this.keyCodeMap[this.key.Alt] && this.keyCodeMap[this.key.Ctrl] && this.keyCodeMap[this.key.A]) {
        this.selectAllMedia();
      }
    }
  }

  @HostListener('document:keyup', ['$event'])
  keyUp(e) {
    if (e.keyCode == this.key.Alt) {
      this.keyCodeMap[this.key.Alt] = false;
    }
    if (e.keyCode == this.key.Ctrl) {
      this.keyCodeMap[this.key.Ctrl] = false;
    }
    if (e.keyCode == this.key.A) {
      this.keyCodeMap[this.key.A] = false;
    }
  }

  /**
   * select all media
   */
  selectAllMedia() {
    this.mediasSelected = new Array<Media>();
    this.medias.forEach(media => {
      media.isSelected = true;
      this.mediasSelected.push(media);
    });
  }

  /**
   * delete all media
   */
  deleteAllMedia() {
    if (this.mediasSelected.length > 0) {
      this.dialogService.showDialog(
        DialogConfirmComponent,
        {
          data: {
            text: `Do you want to delete all media?`,
            button1: 'Yes',
            button2: 'No',
            title: 'Confirmation'
          }
        },
        result => {
          if (result) {
            this.mediasSelected.forEach(mediaData => {
              this.mediaService.deleteMedia(mediaData.id).subscribe(() => {
                let index = this.medias.findIndex(media => media.id == mediaData.id);
                this.medias.splice(index, 1);
                this.mediasSelected.splice(index, 1);
                this.setMediaTypesCurrentToFilter();
                this.mediaSelected = undefined;
              });
            });
          }
        }
      );
    }
  }

  /**
   * stop play media
   */
  private stopPlayMedia(): void {
    if (this.isCreateSequence && this.isActiveTabPreview) {
      if (this.isShowClipMode && this.mediaOfSequenceSelected) {
        this.resetPreviewToDefault();
      } else {
        this.pauseAllPreviewAtSequenceMode();
        this.pausePreviewSequence.unsubscribe();
        this.cancelFetchRequest.unsubscribe();
        this.resumePreviewSequence.unsubscribe();
        this.previewSequenceSubscription?.unsubscribe();
      }
    }
    this.isPlaySequence = false;
    this.dataService.sendData([Constant.IS_PLAY_MEDIA, this.isPlaySequence]);
    this.pausePreviewSequence.next();
  }

  /**
   * selected media
   * @param media Object Media
   */
  selectMedia(media: Media) {
    if (!media || this.isPlaySequence || (this.isCreateSequence && Helper.isSequence(media))) {
      return;
    }
    if (this.isPlayMedia) {
      this.isPlayMedia = false;
      this.dataService.sendData([Constant.IS_PLAY_MEDIA, this.isPlayMedia]);
    }

    if (this.isEditMedia) {
      this.dialogService.showDialog(
        DialogConfirmComponent,
        {
          data: {
            text: 'Do you want to save changes and select another file?',
            button1: 'OK',
            button2: 'Cancel',
            title: 'Confirmation'
          }
        },
        result => {
          if (result) {
            if (this.mediaOfSequenceSelected) {
              this.mediaOfSequenceSelected = undefined;
            }
            this.saveChangeMedia(true);
            const sub = this.saveDataSuccess.subscribe(isSuccess => {
              sub.unsubscribe();
              if (isSuccess) {
                this.isEditMedia = false;
                this.isChangedData = false;
                this.mediaSelected = undefined;
                this.selectMedia(media);
              }
            });
          }
        }
      );
    } else {
      if (this.mediaOfSequenceSelected) {
        this.mediaOfSequenceSelected = undefined;
      }
      if (!this.isCreateSequence) {
        this.mediaOfSequences = [];
      }
      this.mediaSelected = media;
      this.mediaNameEdit = this.mediaSelected.name;
      this.mediaOwnerEdit = this.mediaSelected.mediaOwner;
      this.mediaDescriptionEdit = this.mediaSelected.description;
      this.isActiveNextButton = this.medias.indexOf(this.mediaSelected) == this.medias.length - 1;
      this.isActivePreButton = this.medias.indexOf(this.mediaSelected) == 0 || this.medias.length == 0;
      if (Helper.isSequence(this.mediaSelected)) {
        let mediaList = [];
        this.mediaService.getMediasOfSequence(this.mediaSelected).subscribe(sequence => {
          let sequenceObject = JSON.parse(JSON.stringify(sequence));
          this.mediaSelected.getMedia().objectFit = this.convertStringToObjectFitEnum(sequenceObject.objectFit);
          sequenceObject.medias.forEach((mediaData, index) => {
            mediaData.media = Helper.convertMediaData(mediaData.media);
            if (!mediaData.media || (Helper.isSound(mediaData.media) && !Helper.isImage(sequenceObject.medias[index - 1].media))) {
              return;
            }
            mediaData.media.validFrom = mediaData?.validFrom;
            mediaData.media.validTo = mediaData?.validTo;
            if (mediaData.media.type == TypeMediaFileEnum.MP4) {
              mediaData.media.in = mediaData.in;
              mediaData.media.out = mediaData.out;
            } else if (mediaData.media.type !== TypeMediaFileEnum.MP3 && mediaData.media.type !== TypeMediaFileEnum.WAV) {
              mediaData.media.duration = mediaData.duration;
            }
            mediaList.push(mediaData.media);
          });
          this.mediaListOfSequence = mediaList;
          this.mediasRead = Helper.convertDataMediasData(this.mediaListOfSequence).filter(media => media);
          if (!this.isCreateSequence) {
            this.mediaOfSequences = Helper.convertDataMediasData(this.mediaListOfSequence).filter(media => media);
            this.mediaOfSequences.forEach(media => {
              if (Helper.isImage(media)) {
                media.durationDisplay = media.getMedia().duration;
              }
            });
            this.handleDuration();
            this.widthCanvasPreviewSequence = this.mediaSelected.getMedia().width;
            this.heightCanvasPreviewSequence = this.mediaSelected.getMedia().height;
            this.calculateScaleTransformCanvas(this.canvasPreviewSequence.nativeElement);
            this.drawMediaTabSequence();
          }
          this.mediaSelected.mediaNameEncode = sequence.mediaNameEncode;
        });
      } else if (Helper.isText(this.mediaSelected)) {
        Helper.readContentTextFile(this.mediaSelected.url, this.mediaSelected);
        this.mediaTextEdit = this.mediaSelected.getMedia().content;
      } else if (Helper.isVideo(this.mediaSelected)) {
        (document.getElementById('video') as HTMLVideoElement)?.load();
      } else if (Helper.isSound(this.mediaSelected)) {
        (document.getElementById('audio') as HTMLAudioElement)?.load();
      }
    }
  }

  /**
   * convert string to ObjectFitEnum
   * @param objectFitValue
   */
  convertStringToObjectFitEnum(objectFitValue: string): ObjectFitEnum {
    let objectFitOutput: ObjectFitEnum;
    if (objectFitValue == 'FILL') {
      objectFitOutput = ObjectFitEnum.FILL;
    } else if (objectFitValue == 'COVER') {
      objectFitOutput = ObjectFitEnum.COVER;
    } else {
      objectFitOutput = ObjectFitEnum.CONTAIN;
    }
    return objectFitOutput;
  }

  /**
   * convert string to time
   * @param text string
   */
  convertStringToTime(text: string) {
    const textSplitList = text.split(':');
    const ss = parseInt(textSplitList[2]);
    const mm = parseInt(textSplitList[1]) * 60;
    const hh = parseInt(textSplitList[0]) * 3600;
    return hh + mm + ss;
  }

  /**
   * play sound/video
   */
  playMedia() {
    this.isPlayMedia = true;
    this.dataService.sendData([Constant.IS_PLAY_MEDIA, this.isPlayMedia]);
    if (Helper.isVideo(this.mediaSelected)) {
      let videoElement = document.getElementById('video') as HTMLVideoElement;
      videoElement.play();
      videoElement.addEventListener(
        'timeupdate',
        () => {
          videoElement.focus();
          videoElement.setAttribute('controls', 'controls');
        },
        false
      );
      videoElement.onplay = () => {
        this.isPlayMedia = true;
        this.dataService.sendData([Constant.IS_PLAY_MEDIA, this.isPlayMedia]);
      };
      videoElement.onpause = () => {
        this.isPlayMedia = false;
        this.dataService.sendData([Constant.IS_PLAY_MEDIA, this.isPlayMedia]);
      };
    } else if (Helper.isSound(this.mediaSelected)) {
      (document.getElementById('audio') as HTMLAudioElement).play();
    }
  }

  /**
   * pause sound/video
   */
  pauseMedia() {
    this.isPlayMedia = false;
    this.dataService.sendData([Constant.IS_PLAY_MEDIA, this.isPlayMedia]);
    if (Helper.isVideo(this.mediaSelected)) {
      (document.getElementById('video') as HTMLVideoElement).pause();
    }

    if (Helper.isSound(this.mediaSelected)) {
      (document.getElementById('audio') as HTMLAudioElement).pause();
    }
  }

  /**
   * play follow speed sound/video
   */
  playSpeedMedia() {
    if (this.speedMedia === 3) {
      this.speedMedia = 1;
    } else {
      this.speedMedia++;
    }
    if (Helper.isVideo(this.mediaSelected)) {
      (document.getElementById('video') as HTMLVideoElement).playbackRate = this.speedMedia;
    } else if (Helper.isSound(this.mediaSelected)) {
      (document.getElementById('audio') as HTMLAudioElement).playbackRate = this.speedMedia;
    }
  }

  /**
   * Get list folder Media by user id
   */
  getListFolderMedia() {
    const isRoot = this.commonService.getCommonObject().userIdString == Constant.ROOT;
    this.folderMediaService.getMediaFoldersByUserId(this.userId, isRoot).subscribe(
      data => {
        this.folders = Helper.convertDataFolderS3Medias(data);
        const projectName = this.commonService.getCommonObject().projectName;
        if (projectName) {
          const indexFolder = this.folders.findIndex(folder => folder.name == projectName);
          if (indexFolder >= 0) {
            setTimeout(() => {
              const element = _.get(this.folderListElement, `nativeElement.children[${indexFolder}]`, this.folderListElement);
              element.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }, 10);
            this.selectFolder(this.folders[indexFolder]);
          }
        }
      },
      () => {
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: `Error`,
            text: `An error has occurred. Please try again.`
          }
        });
      }
    );
  }

  /**
   * Search media by name
   * @param searchInput search value
   */
  searchMediaByName(searchInput: string) {
    if (this.isEditMedia) {
      this.dialogService.showDialog(
        DialogConfirmComponent,
        {
          data: {
            text: 'Do you want to save changes?',
            button1: 'OK',
            button2: 'Cancel',
            title: 'Confirmation'
          }
        },
        result => {
          if (result) {
            this.saveChangeMedia();
            const sub = this.saveDataSuccess.subscribe(isSuccess => {
              sub.unsubscribe();
              if (isSuccess) {
                this.isEditMedia = false;
                this.isChangedData = false;
                this.searchMediaByName(searchInput);
              }
            });
          }
        }
      );
    } else {
      this.filterMediaByType();
      this.searchInputValue = searchInput.trim();
      if (this.searchInputValue !== '') {
        this.medias = this.medias.filter(media => media.name.toLocaleLowerCase().includes(this.searchInputValue.toLocaleLowerCase()));
      }
      if (this.medias.length > 0) {
        this.selectMedia(this.medias[0]);
      } else {
        this.mediaSelected = undefined;
      }
    }
  }

  /**
   * Filter media by type
   *
   * @param typeMedia selected value
   */
  filterMediaByType() {
    if (this.isEditMedia) {
      this.dialogService.showDialog(
        DialogConfirmComponent,
        {
          data: {
            text: 'Do you want to save changes?',
            button1: 'OK',
            button2: 'Cancel',
            title: 'Confirmation'
          }
        },
        result => {
          if (result) {
            this.saveChangeMedia();
            const sub = this.saveDataSuccess.subscribe(isSuccess => {
              sub.unsubscribe();
              if (isSuccess) {
                this.isEditMedia = false;
                this.isChangedData = false;
                this.filterMediaByType();
              }
            });
          } else {
            this.mediaTypeFilter = this.mediaTypeFilterOld;
          }
        }
      );
    } else {
      this.mediaTypeFilterOld = this.mediaTypeFilter;
      this.searchInputValue = '';
      if (this.mediaTypeFilter == TypeMediaFileEnum.ALL) {
        this.medias = this.mediasOrigin;
      } else {
        this.medias = this.mediasOrigin.filter(media => media.type == this.mediaTypeFilter);
      }
      if (this.medias.length > 0) {
        this.selectMedia(this.medias[0]);
      } else {
        this.mediaSelected = undefined;
      }
    }
  }

  /**
   * Handle event when click button PREV
   */
  preSelectedMedia() {
    let index = this.medias.indexOf(this.mediaSelected);
    if (index == 0) {
      return;
    }
    this.selectMedia(this.medias[index - 1]);
  }

  /**
   * Handle event when click button NEXT
   */
  nextSelectedMedia() {
    let index = this.medias.indexOf(this.mediaSelected);
    if (index == this.medias.length - 1) {
      return;
    }
    this.selectMedia(this.medias[index + 1]);
  }

  /**
   * change duration
   * @param time duration change value
   * @param index index file
   */
  changeDuration(media: Media) {
    this.handleDuration();
    this.isInvalid = true;
    if (+media.durationDisplay < 1 || +media.durationDisplay > 3600) {
      this.dataService.sendData([this.DISABLE_MENU_DELETE, true]);
      this.isInvalid = true;
    } else if (!_.isInteger(+media.durationDisplay)) {
      this.isInvalid = true;
    } else {
      this.dataService.sendData([this.DISABLE_MENU_DELETE, false]);
    }
    if (this.audioObject.audioHtmlElement) {
      this.audioObject.audioHtmlElement.currentTime = 0;
    }
  }

  /**
   * Handle duration value (start time, in, out)
   */
  handleDuration() {
    var totalDuration = 0;
    this.mediaOfSequences.forEach((data, index) => {
      if (index === 0) {
        data.startTime = this.START_TIME_DEFAULT;
      }
      if (index + 1 == this.mediaOfSequences.length) {
        return;
      }
      var time;
      var startTimeNext;
      if (data instanceof Video) {
        time = Helper.convertDuration(data.getMedia().startTime ?? this.START_TIME_DEFAULT);
        startTimeNext = data.getMedia().out - data.getMedia().in + time;
        this.mediaOfSequences[index + 1].getMedia().startTime = Helper.formatDuration(startTimeNext);
      } else if (data instanceof Sound) {
        data.getMedia().startTime = this.mediaOfSequences[index - 1].getMedia().startTime;
        time = Helper.convertDuration(data.getMedia().startTime ?? this.START_TIME_DEFAULT);
        startTimeNext = +this.mediaOfSequences[index - 1].getMedia().durationDisplay + time;
        this.mediaOfSequences[index + 1].getMedia().startTime = Helper.formatDuration(startTimeNext);
      } else {
        if (this.mediaOfSequences[index + 1] instanceof Sound) {
          this.mediaOfSequences[index + 1].getMedia().startTime = data.startTime ?? this.START_TIME_DEFAULT;
        } else {
          time = Helper.convertDuration(data.getMedia().startTime ?? this.START_TIME_DEFAULT);
          startTimeNext = +data.getMedia().durationDisplay + time;
          this.mediaOfSequences[index + 1].getMedia().startTime = Helper.formatDuration(startTimeNext);
        }
      }
    });
    let mediaEnd = this.mediaOfSequences[this.mediaOfSequences.length - 1];
    if (mediaEnd instanceof Video) {
      totalDuration = Helper.convertDuration(mediaEnd.getMedia().startTime) + mediaEnd.getMedia().out - mediaEnd.getMedia().in;
    } else if (mediaEnd instanceof Sound) {
      totalDuration =
        Helper.convertDuration(mediaEnd?.getMedia().startTime) +
        +this.mediaOfSequences[this.mediaOfSequences.length - 2].getMedia().durationDisplay;
    } else {
      totalDuration =
        Helper.convertDuration(mediaEnd?.getMedia().startTime ?? this.START_TIME_DEFAULT) + +mediaEnd?.getMedia().durationDisplay;
    }
    this.totalDurationOfSequence = (isNaN(totalDuration) ? 0 : totalDuration) + '';
  }

  /**
   * Drag picture
   * @param e event
   * @param media Media object
   */
  dragPicture(e: any, media: Media) {
    var obj = JSON.stringify(media);
    e.dataTransfer.setData(Constant.IS_MEDIA_IN_STATION_CONTENT_FOLDER, this.isOpenStationContentFolder);
    e.dataTransfer.setData(Constant.IS_MEDIA_IN_LCD_LAYOUT_EDITOR, this.folderSelected.name == Constant.FOLDER_LCD_LAYOUT_EDITOR);
    e.dataTransfer.setData(Constant.FOLDER_INDEX_WORD_EDITOR, this.folderSelected.name == Constant.FOLDER_INDEX_WORD_EDITOR);
    e.dataTransfer.setData(Constant.MEDIA_VALUE, obj);
  }

  /**
   * filter list type media
   */
  setMediaTypesCurrentToFilter() {
    this.mediaTypesCurrent = [];
    this.mediaTypesCurrent.push(TypeMediaFileEnum.ALL);
    let mediaTypesCurrent = this.mediasOrigin.map(media => media.type);
    if (mediaTypesCurrent.length == 0) {
      return;
    }
    mediaTypesCurrent.forEach(type => {
      switch (type.toLowerCase()) {
        case TypeMediaFileEnum.BMP:
          this.mediaTypesCurrent.push(TypeMediaFileEnum.BMP);
          break;
        case TypeMediaFileEnum.GIF:
          this.mediaTypesCurrent.push(TypeMediaFileEnum.GIF);
          break;
        case TypeMediaFileEnum.JPG:
          this.mediaTypesCurrent.push(TypeMediaFileEnum.JPG);
          break;
        case TypeMediaFileEnum.PNG:
          this.mediaTypesCurrent.push(TypeMediaFileEnum.PNG);
          break;
        case TypeMediaFileEnum.MP3:
          this.mediaTypesCurrent.push(TypeMediaFileEnum.MP3);
          break;
        case TypeMediaFileEnum.WAV:
          this.mediaTypesCurrent.push(TypeMediaFileEnum.WAV);
          break;
        case TypeMediaFileEnum.MP4:
          this.mediaTypesCurrent.push(TypeMediaFileEnum.MP4);
          break;
        case TypeMediaFileEnum.SEQ:
          this.mediaTypesCurrent.push(TypeMediaFileEnum.SEQ);
          break;
        case TypeMediaFileEnum.TXT:
          this.mediaTypesCurrent.push(TypeMediaFileEnum.TXT);
          break;
        default:
          break;
      }
    });
  }

  /**
   * Drop media
   * @param e CdkDragDrop
   */
  dropMedia(e: CdkDragDrop<any[]>) {
    if (e.previousContainer === e.container) {
      this.dropMediaOfSequence(e);
      this.handleDuration();
      if (!this.isShowClipMode && !this.isInvalid) {
        this.calculatorPointList();
      }
      this.selectMediaOfSequence(this.mediaOfSequences[e.currentIndex], true);
    } else {
      if (this.isPlaySequence || this.isOpenStationContentFolder || this.isLoadingMediaOnCanvas) {
        return;
      }
      // convert data media
      let media = Helper.convertMediaData(e.previousContainer.data[e.previousIndex]);
      if (Helper.isSequence(media) || Helper.isText(media)) {
        this.dialogService.showDialog(DialogMessageComponent, { data: { title: 'Error', text: 'The data must be image/video/audio.' } });
        return;
      }
      if (Helper.isVideo(media)) {
        if (Helper.isImage(this.mediaOfSequences[e.currentIndex - 1]) && Helper.isSound(this.mediaOfSequences[e.currentIndex])) {
          this.dialogService.showDialog(DialogMessageComponent, { data: { title: 'Error', text: 'File cannot be dropped here.' } });
          return;
        }
        media.durationDisplay = Constant.DEPENDS_ON_FILE;
        media.getMedia().in = 0;
        media.getMedia().out = this.convertStringToTime(media.getMedia().duration);
      } else if (Helper.isImage(media)) {
        if (Helper.isImage(this.mediaOfSequences[e.currentIndex - 1]) && Helper.isSound(this.mediaOfSequences[e.currentIndex])) {
          this.dialogService.showDialog(DialogMessageComponent, { data: { title: 'Error', text: 'File cannot be dropped here.' } });
          return;
        }
        media.durationDisplay = 5;
      } else if (Helper.isSound(media)) {
        if (Helper.isImage(this.mediaOfSequences[e.currentIndex - 1])) {
          if (Helper.isSound(this.mediaOfSequences[e.currentIndex])) {
            this.dialogService.showDialog(DialogMessageComponent, { data: { title: 'Error', text: 'File cannot be dropped here.' } });
            return;
          }
        } else {
          this.dialogService.showDialog(DialogMessageComponent, { data: { title: 'Error', text: 'Sound must be attached to an Image.' } });
          return;
        }
        media.durationDisplay = media.getMedia().duration;
      }
      this.mediaOfSequences.splice(e.currentIndex, 0, media);
      this.isChangedData = true;
      this.handleDuration();
      this.selectMediaOfSequence(this.mediaOfSequences[e.currentIndex]);
      if (!this.isShowClipMode) {
        this.calculatorPointList();
      }
    }
  }

  /**
   * Drop media in area sequence maker
   * @param event CdkDragDrop
   */
  dropMediaOfSequence(event: CdkDragDrop<any[]>) {
    if (this.isPlaySequence || this.isLoadingMediaOnCanvas || this.isInvalid) {
      return;
    }
    let currentMediasOfSequence = event.container.data;
    let indexCurrent = event.currentIndex;
    let indexPrevious = event.previousIndex;
    if (Helper.isImage(currentMediasOfSequence[indexPrevious]) || Helper.isVideo(currentMediasOfSequence[indexPrevious])) {
      if (indexPrevious < indexCurrent) {
        if (Helper.isImage(currentMediasOfSequence[indexCurrent]) && Helper.isSound(currentMediasOfSequence[indexCurrent + 1])) {
          this.dialogService.showDialog(DialogMessageComponent, { data: { title: 'Error', text: 'File cannot be dropped here.' } });
          return;
        }
        if (Helper.isImage(currentMediasOfSequence[indexPrevious]) && Helper.isSound(currentMediasOfSequence[indexPrevious + 1])) {
          moveItemInArray(currentMediasOfSequence, indexPrevious, indexCurrent);
          moveItemInArray(currentMediasOfSequence, indexPrevious, indexCurrent);
        } else {
          moveItemInArray(currentMediasOfSequence, indexPrevious, indexCurrent);
        }
      } else {
        if (Helper.isImage(currentMediasOfSequence[indexCurrent - 1]) && Helper.isSound(currentMediasOfSequence[indexCurrent])) {
          this.dialogService.showDialog(DialogMessageComponent, { data: { title: 'Error', text: 'File cannot be dropped here.' } });
          return;
        }
        if (Helper.isImage(currentMediasOfSequence[indexPrevious]) && Helper.isSound(currentMediasOfSequence[indexPrevious + 1])) {
          moveItemInArray(currentMediasOfSequence, indexPrevious, indexCurrent);
          moveItemInArray(currentMediasOfSequence, indexPrevious + 1, indexCurrent + 1);
        } else {
          moveItemInArray(currentMediasOfSequence, indexPrevious, indexCurrent);
        }
      }
    } else {
      if (indexPrevious < indexCurrent) {
        if (Helper.isImage(currentMediasOfSequence[indexCurrent]) && Helper.isSound(currentMediasOfSequence[indexCurrent + 1])) {
          this.dialogService.showDialog(DialogMessageComponent, { data: { title: 'Error', text: 'File cannot be dropped here.' } });
          return;
        }
        if (
          Helper.isSound(currentMediasOfSequence[indexCurrent]) &&
          Helper.isImage(currentMediasOfSequence[indexCurrent - 1]) &&
          !Helper.isSound(currentMediasOfSequence[indexPrevious])
        ) {
          this.dialogService.showDialog(DialogMessageComponent, { data: { title: 'Error', text: 'File cannot be dropped here.' } });
          return;
        }
        if (Helper.isSound(currentMediasOfSequence[indexPrevious]) && Helper.isImage(currentMediasOfSequence[indexPrevious - 1])) {
          moveItemInArray(currentMediasOfSequence, indexPrevious, indexCurrent);
          moveItemInArray(currentMediasOfSequence, indexPrevious - 1, indexCurrent - 1);
        }
      } else {
        if (
          Helper.isImage(currentMediasOfSequence[indexCurrent - 1]) &&
          Helper.isSound(currentMediasOfSequence[indexCurrent]) &&
          !Helper.isSound(currentMediasOfSequence[indexPrevious])
        ) {
          this.dialogService.showDialog(DialogMessageComponent, { data: { title: 'Error', text: 'File cannot be dropped here.' } });
          return;
        }
        if (Helper.isSound(currentMediasOfSequence[indexCurrent]) && Helper.isImage(currentMediasOfSequence[indexCurrent + 1])) {
          this.dialogService.showDialog(DialogMessageComponent, { data: { title: 'Error', text: 'File cannot be dropped here.' } });
          return;
        }
        if (Helper.isSound(currentMediasOfSequence[indexPrevious]) && Helper.isImage(currentMediasOfSequence[indexPrevious - 1])) {
          moveItemInArray(currentMediasOfSequence, indexPrevious, indexCurrent);
          moveItemInArray(currentMediasOfSequence, indexPrevious, indexCurrent);
        }
      }
    }
  }

  /**
   * Allow Drop
   * @param e event
   */
  allowDrop(e: any) {
    if (this.isOpenStationContentFolder) {
      return;
    }
    e.preventDefault();
  }

  /**
   * select media of sequence
   * @param media Media object
   */
  selectMediaOfSequence(media: Media, isDragDropInSequence?: boolean) {
    if (this.isLoadingMediaOnCanvas && this.isActiveTabPreview) {
      return;
    }
    this.mediaOfSequenceSelected = undefined;
    if (
      !media ||
      this.isPlaySequence ||
      (this.mediaOfSequenceSelected?.startTime == media?.startTime &&
        this.mediaOfSequenceSelected?.id == media?.id &&
        !isDragDropInSequence) ||
      this.isInvalid
    ) {
      return;
    }
    if (this.isEditMedia) {
      this.dialogService.showDialog(
        DialogConfirmComponent,
        {
          data: {
            text: 'Do you want to save your changes?',
            button1: 'Save',
            button2: 'Cancel',
            title: 'Confirmation'
          }
        },
        result => {
          if (result) {
            this.saveChangeMedia();
            this.isEditMedia = false;
            this.isChangedData = false;
            this.mediaSelected = undefined;
            this.selectMediaOfSequence(media);
          }
        }
      );
    } else {
      this.drawService.clearCanvas(this.canvasPreviewSequence.nativeElement);
      if (this.mediaSelected) {
        this.mediaSelected = undefined;
      }
      this.mediaOfSequenceSelected = media;
      this.resetPreviewToDefault();
      if (this.isActiveTabPreview) {
        this.drawSequence();
      }
    }
  }

  /**
   * set time out second
   */
  private setTimeOutSecond(media: Media) {
    if (Helper.isVideo(media)) {
      this.valueTotalTime = this.mediaOfSequenceSelected.getMedia().duration;
      this.timeOutSecond = Helper.convertDuration(this.mediaOfSequenceSelected.getMedia().duration);
    } else if (Helper.isImage(media)) {
      this.valueTotalTime = Helper.formatDuration(this.mediaOfSequenceSelected.durationDisplay);
      this.timeOutSecond = +this.mediaOfSequenceSelected.durationDisplay;
    }
  }

  /**
   * check value input duration
   * @param media
   */
  checkValue(media: Media, i: number) {
    if (!_.isInteger(+media.durationDisplay)) {
      const indexMediaOfSequence = this.getIndexMediaSelected();
      media.durationDisplay = this.mediasRead[indexMediaOfSequence][Constant.FIELD_DURATION];
      this.dialogService.showDialog(DialogMessageComponent, { data: { title: 'Error', text: 'Invalid Time.' } });
      this.isInvalid = false;
      return;
    }
    const elementInput = document.getElementById(`input${i}`);
    let errorMess: string = undefined;
    if (+this.totalDurationOfSequence > this.MAX_TIME_SEQUENCE) {
      errorMess = 'Total duration must not be greater than 3h.';
    } else if (media.getMedia().durationDisplay < 1) {
      errorMess = 'Duration must be longer than or equal to 1s.';
    } else if (media.getMedia().durationDisplay > 3600) {
      errorMess = 'Duration must be shorter than or equal to 3600s.';
    }
    if (errorMess) {
      this.sendDataService.sendData({ object: { isInvalid: true } });
      this.dialogService.showDialog(DialogMessageComponent, { data: { title: 'Error', text: errorMess }, autoFocus: true }, () => {
        this.dataService.sendData([this.DISABLE_MENU_DELETE, this.isCreateSequence]);
        elementInput.focus();
      });
      return;
    } else {
      this.isInvalid = false;
    }
    if (this.isShowClipMode) {
      this.maxTime = this.mediaOfSequenceSelected?.getMedia().durationDisplay;
      this.timeCursorPointSecond = 0;
      this.valueTotalTime = Helper.formatDuration(this.maxTime);
    } else {
      this.calculatorPointList();
      this.mediaOfSequenceSelected = this.mediaOfSequences[0];
      this.drawSequence();
    }
  }

  /**
   * import media
   */
  importMedia() {
    if (!this.folderSelected) {
      this.dialogService.showDialog(DialogMessageComponent, { data: { title: 'Error', text: 'Please choose a media folder.' } });
      return;
    }
    let element = document.getElementById('importedFileMedia') as HTMLInputElement;
    element.setAttribute('accept', '.jpg, .png, .bmp, .gif, .mp4, .mp3, .wav, .txt');
    element.click();
  }

  /**
   * upload media files
   * @param event
   */
  async upload(event: any) {
    let selectedFiles: any[] = event.target.files;
    if (!selectedFiles || selectedFiles.length <= 0) {
      return;
    }
    const mediaTypesSupported = [
      TypeMediaFileEnum.BMP,
      TypeMediaFileEnum.GIF,
      TypeMediaFileEnum.JPG,
      TypeMediaFileEnum.MP3,
      TypeMediaFileEnum.MP4,
      TypeMediaFileEnum.PNG,
      TypeMediaFileEnum.SEQ,
      TypeMediaFileEnum.TXT,
      TypeMediaFileEnum.WAV
    ];
    let errorCharacters = false;
    let errorName = false;
    let errorType = false;
    let errorFile = '';
    // upload file
    for (const file of selectedFiles) {
      let fileName = file.name.slice(0, file.name.lastIndexOf('.'));
      let typeName = file.name.slice(file.name.lastIndexOf('.') + 1, file.name.length).toLowerCase();
      if (!mediaTypesSupported.includes(typeName)) {
        errorType = true;
      } else if (fileName.match(Constant.FORMAT) || fileName.includes('\\')) {
        errorCharacters = true;
      } else if (fileName.length > Constant.MAX_LENGTH_FILE_NAME) {
        errorName = true;
      } else if (typeName == TypeMediaFileEnum.TXT) {
        const errorMessage = await this.validateFile(file);
        if (errorMessage) {
          errorFile = errorMessage;
        } else {
          this.addNewMedia(file);
        }
      } else {
        this.addNewMedia(file);
      }
    }
    // reset input file value
    let element = document.getElementById('importedFileMedia') as HTMLInputElement;
    element.value = null;
    if (errorType) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: 'Error',
          text: 'This file format is not supported.'
        }
      });
      return;
    }
    if (errorCharacters) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: 'Error',
          text: 'A file name cannot contain any of the following characters: ! @ # $ % ^ & * ( ) , . ? " : { } | < > / \\'
        }
      });
      return;
    } else if (errorName) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: `Error`,
          text: `File name cannot be longer than ${Constant.MAX_LENGTH_FILE_NAME} characters.`
        }
      });
      return;
    } else if (errorFile) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: `Error`,
          text: errorFile
        }
      });
      return;
    }
  }

  /**
   * validate file
   */
  async validateFile(file: any) {
    return new Promise<string>(resolve => {
      let errorMessage = '';
      let reader = new FileReader();
      reader.readAsText(file);
      reader.onload = () => {
        let content = reader.result;
        if (content.toString().length > this.MAX_LENGTH_CONTENT_FILE) {
          errorMessage = `Script cannot be longer than file ${this.MAX_LENGTH_CONTENT_FILE} characters.`;
        } else if (content.toString().length == 0) {
          errorMessage = 'Script cannot be empty.';
        }
        resolve(errorMessage);
      };
    });
  }

  /**
   * add new media
   * @param file file
   */
  addNewMedia(file: any) {
    let index = file.name.lastIndexOf('.');
    let media: Media = this.getMediaByType(file.name.slice(index + 1).toLowerCase());
    media.name = file.name.substring(0, index);
    media.type = file.name.slice(index + 1).toLowerCase();
    this.mediaService.addMedia(file, this.folderSelected.id).subscribe(
      data => {
        if (data.id == Constant.ERROR_CODE_MAX_LENGTH_NAME) {
          this.dialogService.showDialog(DialogMessageComponent, {
            data: {
              title: 'Error',
              text: 'Media name must contain no more than 64 characters.'
            }
          });
          return;
        }
        let media: Media = Helper.convertMediaData(data);
        this.mediasOrigin.push(media);
        this.filterMediaByType();
        this.setMediaTypesCurrentToFilter();
      },
      () => {
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: `Error`,
            text: `An error has occurred. Please try again.`
          }
        });
      }
    );
  }

  /**
   * cancel edit info media
   */
  cancelEditMedia() {
    if (this.isOpenStationContentFolder) {
      return;
    }
    this.isEditSequence = true;
    this.isEditMedia = false;
    this.isChangedData = false;
    this.mediaNameEdit = this.mediaSelected.name;
    this.mediaOwnerEdit = this.mediaSelected.mediaOwner;
    if (this.mediaSelected instanceof Text) {
      this.mediaTextEdit = this.mediaSelected.content;
    }
    this.dataService.sendData([this.DISABLE_MENU_VALUE, this.isCreateSequence]);
    this.dataService.sendData([this.DISABLE_MENU_DELETE, this.isCreateSequence]);
    this.saveDataSuccess.emit(true);
  }

  /**
   * edit media
   */
  editMedia() {
    // if not select a folder -> show error
    if (!this.folderSelected) {
      this.dialogService.showDialog(DialogMessageComponent, { data: { title: 'Error', text: 'Please choose folder media.' } });
      return;
    } else if (this.medias.length == 0) {
      this.dialogService.showDialog(DialogMessageComponent, { data: { title: 'Error', text: 'Please choose a media.' } });
    }
    if (this.isCreateSequence || !this.mediaSelected) {
      return;
    }
    this.isPlaySequence = false;
    this.dataService.sendData([Constant.IS_PLAY_MEDIA, this.isPlaySequence]);
    this.pausePreviewSequence.next();
    this.isActiveTabProperties = true;
    this.isActiveTabPreview = false;
    this.commonObject.isActiveTabPreview = this.isActiveTabPreview;
    Helper.saveMainStateAction(this.store, this.commonObject);
    this.isEditMedia = true;
    this.isChangedData = true;
    this.isCreateSequence = false;
    this.isEditSequence = false;
    this.dataService.sendData([this.DISABLE_MENU_VALUE, !this.isCreateSequence]);
    this.dataService.sendData([this.DISABLE_MENU_DELETE, !this.isCreateSequence]);
  }

  /**
   * validate media name
   * @param mediaName
   * @returns
   */
  private validateMediaName(mediaName: string): boolean {
    // check empty mediaName
    if (mediaName == '') {
      this.elementRefName.nativeElement.focus();
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: `Error`,
          text: 'File name cannot be empty.'
        }
      });
      this.saveDataSuccess.emit(false);
      return false;
    }
    // check special character
    if (mediaName.match(Constant.FORMAT) || mediaName.includes('\\')) {
      this.elementRefName.nativeElement.focus();
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: 'Error',
          text: 'A file name cannot contain any of the following characters: ! @ # $ % ^ & * ( ) , . ? " : { } | < > / \\'
        }
      });
      return false;
    }
    // check max length mediaName
    if (mediaName?.length > Constant.MAX_LENGTH_FILE_NAME) {
      this.elementRefName.nativeElement.focus();
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: `Error`,
          text: `File name must contain no more than ${Constant.MAX_LENGTH_FILE_NAME} characters.`
        }
      });
      this.saveDataSuccess.emit(false);
      return false;
    }
    return true;
  }

  /**
   * validate media owner
   * @param mediaOwner
   * @returns
   */
  private validateMediaOWner(mediaOwner: string): boolean {
    const maxLengthMediaOwner = 16;
    if (mediaOwner?.length > maxLengthMediaOwner) {
      this.elementRefOwner.nativeElement.focus();
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: `Error`,
          text: `Media Owner must contain no more than ${maxLengthMediaOwner} characters.`
        }
      });
      this.saveDataSuccess.emit(false);
      return false;
    }
    return true;
  }

  /**
   * validate media description
   * @param mediaDescription
   * @returns
   */
  private validateMediaDescription(mediaDescription: string): boolean {
    if (mediaDescription?.length > this.MAX_LENGTH_CONTENT_FILE) {
      this.elementRefDescription.nativeElement.focus();
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: `Error`,
          text: `Media description must contain no more than ${this.MAX_LENGTH_CONTENT_FILE} characters.`
        }
      });
      this.saveDataSuccess.emit(false);
      return false;
    }
    return true;
  }

  /**
   * validate media text
   * @param media
   * @param mediaText
   * @returns
   */
  private validateMediaText(media: Media, mediaText: string): boolean {
    if (media instanceof Text) {
      // check empty mediaText
      if (mediaText == '') {
        this.elementRefText.nativeElement.focus();
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: `Error`,
            text: 'Media Script cannot be empty.'
          }
        });
        this.saveDataSuccess.emit(false);
        return false;
      }
      // check max length mediaText
      if (mediaText.length > this.MAX_LENGTH_CONTENT_FILE) {
        this.elementRefText.nativeElement.focus();
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: `Error`,
            text: `Media Script must contain no more than ${this.MAX_LENGTH_CONTENT_FILE} characters.`
          }
        });
        this.saveDataSuccess.emit(false);
        return false;
      }
    }
    return true;
  }

  /**
   * save info media edited
   */
  saveChangeMedia(isSelectMedia?: boolean) {
    let mediaName = this.mediaNameEdit?.trim();
    let mediaOwner = this.mediaOwnerEdit?.trim();
    let mediaDescription = this.mediaDescriptionEdit?.trim();
    let mediaText = this.mediaTextEdit;
    // validate
    if (
      !this.validateMediaName(mediaName) ||
      !this.validateMediaOWner(mediaOwner) ||
      !this.validateMediaText(this.mediaSelected, mediaText) ||
      !this.validateMediaDescription(mediaDescription)
    ) {
      return;
    }
    const oldName = this.mediaSelected.name;
    this.mediaSelected.name = mediaName;
    // check duplicate name
    this.mediaService.checkDuplicateMedia(this.mediaSelected).subscribe(
      data => {
        if (data) {
          this.mediaSelected.name = oldName;
          this.elementRefName?.nativeElement.focus();
          this.dialogService.showDialog(DialogMessageComponent, { data: { title: 'Error', text: 'File name already exists.' } });
          return;
        }
        this.mediaSelected.name = mediaName;
        this.mediaSelected.mediaOwner = mediaOwner;
        this.mediaSelected.description = mediaDescription;
        // edit media
        const isEditScript = this.mediaSelected instanceof Text && this.mediaSelected.getMedia().content !== mediaText;
        this.mediaService.editMedia(this.mediaSelected, mediaText, isEditScript).subscribe(
          media => {
            if (media.id == Constant.ERROR_CODE_MAX_LENGTH_NAME) {
              this.dialogService.showDialog(DialogMessageComponent, {
                data: {
                  title: 'Error',
                  text: 'Media name must contain no more than 64 characters.'
                }
              });
              return;
            }
            if (isSelectMedia) {
              this.saveDataSuccess.emit(true);
              return;
            }
            let mediaEdit = Helper.convertMediaData(media);
            let index = this.medias.findIndex(media => media.id == mediaEdit.id);
            if (index != -1) {
              this.medias[index] = mediaEdit;
              let indexMedia = this.mediasOrigin.findIndex(media => media.id == mediaEdit.id);
              if (indexMedia != -1) {
                this.mediasOrigin[indexMedia] = mediaEdit;
              }
              if (this.isEditMedia) {
                this.isEditMedia = false;
                this.isChangedData = false;
                this.selectMedia(this.medias[index]);
              }
            }
            this.saveDataSuccess.emit(true);
          },
          error => {
            this.dialogService.showDialog(DialogMessageComponent, {
              data: {
                title: `Error`,
                text: `An error has occurred. Please try again.`
              }
            });
            this.saveDataSuccess.emit(false);
          }
        );
        this.isEditSequence = true;
        this.dataService.sendData([this.DISABLE_MENU_VALUE, this.isCreateSequence]);
        this.dataService.sendData([this.DISABLE_MENU_DELETE, this.isCreateSequence]);
      },
      error => {
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: `Error`,
            text: `An error has occurred. Please try again.`
          }
        });
        this.saveDataSuccess.emit(false);
      }
    );
  }

  /**
   * get media by type
   * @param mediaType media type
   */
  getMediaByType(mediaType: string): Media {
    let media: Media;
    if (
      mediaType == TypeMediaFileEnum.JPG ||
      mediaType == TypeMediaFileEnum.GIF ||
      mediaType == TypeMediaFileEnum.PNG ||
      mediaType == TypeMediaFileEnum.BMP
    ) {
      media = new Image();
    } else if (mediaType == TypeMediaFileEnum.WAV || mediaType == TypeMediaFileEnum.MP3) {
      media = new Sound();
    } else if (mediaType == TypeMediaFileEnum.MP4) {
      media = new Video();
    } else if (mediaType == TypeMediaFileEnum.TXT) {
      media = new Text();
    }
    return media;
  }

  /**
   * delete media of folder
   */
  deleteMediaOfFolder() {
    if (this.medias.length == 0 || !this.mediaSelected) {
      this.dialogService.showDialog(DialogMessageComponent, { data: { title: 'Error', text: 'Please choose a media.' } });
      return;
    }
    if (this.isEditMedia || this.isOpenStationContentFolder) {
      return;
    }
    // show dialog
    this.dialogService.showDialog(
      DialogConfirmComponent,
      {
        data: {
          text: `Do you want to delete ${this.mediaSelected.name}.${this.mediaSelected.type}?`,
          button1: 'Yes',
          button2: 'No',
          title: 'Confirmation'
        }
      },
      result => {
        if (result) {
          this.mediaService.deleteMedia(this.mediaSelected.id).subscribe(
            () => {
              let selectedIndex = this.medias.findIndex(media => media.id == this.mediaSelected.id);
              let selectedIndexOrigin = this.mediasOrigin.findIndex(media => media.id == this.mediaSelected.id);
              this.mediasOrigin.splice(selectedIndexOrigin, 1);
              this.medias.splice(selectedIndex, 1);
              this.mediaSelected = undefined;
              this.setMediaTypesCurrentToFilter();
              if (this.medias.length == 0) {
                this.mediaTypeFilter = TypeMediaFileEnum.ALL;
                this.mediaTypeFilterOld = this.mediaTypeFilter;
                this.filterMediaByType();
              }
              this.selectMedia(this.medias[0]);
              this.isCreateSequence = false;
            },
            () => {
              this.dialogService.showDialog(DialogMessageComponent, {
                data: {
                  title: `Error`,
                  text: `An error has occurred. Please try again.`
                }
              });
            }
          );
        }
      }
    );
  }

  /**
   * delete media of sequence
   */
  deleteMediaOfSequence() {
    if (this.mediaOfSequences.length == 0 || !this.mediaOfSequenceSelected) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: `Error`,
          text: `Please select a media.`
        }
      });
      return;
    }
    // show dialog
    this.dialogService.showDialog(
      DialogConfirmComponent,
      {
        data: {
          text: `Do you want to delete the selected row?`,
          button1: 'Yes',
          button2: 'No',
          title: 'Confirmation'
        }
      },
      result => {
        if (result) {
          this.isChangedData = true;
          let index = this.mediaOfSequences.findIndex(media => media.randomNumber == this.mediaOfSequenceSelected.randomNumber);
          if (index != -1) {
            if (Helper.isImage(this.mediaOfSequences[index]) && Helper.isSound(this.mediaOfSequences[index + 1])) {
              this.mediaOfSequences.splice(index, 2);
            } else {
              this.mediaOfSequences.splice(index, 1);
            }
            this.handleDuration();
            this.mediaOfSequenceSelected = undefined;
            if (this.mediaOfSequences.length > 0) {
              this.selectMediaOfSequence(this.mediaOfSequences[0]);
            } else {
              this.mediaSelected = this.lastSelectedSequence;
            }
            if (!this.isShowClipMode) {
              this.calculatorPointList();
            }
          }
        }
      }
    );
  }

  /**
   * choose tab Properties / Preview
   *
   * @param tab (Properties or Preview)
   */
  chooseTab(tab: string) {
    const TAB_PROPERTIES = 'Properties';
    const TAB_PREVIEW = 'Preview';
    if (tab == TAB_PROPERTIES) {
      if (!this.isCreateSequence && Helper.isSequence(this.mediaSelected)) {
        this.pauseAllPreviewAtSequenceMode();
        this.isPlaySequence = false;
        this.dataService.sendData([Constant.IS_PLAY_MEDIA, this.isPlaySequence]);
        this.timeCursorPointSecond = 0;
        this.valueCursorPoint = 0;
        this.valueCursorPointOld = 0;
      }
      if (this.isCreateSequence && this.isActiveTabPreview) {
        if (this.isShowClipMode) {
          this.resetPreviewToDefault();
        } else {
          this.pauseAllPreviewAtSequenceMode();
        }
      }
      this.isActiveTabProperties = true;
      this.isActiveTabPreview = false;
      this.commonObject.isActiveTabPreview = this.isActiveTabPreview;
      Helper.saveMainStateAction(this.store, this.commonObject);
      this.isShowClipMode = false;
    } else if (tab == TAB_PREVIEW) {
      this.isActiveTabPreview = true;
      this.commonObject.isActiveTabPreview = this.isActiveTabPreview;
      Helper.saveMainStateAction(this.store, this.commonObject);
      this.isActiveTabProperties = false;
      this.isShowClipMode = false;
      if (!this.isCreateSequence) {
        if (Helper.isSequence(this.mediaSelected)) {
          this.drawMediaTabSequence();
        }
        return;
      } else if (!Helper.isSequence(this.mediaSelected) && this.isCreateSequence) {
        this.widthCanvasPreviewSequence = this.sequenceSave.width;
        this.heightCanvasPreviewSequence = this.sequenceSave.height;
        this.calculateScaleTransformCanvas(this.canvasPreviewSequence.nativeElement);
        this.chooseTabClip();
      }
    }
  }

  /**
   * drawSequence
   */
  drawSequence() {
    if (this.isShowClipMode) {
      this.audioObject = new AudioObject();
      this.video = undefined;
      if (Helper.isVideo(this.mediaOfSequenceSelected)) {
        this.positionLeft =
          (this.mediaOfSequenceSelected.getMedia().in / Helper.convertDuration(this.mediaOfSequenceSelected.getMedia().duration)) * 100 + 3;
        this.positionRight =
          (this.mediaOfSequenceSelected.getMedia().out / Helper.convertDuration(this.mediaOfSequenceSelected.getMedia().duration)) * 100;
        this.timeDurationSecond = this.mediaOfSequenceSelected.getMedia().out - this.mediaOfSequenceSelected.getMedia().in;
      }
      this.setTimeOutSecond(this.mediaOfSequenceSelected);
      this.maxTime = Helper.convertDuration(this.valueTotalTime);

      if (this.isActiveTabPreview) {
        const indexMediaOfSequence = this.getIndexMediaSelected();
        this.drawMediaOfSequence(this.canvasPreviewSequence.nativeElement, indexMediaOfSequence);
      }
    } else if (!this.isPlaySequence) {
      this.audioOnCanvasObject = undefined;
      this.videoOnCanvasObject = undefined;
      this.valueCursorPoint = Helper.convertDuration(this.mediaOfSequences[this.getIndexMediaSelected()]?.startTime);
      this.timeCursorPointSecond = this.valueCursorPoint;
      this.drawMediaAtStartTimeOntoCanvasAtSequenceMode(
        this.canvasPreviewSequence.nativeElement,
        this.mediaOfSequences,
        this.sequenceSave.objectFit
      );
    }
  }

  /**
   * change search input
   *
   * @param searchInput
   */
  changeSearchInput(searchInput: string) {
    this.searchInputValue = searchInput.trim();
  }

  /**
   * check validate time sequence
   */
  checkValidateTimeSequence(mediaOfSequences: Array<Media>, timeZone: string): boolean {
    return mediaOfSequences.some(media => {
      let validFromOld = undefined;
      let validToOld = undefined;
      if (Helper.isImage(media)) {
        media.getMedia().duration = media.durationDisplay;
      }
      if (media.getMedia().validFrom != Constant.EMPTY) {
        validFromOld = media.getMedia().validFrom;
        media.getMedia().validFrom += timeZone;
      }
      if (media.getMedia().validTo != Constant.EMPTY) {
        validToOld = media.getMedia().validTo;
        media.getMedia().validTo += timeZone;
      }
      if (!validToOld || !validFromOld) {
        return false;
      }
      return moment(validToOld).isBefore(moment().format(Constant.FORMAT_DATE)) || moment(validToOld).isBefore(moment(validFromOld));
    });
  }

  /**
   * save sequence
   */
  saveSequence() {
    if (this.isInvalid) {
      return;
    }
    this.mediaListOfSequence = [];
    const mediaOfSequenceClone = _.cloneDeep(this.mediaOfSequences);
    const timeZone = Constant.SUFFIX_TIME + Helper.getUserTimeZone(this.commonService.getCommonObject().setting);
    if (this.checkValidateTimeSequence(mediaOfSequenceClone, timeZone)) {
      this.saveDataSuccess.emit(false);
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: `Error`,
          text: 'Invalid date.'
        }
      });
      return;
    }
    this.sequenceSave.folderS3 = this.folderContainSequence;
    this.sequenceSave.medias = mediaOfSequenceClone;
    this.mediaService.editSequence(this.sequenceSave).subscribe(() => {
      this.isChangedData = false;
      this.isCreateSequence = false;
      this.dataService.sendData([this.DISABLE_MENU_VALUE, this.isCreateSequence]);
      this.isEditSequence = true;
      this.saveDataSuccess.emit(true);
      this.isShowClipMode = false;
      this.mediaOfSequences = undefined;
      this.pauseAllPreviewAtSequenceMode();
      this.resetPreviewToDefault();
      this.mediaListOfSequence = [];
      this.selectMedia(this.medias[0]);
      this.isActiveTabProperties = true;
      this.isActiveTabPreview = false;
      this.commonObject.isActiveTabPreview = this.isActiveTabPreview;
      Helper.saveMainStateAction(this.store, this.commonObject);
    });
  }

  /**
   * open station content folder
   */
  openStationContentFolder() {
    this.resetStateMedia();
    if (this.isEditMedia) {
      this.dialogService.showDialog(
        DialogConfirmComponent,
        {
          data: {
            text: 'Do you want to save changes?',
            button1: 'Save',
            button2: 'Cancel',
            title: 'Confirmation'
          }
        },
        result => {
          if (result) {
            this.saveChangeMedia();
            const sub = this.saveDataSuccess.subscribe(isSuccess => {
              sub.unsubscribe();
              if (isSuccess) {
                this.isEditMedia = false;
                this.isChangedData = false;
                this.mediaSelected = undefined;
                this.openStationContentFolder();
              }
            });
          }
        }
      );
    } else {
      this.isOpenStationContentFolder = true;
      this.dataService.sendData(['isOpenStationContentFolder', this.isOpenStationContentFolder]);
      this.searchInputValue = '';
      if (this.mediaSelected) {
        this.mediaSelected = undefined;
      }
      this.handleGetMediaInStationContentFolder(this.folderSelected);
    }
  }

  /**
   * handle get medias in station content folder
   * @param folder Folder object
   */
  handleGetMediaInStationContentFolder(folder: FolderMedia) {
    if (!folder) {
      return;
    }
    this.mediaService.getMediasInStationContentFolderByFolderId(folder.id).subscribe(data => {
      this.mediasOrigin = Helper.convertDataMediasData(data).filter(media => media);
      this.medias = _.cloneDeep(this.mediasOrigin);
      this.setMediaTypesCurrentToFilter();
      if (this.medias.length > 0) {
        this.selectMedia(this.medias[0]);
      } else {
        this.mediaSelected = undefined;
      }
    });
  }

  /**
   * start drag media
   * @param index
   */
  cdkDragStarted(index) {
    if (Helper.isImage(this.mediaOfSequences[index]) && Helper.isSound(this.mediaOfSequences[index + 1])) {
      this.mediaOfSequences[index + 1]['isHidden'] = true;
    } else if (Helper.isSound(this.mediaOfSequences[index]) && Helper.isImage(this.mediaOfSequences[index - 1])) {
      this.mediaOfSequences[index - 1]['isHidden'] = true;
    }
  }

  /**
   * end drag media
   * @param index
   */
  dragEnded(index) {
    if (Helper.isImage(this.mediaOfSequences[index]) && Helper.isSound(this.mediaOfSequences[index + 1])) {
      this.mediaOfSequences[index + 1]['isHidden'] = false;
    } else if (Helper.isSound(this.mediaOfSequences[index]) && Helper.isImage(this.mediaOfSequences[index - 1])) {
      this.mediaOfSequences[index - 1]['isHidden'] = false;
    }
  }

  /**
   * set value cursor point mode clip
   */
  setValueCursorPointClip(valueCursorPoint, isHandleByHand) {
    if (valueCursorPoint == 0) {
      this.timeCursorPointSecond = 0;
      return;
    }
    if (isHandleByHand && Helper.isVideo(this.mediaOfSequenceSelected)) {
      this.setScreenVideo(this.video, valueCursorPoint);
    }
    this.timeCursorPointSecond = +valueCursorPoint;
  }

  /**
   * set value cursor point sequence
   *
   * @param valueCursorPoint
   */
  setValueCursorPointSequence(valueCursorPoint) {
    this.timeCursorPointSecond = valueCursorPoint;
  }

  /**
   * validate time when handle button in and button out
   *
   * @param timeIn
   * @param timeOut
   */
  validateTimeHandleButtonIn(timeIn: number, timeOut: number): boolean {
    if (timeIn > this.MAX_TIME_SEQUENCE) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: `Error`,
          text: `Video length must not be longer than 3 hours.`
        }
      });
      return false;
    } else if (timeIn >= timeOut) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: `Error`,
          text: `Invalid IN point.`
        }
      });
      return false;
    } else if (timeIn > this.mediaOfSequenceSelected.getMedia().out) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: `Error`,
          text: `Invalid IN point.`
        }
      });
      return false;
    }
    return true;
  }

  /**
   * validate time when handle button out
   *
   * @param timeIn
   * @param timeOut
   */
  validateTimeHandleButtonOut(timeIn: number, timeOut: number): boolean {
    if (timeOut > this.MAX_TIME_SEQUENCE) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: `Error`,
          text: `Video length must not be longer than 3 hours.`
        }
      });
      return false;
    } else if (timeOut <= timeIn) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: `Error`,
          text: `Invalid OUT point.`
        }
      });
      return false;
    } else if (timeOut > this.maxTime) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: `Error`,
          text: `Invalid OUT point.`
        }
      });
      return false;
    }
    return true;
  }

  /**
   * handle button IN set value cursor point according to input value IN
   */
  handleButtonIn() {
    if (!this.validateTimeHandleButtonIn(Math.floor(this.timeInSecond), Math.floor(this.timeOutSecond))) {
      return;
    }
    this.valueCursorPoint = this.timeInSecond;
    this.timeCursorPointSecond = this.valueCursorPoint;
    this.setScreenVideo(this.video, this.valueCursorPoint);
  }

  /**
   * handle button OUT set value cursor point according to input value OUT
   */
  handleButtonOut() {
    if (!this.validateTimeHandleButtonOut(Math.floor(this.timeInSecond), Math.floor(this.timeOutSecond))) {
      return;
    }
    this.valueCursorPoint = this.timeOutSecond;
    this.timeCursorPointSecond = this.valueCursorPoint;
    this.setScreenVideo(this.video, this.valueCursorPoint);
  }

  /**
   * play preview sequence on canvas
   */
  playPreviewSequenceOnCanvas() {
    if (
      this.isLoadingMediaOnCanvas ||
      this.mediaOfSequenceSelected.getMedia().durationDisplay < 1 ||
      this.mediaOfSequenceSelected.getMedia().durationDisplay > 3600
    ) {
      return;
    }
    this.isPlaySequence = true;
    this.dataService.sendData([Constant.IS_PLAY_MEDIA, this.isPlaySequence]);
    if (this.isShowClipMode) {
      const indexMediaOfSequence = this.getIndexMediaSelected();
      if (
        (Helper.isImage(this.mediaOfSequenceSelected) &&
          Math.floor(this.valueCursorPoint) == +this.mediaOfSequenceSelected.durationDisplay) ||
        (Helper.isSound(this.mediaOfSequenceSelected) &&
          Math.floor(this.valueCursorPoint) == +this.mediaOfSequences[indexMediaOfSequence].getMedia().duration) ||
        (Helper.isVideo(this.mediaOfSequenceSelected) && Math.floor(this.valueCursorPoint) == Helper.convertDuration(this.valueTotalTime))
      ) {
        this.valueCursorPoint = 0;
        this.timeCursorPointSecond = 0;
      }
      this.playMediaSequence(this.mediaOfSequenceSelected);
    } else {
      this.playPreviewSequenceAtSequenceMode();
    }
  }

  /**
   * play media preview area sequence maker
   *
   * @param media
   */
  private playMediaSequence(media: Media) {
    if (Helper.isVideo(media)) {
      this.playVideo(this.video);
    } else {
      const indexMediaSelected = this.getIndexMediaSelected();
      if (
        (Helper.isImage(this.mediaOfSequences[indexMediaSelected]) && !Helper.isSound(this.mediaOfSequences[indexMediaSelected + 1])) ||
        this.valueCursorPoint > this.audioObject?.audioHtmlElement?.duration
      ) {
        this.fakeTimelinePreview();
      } else {
        this.playAudioSequence(this.audioObject);
      }
    }
  }

  /**
   * play video
   *
   * @param video
   */
  playVideo(video: HTMLVideoElement) {
    if (!video) {
      return;
    }
    if (video.currentTime == Helper.convertDuration(this.valueTotalTime)) {
      video.currentTime = 0;
    }
    video.addEventListener('timeupdate', () => {
      const currentTime = video.currentTime;
      this.valueCursorPoint = currentTime;
      if (video.currentTime == video.duration) {
        this.isPlaySequence = false;
        this.dataService.sendData([Constant.IS_PLAY_MEDIA, this.isPlaySequence]);
      }
      this.setValueCursorPointClip(currentTime, false);
    });
    video.play();
  }

  /**
   * pause video
   *
   * @param video
   */
  pauseVideo(video: HTMLVideoElement) {
    if (!video) {
      return;
    }
    video.pause();
  }

  /**
   * fake timeline preview
   */
  fakeTimelinePreview() {
    clearInterval(this.idInterval);
    this.idInterval = setInterval(() => {
      this.setValueCursorPointClip(++this.valueCursorPoint, false);
      if (Math.floor(this.valueCursorPoint) == +this.mediaOfSequenceSelected.getMedia().durationDisplay) {
        clearInterval(this.idInterval);
        this.isPlaySequence = false;
        this.dataService.sendData([Constant.IS_PLAY_MEDIA, this.isPlaySequence]);
      }
      this.valueCursorPointOld = _.cloneDeep(this.valueCursorPoint);
    }, 1000);
  }

  /**
   * play image and audio
   *
   * @param audioObject
   */
  private playAudioSequence(audioObject: AudioObject) {
    if (!audioObject.audioHtmlElement) {
      return;
    }
    const indexOfMediaOfSequence = this.getIndexMediaSelected();
    if (
      Math.floor(this.valueCursorPointOld) == +this.mediaOfSequences[indexOfMediaOfSequence].getMedia().durationDisplay &&
      this.isShowClipMode
    ) {
      audioObject.audioHtmlElement.currentTime = 0;
      this.valueCursorPoint = 0;
      this.valueCursorPointOld = 0;
    }
    if (
      (Helper.isImage(this.mediaOfSequences[indexOfMediaOfSequence]) &&
        Helper.isSound(this.mediaOfSequences[indexOfMediaOfSequence + 1])) ||
      (Helper.isSound(this.mediaOfSequences[indexOfMediaOfSequence]) && Helper.isImage(this.mediaOfSequences[indexOfMediaOfSequence - 1]))
    ) {
      if (
        Helper.convertDuration(this.valueTotalTime) <=
        Helper.convertDuration(this.mediaOfSequences[indexOfMediaOfSequence + 1].getMedia().duration)
      ) {
        audioObject.audioHtmlElement.currentTime = this.valueCursorPoint;
        audioObject.audioHtmlElement.addEventListener('timeupdate', () => {
          const currentTime = Math.floor(audioObject.audioHtmlElement.currentTime);
          this.timeCursorPointSecond = currentTime;
          this.valueCursorPoint = Math.floor(currentTime);
          if (
            Math.floor(audioObject.audioHtmlElement.currentTime) ==
            +this.mediaOfSequences[indexOfMediaOfSequence]?.getMedia().durationDisplay
          ) {
            audioObject.audioHtmlElement.pause();
            this.valueCursorPointOld = _.cloneDeep(this.valueCursorPoint);
            this.isPlaySequence = false;
            this.dataService.sendData([Constant.IS_PLAY_MEDIA, this.isPlaySequence]);
          }
        });
      } else {
        audioObject.audioHtmlElement.addEventListener('timeupdate', () => {
          const currentTime = Math.floor(audioObject.audioHtmlElement.currentTime);
          this.timeCursorPointSecond = currentTime;
          this.valueCursorPoint = currentTime;
          if (audioObject.audioHtmlElement.currentTime == audioObject.audioHtmlElement.duration) {
            this.fakeTimelinePreview();
          }
        });
      }
    }
    audioObject.audioHtmlElement.play();
  }

  /**
   * pause audio sequence
   *
   * @param audioObject
   */
  private pauseAudioSequence(audioObject: AudioObject) {
    if (!audioObject?.audioHtmlElement) {
      return;
    }
    audioObject.audioHtmlElement.pause();
  }

  /**
   * get index media selected of list mediaOfSequences
   */
  getIndexMediaSelected(): number {
    return this.mediaOfSequences?.findIndex(media => media?.startTime == this.mediaOfSequenceSelected?.startTime);
  }

  /**
   * pause preview sequence on canvas
   */
  pausePreviewSequenceOnCanvas() {
    if (this.isLoadingMediaOnCanvas) {
      return;
    }
    this.isPlaySequence = false;
    this.dataService.sendData([Constant.IS_PLAY_MEDIA, this.isPlaySequence]);
    if (this.isShowClipMode) {
      const indexMediaSelected = this.getIndexMediaSelected();
      if (Helper.isVideo(this.mediaOfSequences[indexMediaSelected])) {
        this.pauseVideo(this.video);
      } else if (
        Helper.isImage(this.mediaOfSequences[indexMediaSelected]) &&
        !Helper.isSound(this.mediaOfSequences[indexMediaSelected + 1])
      ) {
        clearInterval(this.idInterval);
      } else if (
        Helper.isImage(this.mediaOfSequences[indexMediaSelected]) &&
        Helper.isSound(this.mediaOfSequences[indexMediaSelected + 1])
      ) {
        if (this.valueCursorPoint > this.audioObject?.audioHtmlElement?.duration) {
          clearInterval(this.idInterval);
        } else {
          this.pauseAudioSequence(this.audioObject);
        }
      }
    } else {
      this.pausePreviewSequence.next();
    }
  }

  /**
   * draw media of sequence
   * @param
   */
  async drawMediaOfSequence(canvas: any, indexMediaOfSequence: number) {
    if (!this.mediaOfSequenceSelected) {
      return;
    }
    this.isLoadingMediaOnCanvas = true;
    if (Helper.isSound(this.mediaOfSequenceSelected)) {
      await this.drawService.drawPictureSequence(canvas, this.mediaOfSequences[indexMediaOfSequence], this.sequenceSave.objectFit);
      this.setupPreviewSoundSequence(this.mediaOfSequences[indexMediaOfSequence + 1], this.audioObject);
      this.isLoadingMediaOnCanvas = false;
    } else if (Helper.isImage(this.mediaOfSequenceSelected)) {
      await this.drawService.drawPictureSequence(canvas, this.mediaOfSequenceSelected, this.sequenceSave.objectFit);
      this.setupPreviewSoundSequence(this.mediaOfSequences[indexMediaOfSequence + 1], this.audioObject);
      this.isLoadingMediaOnCanvas = false;
    } else {
      const videoOnCanvasObject = new VideoOnCanvasObject(this.mediaOfSequenceSelected);
      this.video = this.drawService.drawVideoSequence(videoOnCanvasObject, canvas, this.sequenceSave.objectFit);
      this.video.addEventListener('canplay', () => {
        if (this.isLoadingMediaOnCanvas) {
          this.isLoadingMediaOnCanvas = false;
        }
      });
      this.timeInSecond = this.mediaOfSequenceSelected.getMedia().in;
      this.timeOutSecond = this.mediaOfSequenceSelected.getMedia().out;
      this.positionLeft = (this.timeInSecond / Helper.convertDuration(this.valueTotalTime)) * 100 + 3;
      this.positionRight = (this.timeOutSecond / Helper.convertDuration(this.valueTotalTime)) * 100;
    }
  }

  /**
   * set preview sound sequence
   */
  private setupPreviewSoundSequence(media: Media, audioObject: AudioObject) {
    if (!Helper.isSound(media)) {
      return;
    }
    audioObject.audio = media;
    const indexOfImage = this.mediaOfSequences.findIndex(data => data.id === media.id) - 1;
    audioObject.audioHtmlElement = new Audio(media.url);
    const durationImage = this.mediaOfSequences[indexOfImage]?.getMedia().durationDisplay;
    if (this.isShowClipMode) {
      this.valueTotalTime = Helper.formatDuration(durationImage);
      this.maxTime = durationImage;
    }
    const durationAudio = Helper.convertDuration(media.getMedia().duration);
    if (durationImage < durationAudio) {
      audioObject.duration = durationImage;
    } else {
      audioObject.duration = durationAudio;
    }
  }

  /**
   * clear time in
   */
  public clearTimeIn() {
    this.timeInSecond = 0;
    this.valueCursorPoint = 0;
    this.timeCursorPointSecond = 0;
    this.mediaOfSequenceSelected.getMedia().in = this.timeInSecond;
    this.setScreenVideo(this.video, 0);
    this.handleDuration();
    this.timeDurationSecond = this.timeOutSecond - this.timeInSecond;
    this.positionLeft = (this.timeInSecond / Helper.convertDuration(this.valueTotalTime)) * 100 + 3;
  }

  /**
   * clear time out
   */
  public clearTimeOut() {
    this.setTimeOutSecond(this.mediaOfSequenceSelected);
    this.valueCursorPoint = this.timeOutSecond;
    this.mediaOfSequenceSelected.getMedia().out = this.timeOutSecond;
    this.setScreenVideo(this.video, Helper.convertDuration(this.mediaOfSequenceSelected.getMedia().duration));
    this.handleDuration();
    this.timeDurationSecond = this.timeOutSecond - this.timeInSecond;
    this.positionRight = (this.timeOutSecond / Helper.convertDuration(this.valueTotalTime)) * 100;
  }

  /**
   * change time in
   *
   * @param event
   */
  public changeTimeIn(event: any) {
    if (this.isInvalid) {
      return;
    }
    this.timeInSecond = event.timeChangeValue;
    this.inputElement = event;
    if (isNaN(this.timeInSecond) || this.timeInSecond > this.maxTime || this.timeInSecond >= this.timeOutSecond) {
      this.isInvalid = true;
      this.dialogService.showDialog(
        DialogMessageComponent,
        {
          data: {
            title: `Error`,
            text: 'Invalid IN point.'
          }
        },
        () => {
          this.isInvalid = false;
          this.timeInSecond = this.mediaOfSequenceSelected.getMedia().in;
          this.sendDataService.sendData({
            object: {
              inputElement: this.inputElement,
              isFocus: true,
              time: this.timeInSecond
            }
          });
        }
      );
      return;
    } else {
      this.sendDataService.sendData({ object: { inputElement: this.inputElement, isFocus: false } });
    }
    this.timeDurationSecond = this.timeOutSecond - this.timeInSecond;
    this.positionLeft = (this.timeInSecond / Helper.convertDuration(this.valueTotalTime)) * 100 + 3;
    this.mediaOfSequenceSelected.getMedia().in = this.timeInSecond;
    this.handleDuration();
  }

  /**
   * change time out
   *
   * @param event
   */
  public changeTimeOut(event: any) {
    if (this.isInvalid) {
      return;
    }
    this.timeOutSecond = event.timeChangeValue;
    this.inputElement = event;
    if (
      isNaN(this.timeOutSecond) ||
      this.timeOutSecond == 0 ||
      this.timeOutSecond > this.maxTime ||
      this.timeOutSecond <= this.timeInSecond
    ) {
      this.isInvalid = true;
      this.dialogService.showDialog(
        DialogMessageComponent,
        {
          data: {
            title: `Error`,
            text: 'Invalid OUT point.'
          }
        },
        () => {
          this.isInvalid = false;
          this.timeOutSecond = this.mediaOfSequenceSelected.getMedia().out;
          this.sendDataService.sendData({
            object: {
              inputElement: this.inputElement,
              isFocus: true,
              time: this.timeOutSecond
            }
          });
        }
      );
      return;
    } else {
      this.sendDataService.sendData({ object: { inputElement: this.inputElement, isFocus: false } });
    }
    this.timeDurationSecond = this.timeOutSecond - this.timeInSecond;
    this.positionRight = (this.timeOutSecond / Helper.convertDuration(this.valueTotalTime)) * 100;
    this.mediaOfSequenceSelected.getMedia().out = this.timeOutSecond;
    this.handleDuration();
  }

  /**
   * change time duration
   *
   * @param event
   */
  public changeTimeDuration(event: any) {
    if (this.isInvalid) {
      return;
    }
    this.timeDurationSecond = event.timeChangeValue;
    this.inputElement = event;
    if (
      isNaN(this.timeDurationSecond) ||
      this.timeDurationSecond == 0 ||
      this.timeDurationSecond > this.maxTime ||
      this.timeDurationSecond + this.timeInSecond > this.maxTime
    ) {
      this.isInvalid = true;
      this.dialogService.showDialog(
        DialogMessageComponent,
        {
          data: {
            title: `Error`,
            text: 'Invalid Duration.'
          }
        },
        () => {
          this.isInvalid = false;
          this.timeDurationSecond = this.mediaOfSequenceSelected.getMedia().out - this.mediaOfSequenceSelected.getMedia().in;
          this.sendDataService.sendData({
            object: {
              inputElement: this.inputElement,
              isFocus: true,
              time: this.timeDurationSecond
            }
          });
        }
      );
      return;
    } else {
      this.sendDataService.sendData({
        object: { inputElement: this.inputElement, isFocus: false }
      });
    }
    this.timeOutSecond = this.timeDurationSecond + this.timeInSecond;
    this.positionRight = (this.timeOutSecond / Helper.convertDuration(this.valueTotalTime)) * 100;
    this.mediaOfSequenceSelected.getMedia().out = this.timeOutSecond;
    this.handleDuration();
  }

  /**
   * change time cursor point
   *
   * @param event
   */
  public changeTimeCursorPoint(event: any) {
    if (this.isInvalid) {
      return;
    }
    this.timeCursorPointSecond = event.timeChangeValue;
    this.inputElement = event;
    if (isNaN(this.timeCursorPointSecond) || this.timeCursorPointSecond > this.maxTime) {
      this.isInvalid = true;
      this.dialogService.showDialog(
        DialogMessageComponent,
        {
          data: {
            title: `Error`,
            text: 'Invalid Time.'
          }
        },
        () => {
          this.isInvalid = false;
          this.timeCursorPointSecond = this.valueCursorPoint;
          this.sendDataService.sendData({
            object: {
              inputElement: this.inputElement,
              isFocus: true,
              time: this.timeCursorPointSecond
            }
          });
        }
      );
      return;
    } else {
      this.sendDataService.sendData({ object: { inputElement: this.inputElement, isFocus: false } });
    }
    this.valueCursorPoint = this.timeCursorPointSecond;
    this.setScreenVideo(this.video, this.valueCursorPoint);
  }
  /**
   * set time in
   */
  public setTimeIn() {
    if (!this.validateTime(this.timeOutSecond, this.valueCursorPoint)) {
      return;
    }
    this.mediaOfSequenceSelected.getMedia().in = Math.floor(this.valueCursorPoint);
    this.handleDuration();
    this.timeInSecond = Math.floor(this.valueCursorPoint);
    this.positionLeft = (this.timeInSecond / Helper.convertDuration(this.valueTotalTime)) * 100 + 3;
    this.setScreenVideo(this.video, this.timeInSecond);
    this.timeDurationSecond = this.timeOutSecond - this.timeInSecond;
  }

  /**
   * set time out
   */
  public setTimeOut() {
    if (!this.validateTime(this.valueCursorPoint, this.timeInSecond)) {
      return;
    }
    this.mediaOfSequenceSelected.getMedia().out = Math.floor(this.valueCursorPoint);
    this.handleDuration();
    this.timeOutSecond = Math.floor(this.valueCursorPoint);
    this.positionRight = (this.timeOutSecond / Helper.convertDuration(this.valueTotalTime)) * 100;
    this.setScreenVideo(this.video, this.timeOutSecond);
    this.timeDurationSecond = this.timeOutSecond - this.timeInSecond;
  }

  /**
   * validate time when set time IN and time OUT
   *
   * @param timeFirst
   * @param timeSecond
   */
  private validateTime(timeFirst: number, timeSecond: number): boolean {
    if (Math.floor(timeFirst) <= Math.floor(timeSecond)) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: `Error`,
          text: `Invalid Time.`
        }
      });
      this.isPlaySequence = false;
      this.dataService.sendData([Constant.IS_PLAY_MEDIA, this.isPlaySequence]);
      this.pauseVideo(this.video);
      return false;
    }
    return true;
  }

  /**
   * set to display the video image at current time
   *
   * @param videoElement
   * @param currentTime
   */
  private setScreenVideo(videoElement: HTMLVideoElement, currentTime: number) {
    if (!videoElement) {
      return;
    }
    videoElement.currentTime = currentTime;
  }

  /**
   * click button reset preview sequence
   */
  public resetTime() {
    if (this.isShowClipMode) {
      this.resetPreviewToDefault();
    } else {
      if (this.isLoadingMediaOnCanvas) {
        return;
      }
      this.isPlaySequence = false;
      this.dataService.sendData([Constant.IS_PLAY_MEDIA, this.isPlaySequence]);
      // pause preview
      this.pausePreviewSequence.next();
      this.previewSequenceSubscription?.unsubscribe();
      // reset timeline preview
      this.timeCursorPointSecond = 0;
      this.valueCursorPoint = 0;
      this.audioOnCanvasObject = undefined;
      this.videoOnCanvasObject = undefined;
      this.drawMediaAtStartTimeOntoCanvasAtSequenceMode(
        this.canvasPreviewSequence.nativeElement,
        this.mediaOfSequences,
        this.sequenceSave.objectFit
      );
    }
  }

  /**
   * click button next preview sequence
   */
  public nextTime() {
    if (this.isShowClipMode) {
      if (this.valueCursorPoint == Helper.convertDuration(this.valueTotalTime)) {
        return;
      }
      if (this.valueCursorPoint < this.timeInSecond) {
        this.valueCursorPoint = this.timeInSecond;
        this.timeCursorPointSecond = this.timeInSecond;
      } else if (this.valueCursorPoint < this.timeOutSecond) {
        this.valueCursorPoint = this.timeOutSecond;
        this.timeCursorPointSecond = this.timeOutSecond;
      } else {
        this.valueCursorPoint = Helper.convertDuration(this.valueTotalTime);
        this.timeCursorPointSecond = Helper.convertDuration(this.valueTotalTime);
      }
      this.setScreenVideo(this.video, this.valueCursorPoint);
    } else {
      if (this.isLoadingMediaOnCanvas) {
        return;
      }
      if (this.valueCursorPoint == Helper.convertDuration(this.valueTotalTime)) {
        return;
      }
      if (this.pointListNumber.length == 0) {
        this.valueCursorPoint = +this.totalDurationOfSequence;
        this.setValueCursorPointSequence(this.valueCursorPoint);
      } else {
        for (let index = 0; index < this.pointListNumber.length; index++) {
          if (this.valueCursorPoint < this.pointListNumber[index]) {
            this.valueCursorPoint = this.pointListNumber[index];
            this.setValueCursorPointSequence(this.valueCursorPoint);
            break;
          } else if (this.valueCursorPoint >= this.pointListNumber[this.pointListNumber.length - 1]) {
            this.valueCursorPoint = +this.totalDurationOfSequence;
            this.setValueCursorPointSequence(this.valueCursorPoint);
            break;
          }
        }
      }
      this.drawPreviewWhenNextOrPreAtSequenceMode(
        this.canvasPreviewSequence.nativeElement,
        this.mediaOfSequences,
        this.sequenceSave.objectFit
      );
    }
  }

  /**
   * click button prev preview sequence
   */
  public prevTime() {
    if (this.isShowClipMode) {
      if (this.valueCursorPoint == 0) {
        return;
      }
      if (this.valueCursorPoint > this.timeOutSecond) {
        this.valueCursorPoint = this.timeOutSecond;
        this.timeCursorPointSecond = this.timeOutSecond;
      } else if (this.valueCursorPoint > this.timeInSecond) {
        this.valueCursorPoint = this.timeInSecond;
        this.timeCursorPointSecond = this.timeInSecond;
      } else {
        this.valueCursorPoint = 0;
        this.timeCursorPointSecond = 0;
      }
      this.setScreenVideo(this.video, this.valueCursorPoint);
    } else {
      if (this.isLoadingMediaOnCanvas) {
        return;
      }
      if (this.valueCursorPoint == 0) {
        return;
      }
      if (this.pointListNumber.length == 0) {
        this.valueCursorPoint = 0;
        this.setValueCursorPointSequence(this.valueCursorPoint);
      } else {
        for (let index = this.pointListNumber.length - 1; index >= 0; index--) {
          if (this.valueCursorPoint > this.pointListNumber[index]) {
            this.valueCursorPoint = this.pointListNumber[index];
            this.setValueCursorPointSequence(this.valueCursorPoint);
            break;
          } else if (this.valueCursorPoint <= this.pointListNumber[0]) {
            this.valueCursorPoint = 0;
            this.setValueCursorPointSequence(this.valueCursorPoint);
            break;
          }
        }
      }
      this.drawPreviewWhenNextOrPreAtSequenceMode(
        this.canvasPreviewSequence.nativeElement,
        this.mediaOfSequences,
        this.sequenceSave.objectFit
      );
    }
  }

  /**
   * draw preview when next or pre at sequence mode
   *
   * @param canvas
   * @param mediaOfSequences
   * @param objectFit
   */
  private drawPreviewWhenNextOrPreAtSequenceMode(canvas: any, mediaOfSequences: Media[], objectFit: ObjectFitEnum) {
    if (this.isPlaySequence) {
      this.pausePreviewSequence.next();
    }
    this.audioOnCanvasObject = undefined;
    this.videoOnCanvasObject = undefined;
    this.drawMediaAtStartTimeOntoCanvasAtSequenceMode(canvas, mediaOfSequences, objectFit);
  }

  /**
   * reset preview to default
   */
  resetPreviewToDefault() {
    this.isPlaySequence = false;
    this.dataService.sendData([Constant.IS_PLAY_MEDIA, this.isPlaySequence]);
    this.timeCursorPointSecond = 0;
    this.valueCursorPoint = 0;
    this.valueCursorPointOld = 0;
    clearInterval(this.idInterval);
    if (Helper.isVideo(this.mediaOfSequenceSelected)) {
      this.video?.pause();
      this.setScreenVideo(this.video, 0);
      if (this.video) {
        this.video.currentTime = 0;
      }
    } else if (Helper.isSound(this.mediaOfSequenceSelected) || Helper.isImage(this.mediaOfSequenceSelected)) {
      this.pauseAudioSequence(this.audioObject);
      if (this.audioObject?.audioHtmlElement) {
        this.audioObject.audioHtmlElement.currentTime = 0;
      }
    }
  }

  /**
   * choose tab clip
   */
  public chooseTabClip() {
    if (this.isShowClipMode) {
      return;
    }
    // stop preview sequence at sequence mode
    this.pauseAllPreviewAtSequenceMode();
    this.isShowClipMode = true;
    this.resetPreviewToDefault();
    const indexMediaOfSequence = this.getIndexMediaSelected();
    if (Helper.isVideo(this.mediaOfSequenceSelected)) {
      this.valueTotalTime = this.mediaOfSequenceSelected.getMedia().duration;
    } else if (Helper.isImage(this.mediaOfSequenceSelected) || Helper.isSound(this.mediaOfSequences[indexMediaOfSequence])) {
      this.valueTotalTime = Helper.formatDuration(this.mediaOfSequenceSelected.durationDisplay);
    }
    this.maxTime = Helper.convertDuration(this.valueTotalTime);
    this.drawSequence();
  }

  /**
   * choose tab sequence
   */
  public async chooseTabSequence() {
    if (!this.isShowClipMode) {
      return;
    }
    this.isShowClipMode = false;
    this.drawMediaTabSequence();
  }

  /**
   * draw media tabSequence
   */
  public async drawMediaTabSequence() {
    this.resetPreviewToDefault();
    this.maxTime = +this.totalDurationOfSequence;
    this.valueTotalTime = Helper.formatDuration(+this.totalDurationOfSequence);
    this.pointListNumber = new Array<number>();
    this.pointListPercent = new Array<string>();
    this.calculatorPointList();
    this.drawMediaAtStartTimeOntoCanvasAtSequenceMode(
      this.canvasPreviewSequence.nativeElement,
      this.mediaOfSequences,
      this.sequenceSave.objectFit
    );
  }

  /**
   * calculator point list color pink of mode sequence
   */
  calculatorPointList() {
    this.pointListNumber = [0];
    this.pointListPercent = ['0'];
    this.mediaOfSequences.forEach(media => {
      if (Helper.isImage(media)) {
        this.pointListNumber.push(+media.getMedia().durationDisplay);
      } else if (Helper.isVideo(media)) {
        this.pointListNumber.push(media.getMedia().out - media.getMedia().in);
      }
    });
    this.pointListNumber.forEach((point, index) => {
      if (index < this.pointListNumber.length - 1) {
        this.pointListNumber[index + 1] += this.pointListNumber[index];
      }
      const percentPoint = +((this.pointListNumber[index] / +this.totalDurationOfSequence) * 100);
      const marginPoint = Math.floor((percentPoint * 6) / 100);
      this.pointListPercent[index] = `calc( ${Math.round(percentPoint)}% - ${marginPoint}px )`;
    });
    this.maxTime = +this.totalDurationOfSequence;
    this.valueTotalTime = Helper.formatDuration(+this.totalDurationOfSequence);
  }

  /**
   * play preview on sequence mode
   */
  private playPreviewSequenceAtSequenceMode() {
    // if preview is ended
    if (this.valueCursorPoint == +this.totalDurationOfSequence) {
      this.timeCursorPointSecond = 0;
      this.valueCursorPoint = 0;
      this.drawMediaAtStartTimeOntoCanvasAtSequenceMode(
        this.canvasPreviewSequence.nativeElement,
        this.mediaOfSequences,
        this.sequenceSave.objectFit
      );
    }
    if (this.previewSequenceSubscription && !this.previewSequenceSubscription.closed) {
      this.resumePreviewSequence.next();
    } else {
      this.drawPreviewSequenceAtSequenceMode(this.canvasPreviewSequence.nativeElement, this.mediaOfSequences, this.sequenceSave.objectFit);
    }
    if (this.videoOnCanvasObject) {
      const startTime = Helper.convertDuration(this.videoOnCanvasObject.video.startTime);
      const duration = this.videoOnCanvasObject.video.getMedia().out - this.videoOnCanvasObject.video.getMedia().in;
      if (this.valueCursorPoint >= startTime && this.valueCursorPoint < startTime + duration) {
        this.videoOnCanvasObject.videoHtmlElement?.play();
      }
    } else if (this.audioOnCanvasObject) {
      const startTime = Helper.convertDuration(this.audioOnCanvasObject.audio.startTime);
      if (this.isCreateSequence) {
        if (this.valueCursorPoint >= startTime && this.valueCursorPoint < startTime + +this.audioOnCanvasObject.duration) {
          this.audioOnCanvasObject.audioHtmlElement?.play();
        }
      } else if (
        this.valueCursorPoint >= startTime &&
        this.valueCursorPoint < startTime + Helper.convertDuration(this.audioOnCanvasObject.audio.getMedia().duration)
      ) {
        this.audioOnCanvasObject.audioHtmlElement?.play();
      }
    }
  }

  /**
   * draw preview sequence at sequence mode
   *
   * @param canvas
   * @param mediasOfSequence
   * @param objectFit
   */
  private drawPreviewSequenceAtSequenceMode(canvas: any, mediasOfSequence: Media[], objectFit: ObjectFitEnum): void {
    if (!canvas) {
      return;
    }
    this.previewSequenceSubscription = interval(1000)
      .pipe(
        takeUntil(this.pausePreviewSequence),
        repeatWhen(() => this.resumePreviewSequence)
      )
      .subscribe(() => {
        this.setValueCursorPointSequence(++this.valueCursorPoint);
        this.valueCursorPointOld = _.cloneDeep(this.valueCursorPoint);
        let ctx = canvas.getContext('2d');
        if (this.valueCursorPoint == +this.totalDurationOfSequence) {
          this.isPlaySequence = false;
          this.dataService.sendData([Constant.IS_PLAY_MEDIA, this.isPlaySequence]);
          ctx.clearRect(0, 0, canvas.width, canvas.height);
          this.pausePreviewSequence.next();
          this.previewSequenceSubscription.unsubscribe();
          return;
        }
        const medias = this.mediaOfSequences.filter(media => Helper.convertDuration(media.startTime) == this.valueCursorPoint);
        if (medias.length == 0) {
          return;
        }
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        this.mediaOfSequenceSelected = medias[0];
        this.pausePreviewSequence.next();
        this.isLoadingMediaOnCanvas = true;
        const mediaPosition = Helper.coverMedia(canvas, medias[0], objectFit);
        if (medias.length == 1) {
          this.drawImageOrVideoOntoCanvasAtSequenceMode(ctx, medias, mediaPosition, objectFit);
        } else {
          this.drawAudioOnImageOntoCanvasAtSequenceMode(ctx, medias, mediaPosition, objectFit);
        }
      });
  }

  /**
   * draw media at start time onto canvas at sequence mode
   *
   * @param canvas
   * @param mediasOfSequence
   * @param objectFit
   */
  private drawMediaAtStartTimeOntoCanvasAtSequenceMode(canvas: any, mediasOfSequence: Media[], objectFit: ObjectFitEnum) {
    if (!canvas) {
      return;
    }
    let ctx = canvas.getContext('2d');
    if (this.valueCursorPoint == +this.totalDurationOfSequence) {
      this.isPlaySequence = false;
      this.dataService.sendData([Constant.IS_PLAY_MEDIA, this.isPlaySequence]);
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      this.pausePreviewSequence.next();
      this.previewSequenceSubscription?.unsubscribe();
      return;
    }
    const medias = mediasOfSequence.filter(media => Helper.convertDuration(media.startTime) == this.valueCursorPoint);
    if (medias.length == 0) {
      return;
    }
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    this.mediaOfSequenceSelected = medias[0];
    this.isLoadingMediaOnCanvas = true;
    const mediaPosition = Helper.coverMedia(canvas, medias[0], objectFit);
    if (medias.length == 1) {
      this.drawImageOrVideoOntoCanvasAtSequenceMode(ctx, medias, mediaPosition, objectFit);
    } else {
      this.drawAudioOnImageOntoCanvasAtSequenceMode(ctx, medias, mediaPosition, objectFit);
    }
  }

  /**
   * draw audio on image onto canvas at sequence mode
   *
   * @param ctx
   * @param medias
   * @param mediaPosition
   * @param objectFit
   */
  private drawAudioOnImageOntoCanvasAtSequenceMode(ctx: any, medias: Media[], mediaPosition: any, objectFit: ObjectFitEnum) {
    fromFetch(this.drawService.createGetRequestMedia(medias[0].url))
      .pipe(
        takeUntil(this.cancelFetchRequest),
        switchMap(response => {
          if (response.ok) {
            return response.text();
          }
        })
      )
      .subscribe({
        complete: () => {
          this.drawImageOntoCanvasAtSequenceMode(ctx, medias[0].url, mediaPosition, objectFit, medias.length);
          fromFetch(this.drawService.createGetRequestMedia(medias[1].url))
            .pipe(
              takeUntil(this.cancelFetchRequest),
              switchMap(response => {
                if (response.ok) {
                  return response.text();
                }
              })
            )
            .subscribe({
              complete: () => {
                // play audio on image
                this.audioOnCanvasObject = new AudioObject(medias[1]);
                this.setupPreviewSoundSequence(medias[1], this.audioOnCanvasObject);
                this.playAudioOnImageAtSequenceMode(this.audioOnCanvasObject);
              }
            });
        }
      });
  }

  /**
   * draw image or video onto canvas at sequence mode
   *
   * @param ctx
   * @param medias
   * @param mediaPosition
   * @param objectFit
   */
  private drawImageOrVideoOntoCanvasAtSequenceMode(ctx: any, medias: Media[], mediaPosition: any, objectFit: ObjectFitEnum) {
    fromFetch(this.drawService.createGetRequestMedia(medias[0].url))
      .pipe(
        takeUntil(this.cancelFetchRequest),
        switchMap(response => {
          if (response.ok) {
            return response.text();
          }
        })
      )
      .subscribe({
        complete: () => {
          if (medias[0] instanceof Video) {
            // draw video onto canvas
            this.videoOnCanvasObject = new VideoOnCanvasObject(medias[0]);
            this.drawVideoOntoCanvasAtSequenceMode(ctx, this.videoOnCanvasObject, mediaPosition, objectFit);
          } else {
            // draw image onto canvas
            this.drawImageOntoCanvasAtSequenceMode(ctx, medias[0].url, mediaPosition, objectFit, medias.length);
          }
        }
      });
  }

  /**
   * draw image onto canvas at sequence mode
   *
   * @param ctx
   * @param imageUrl
   * @param mediaPosition
   * @param objectFit
   */
  private drawImageOntoCanvasAtSequenceMode(
    ctx: any,
    imageUrl: string,
    mediaPosition: any,
    objectFit: ObjectFitEnum,
    mediasLength: number
  ) {
    let imageHtmlElement = document.createElement('img');
    imageHtmlElement.src = imageUrl;
    let pattern = ctx.createPattern(imageHtmlElement, 'no-repeat');
    ctx.fillStyle = pattern;
    ctx.clearRect(0, 0, this.canvasPreviewSequence.nativeElement.width, this.canvasPreviewSequence.nativeElement.height);
    // listen event load
    imageHtmlElement.addEventListener('load', () => {
      // draw image
      if (objectFit == ObjectFitEnum.FILL) {
        ctx.drawImage(imageHtmlElement, mediaPosition.x, mediaPosition.y, mediaPosition.width, mediaPosition.height);
      } else {
        ctx.drawImage(
          imageHtmlElement,
          mediaPosition.sX,
          mediaPosition.sY,
          mediaPosition.sWidth,
          mediaPosition.sHeight,
          mediaPosition.x,
          mediaPosition.y,
          mediaPosition.width,
          mediaPosition.height
        );
      }
      // case only image
      if (mediasLength > 1) {
        return;
      }
      if (this.isPlaySequence) {
        this.resumePreviewSequence.next();
      } else {
        this.isLoadingMediaOnCanvas = false;
      }
    });
  }

  /**
   * play audio on image at sequence mode
   *
   * @param audioObject
   */
  private playAudioOnImageAtSequenceMode(audioObject: AudioObject) {
    // listen event loadeddata
    audioObject?.audioHtmlElement?.addEventListener('loadeddata', () => {
      audioObject.audioHtmlElement.currentTime = 0;
      if (this.isPlaySequence) {
        audioObject.audioHtmlElement.play();
        this.resumePreviewSequence.next();
      } else {
        this.isLoadingMediaOnCanvas = false;
      }
    });
    // listen event timeupdate audio
    audioObject?.audioHtmlElement?.addEventListener('timeupdate', () => {
      if (Math.floor(audioObject?.audioHtmlElement.currentTime) >= +audioObject?.duration) {
        audioObject.audioHtmlElement.pause();
        audioObject.audioHtmlElement.currentTime = 0;
        this.audioOnCanvasObject = undefined;
      }
    });
  }

  /**
   * draw video onto canvas at sequence mode
   *
   * @param ctx
   * @param videoOnCanvasObject
   * @param mediaPosition
   * @param objectFit
   */
  private drawVideoOntoCanvasAtSequenceMode(
    ctx: any,
    videoOnCanvasObject: VideoOnCanvasObject,
    mediaPosition: any,
    objectFit: ObjectFitEnum
  ) {
    // listen event loadeddata
    videoOnCanvasObject.videoHtmlElement.addEventListener('loadeddata', () => {
      videoOnCanvasObject.videoHtmlElement.currentTime = videoOnCanvasObject.video.getMedia().in;
      if (this.isPlaySequence) {
        videoOnCanvasObject.videoHtmlElement.play();
        this.resumePreviewSequence.next();
      }
      this.isLoadingMediaOnCanvas = false;
    });
    if (!this.isPlaySequence) {
      videoOnCanvasObject.videoHtmlElement.addEventListener('seeked', () => {
        // draw seeked video
        if (objectFit == ObjectFitEnum.FILL) {
          ctx.drawImage(videoOnCanvasObject.videoHtmlElement, mediaPosition.x, mediaPosition.y, mediaPosition.width, mediaPosition.height);
        } else {
          ctx.drawImage(
            videoOnCanvasObject.videoHtmlElement,
            mediaPosition.sX,
            mediaPosition.sY,
            mediaPosition.sWidth,
            mediaPosition.sHeight,
            mediaPosition.x,
            mediaPosition.y,
            mediaPosition.width,
            mediaPosition.height
          );
        }
        this.isLoadingMediaOnCanvas = false;
      });
    }
    // listen event play video
    videoOnCanvasObject.videoHtmlElement.addEventListener('play', () => {
      this.drawVideoFrameAnimationAtSequenceMode(ctx, videoOnCanvasObject, mediaPosition, objectFit);
    });
    // listen event pause video
    videoOnCanvasObject.videoHtmlElement.addEventListener('pause', () => {
      cancelAnimationFrame(videoOnCanvasObject.idAnimationFrame);
    });
  }

  /**
   * draw video frame animation at sequence mode
   *
   * @param ctx
   * @param videoOnCanvasObject
   * @param mediaPos
   * @param objectFit
   */
  private drawVideoFrameAnimationAtSequenceMode(
    ctx: any,
    videoOnCanvasObject: VideoOnCanvasObject,
    mediaPos: any,
    objectFit: ObjectFitEnum
  ) {
    ctx.clearRect(0, 0, this.canvasPreviewSequence.nativeElement.width, this.canvasPreviewSequence.nativeElement.height);
    if (Math.floor(videoOnCanvasObject.videoHtmlElement.currentTime) == videoOnCanvasObject.video.getMedia().out) {
      videoOnCanvasObject.videoHtmlElement.pause();
      videoOnCanvasObject.videoHtmlElement.remove();
      this.videoOnCanvasObject = undefined;
      return;
    }
    // draw video
    if (objectFit == ObjectFitEnum.FILL) {
      ctx.drawImage(videoOnCanvasObject.videoHtmlElement, mediaPos.x, mediaPos.y, mediaPos.width, mediaPos.height);
    } else {
      ctx.drawImage(
        videoOnCanvasObject.videoHtmlElement,
        mediaPos.sX,
        mediaPos.sY,
        mediaPos.sWidth,
        mediaPos.sHeight,
        mediaPos.x,
        mediaPos.y,
        mediaPos.width,
        mediaPos.height
      );
    }
    // set request animation
    videoOnCanvasObject.idAnimationFrame = requestAnimationFrame(() => {
      this.drawVideoFrameAnimationAtSequenceMode(ctx, videoOnCanvasObject, mediaPos, objectFit);
    });
  }

  /**
   * calculate scale transform canvas
   *
   * @param canvas
   */
  private calculateScaleTransformCanvas(canvas: any) {
    this.changeDetectorRef.detectChanges();
    let maxHeight = 364;
    let maxWidth = 445;
    let scaleTransform = { scaleX: 1, scaleY: 1 };
    if (canvas.width > maxHeight) {
      scaleTransform.scaleX = maxWidth / canvas.width;
    }
    if (canvas.height > maxHeight) {
      scaleTransform.scaleY = maxWidth / canvas.height;
    }
    let scale = Math.min(scaleTransform.scaleX, scaleTransform.scaleY);
    if (this.isCreateSequence) {
      this.widthCanvasPreviewSequence = this.sequenceSave.width * scale;
      this.heightCanvasPreviewSequence = this.sequenceSave.height * scale;
    } else if (Helper.isSequence(this.mediaSelected) && !this.isCreateSequence) {
      this.widthCanvasPreviewSequence = this.mediaSelected.getMedia().width * scale;
      this.heightCanvasPreviewSequence = this.mediaSelected.getMedia().height * scale;
    }
    this.changeDetectorRef.detectChanges();
  }

  /**
   * pause all preview at sequence mode
   */
  private pauseAllPreviewAtSequenceMode() {
    this.isLoadingMediaOnCanvas = false;
    if (this.isPlaySequence) {
      this.pausePreviewSequence.next();
    }
    this.cancelFetchRequest.next();
    this.audioOnCanvasObject = undefined;
    this.videoOnCanvasObject = undefined;
  }

  /**
   * set height preview area
   */
  setHeightPreview() {
    return this.isCreateSequence ? 80 : 100;
  }
}
/**
 * Class Timeout
 */
export class TimeOut {
  time: any;
  id: Number;
  constructor(time: any, id: Number) {
    this.time = time;
    this.id = id;
  }
}

/**
 * Class AudioObject
 */
export class AudioObject {
  audio: Media;
  audioHtmlElement: HTMLAudioElement;
  duration: number;
  constructor(audio?: Media) {
    this.audio = audio;
  }
}

/**
 * Class VideoOnCanvasObject
 */
export class VideoOnCanvasObject {
  video: Media;
  videoHtmlElement: HTMLVideoElement;
  idAnimationFrame: number;
  constructor(video: Media) {
    this.videoHtmlElement = document.createElement('video');
    this.videoHtmlElement.src = video?.url;
    this.video = video;
  }
}

/**
 * Class TimeSequence
 */
export class TimeSequence {
  valueInputHH: string;
  valueInputMM: string;
  valueInputSS: string;
  constructor(hour: string, minute: string, second: string) {
    this.valueInputHH = hour;
    this.valueInputMM = minute;
    this.valueInputSS = second;
  }
}
